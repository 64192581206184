import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { appConfig } from "../appConfig";
import { APIError } from "../models/utils";

interface ApiConfig {
    audience: string,
    scope: string,
    baseUrl: string
}

export enum ResultType {
    JSON = "JSON",
    Text = "Text",
    Blob = "Blob",
    None = "None"
}

export enum BodyType {
    JSON = "JSON",
    Form = "Form"
}

export const useAuthApiCall = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [apiConfig] = useState({ audience: appConfig().apiAudience, scope: appConfig().apiScope, baseUrl: appConfig().apiBaseUrl } as ApiConfig);

    return useCallback(async (endpoint: string, method: string, body?: any, resultType?: ResultType, bodyType?: BodyType, additionalHeaders: any = {}) => {

        // default to JSON unless otherwise told
        resultType = resultType || ResultType.JSON;

        //console.log(`Calling authenticated api: ${endpoint}`);
        let accessToken;
        try {
            accessToken = await getAccessTokenSilently({
                audience: apiConfig.audience,
                scope: apiConfig.scope
            });
        } catch (e) {
            console.error(e);
            return;
        }
        // { "Content-Type": "multipart/form-data" }
        const opts: RequestInit = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': bodyType === BodyType.Form ? "application/x-www-form-urlencoded" : "application/json",
                ...additionalHeaders
            },
            method: method
        }

        if (body) {
            if (bodyType === BodyType.Form) {
                opts.body = body;
            } else {
                opts.body = JSON.stringify(body);
            }
        }

        try {
            let resp = await fetch(`${apiConfig.baseUrl}/${endpoint}`, opts);

            if (!resp.ok) {
                console.error(`Error calling ${endpoint}: ${resp.status} - ${resp.statusText}`);
                let e = new APIError();
                e.message = await resp.text();
                e.status = resp.status;
                e.endpoint = endpoint;
                throw e;
            }

            switch (resultType) {
                case ResultType.Text:
                    return await resp.text();
                case ResultType.Blob:
                    return await resp.blob();
                case ResultType.JSON:
                    return await resp.json();
                case ResultType.None:
                    return;
            }


        } catch (err: any) {
            console.error("Error calling API", err);
            
            let e = err as APIError;
            e.name = 'API call failure';
            e.message = 'Unable to make call to API Backend';
            e.endpoint = endpoint;
            throw e;
        }
    }, [apiConfig.audience, apiConfig.baseUrl, apiConfig.scope, getAccessTokenSilently]);
}
