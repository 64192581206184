import { faInstagram, faTiktok, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AccountStatus, AccountType, AppUser, AppUserProfile, Role, SocialMediaSite } from "../models/appUser";
import Register from "./Register";
import ImageUploader from "./utils/ImageUploader";
import { faArrowUpRightFromSquare, faCheckCircle, faCreditCard, faCross, faEnvelope, faPencil, faQuestionCircle, faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ProtectedItem } from "../utils/ProtectedItem";
import { LookupItem } from "../models/lookupItem";
import { ResultType, useAuthApiCall } from "../utils/useAuthApiCall";
import { PaymentSession, PaymentType, ProductId } from "../models/payment";
import { appConfig } from "../appConfig";
import purpleAsterix from "../styles/img/doodles/purple/purple-asterix.png";
import blueHash from "../styles/img/doodles/blue/hashtag.webp";
import { UserContext } from "../contexts/userContext";

interface Props {
  showEditButton: boolean;
  adminMode?: boolean;
  appUserProfile?: AppUserProfile;
  appUser: AppUser;
  viewOnly?: boolean;
  submitCallback?: (u: AppUser) => void;
}

export default function ProfileView(props: Props) {
  const [showEdit, setShowEdit] = useState(false);
  const [creatingSession, setCreatingSession] = useState(false);
  const navigate = useNavigate();
  const apiCall = useAuthApiCall();
  const [product, setProduct] = useState({} as any);
  const appUserCtx = useContext(UserContext);

  useEffect(() => {
    const getProduct = async (prodId: string) => {
      let prod = await apiCall(`payments/products/${prodId}`, 'GET');
      setProduct(prod)
    };

    props.appUser.subscription && getProduct(props.appUser.subscription?.Items[0].Plan.ProductId);
  }, [apiCall, props.appUser.subscription])


  return (
    <>
      {
        props.appUser && props.appUser.accountStatus === AccountStatus.Updated && props.showEditButton &&
        <>
          <Alert variant="warning" style={{ textAlign: 'center' }}>
            Your profile changes are being reviewed. Brands will continue to see the information below until it is approved.
          </Alert>
        </>
      }
      {
        props.appUser && props.appUser.accountStatus === AccountStatus.RequiresChange && props.showEditButton &&
        <>
          <Alert variant="warning">
            NOTE: We have requested some changes to your profile: {props.appUser.feedback}
          </Alert>
        </>
      }
      <Container>
        {
          props.adminMode &&
          <div style={{ textAlign: 'right' }}>
            <a href={`mailto:${props.appUser.email}`} className="btn btn-secondary btn-sm"><FontAwesomeIcon icon={faEnvelope} /> Email User</a>
            {
              props.appUser.accountStatus === AccountStatus.Updated || props.appUser.accountStatus === AccountStatus.New ?

                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 800 }}
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      <div style={{ padding: 10 }}>
                        <h3>Unable to edit</h3>
                        <p>This profile currently has pending changes which would overwrite your changes. </p>
                        <p>Set the status to Active to apply the user's changes first, and then you can edit.</p>
                      </div>
                    </Tooltip>}
                >
                  <Button variant="success" size="sm" style={{ marginLeft: 15 }} title="Edit profile">
                    <FontAwesomeIcon icon={faPencil} />&nbsp;&nbsp;Edit
                  </Button>
                </OverlayTrigger>
                :
                <Button variant="success" size="sm" style={{ marginLeft: 15 }} onClick={(e) => { e.preventDefault(); setShowEdit(true); }} title="Edit profile">
                  <FontAwesomeIcon icon={faPencil} />&nbsp;&nbsp;Edit
                </Button>
            }
          </div>
        }

        {
          props.showEditButton &&
          <>
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
              <Col>
              </Col>
              <Col style={{ textAlign: 'right' }} xs={12}>
                {
                  props.appUser.accountType === AccountType.Creator && !props.viewOnly &&
                  <Button variant="secondary" size="sm"
                    onClick={(e) => { e.preventDefault(); navigate('../../creators/dashboard') }}>
                    <FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;View Applications
                  </Button>
                }
                {
                  props.appUser.accountType === AccountType.Brand && !props.viewOnly &&
                  <Button variant="secondary" size="sm"
                    onClick={(e) => { e.preventDefault(); navigate(`../../brands/${props.appUserProfile?.brands[0].brandGuid}/briefs`) }}>
                    <FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;View Briefs
                  </Button>
                }
                {
                  props.appUser.accountType === AccountType.Agency && !props.viewOnly &&
                  <Button variant="secondary" size="sm"
                    onClick={(e) => { e.preventDefault(); navigate(`../../brands`) }}>
                    <FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;View Briefs
                  </Button>
                }
                {
                  props.showEditButton &&
                  <Button variant="success" size="sm" style={{ marginLeft: 15 }} onClick={(e) => { e.preventDefault(); setShowEdit(true); }} title="Edit profile">
                    <FontAwesomeIcon icon={faPencil} />&nbsp;&nbsp;Edit Profile
                  </Button>
                }
              </Col>
            </Row>
            <hr />
          </>
        }

        <Row style={{ padding: 20 }}>
          <Container style={{ paddingTop: 0, paddingBottom: 40 }}>
            <Row>
              <Col md={5} style={{ textAlign: 'center', paddingRight: 0, marginBottom: 20 }}>
                <ImageUploader
                  fileId={props.appUserProfile && props.appUserProfile.picture && `${props.appUser.id}/${props.appUserProfile?.picture.id}`}
                  showEdit={false}
                  customKey="profile-viewer"
                  additionalStyles={{ width: props.appUser.accountType === AccountType.Creator ? 250 : 200, height: props.appUser.accountType === AccountType.Creator ? 250 : 200, borderRadius: '50%', margin: '0 auto', boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)' }}
                  fallbackImageUrl="/green-default-sq.png" />
              </Col>
              <Col md={{ offset: 1, span: 6 }}>
                <Container style={{ marginTop: 0 }}>
                  <Row style={{ paddingBottom: 20 }}>
                    <Col style={{ textAlign: 'center' }} sm={9}>
                      <h1 className="display-6"><span className="sf-underline sf-blue-underline">
                        {
                          props.appUser && props.appUser.accountType === AccountType.Creator &&
                          <>{props.appUserProfile?.firstName} {props.appUserProfile?.lastName}</>
                        }
                        {
                          props.appUser && props.appUser.accountType === AccountType.Agency &&
                          <>{props.appUserProfile?.agencyName}</>
                        }
                        {
                          props.appUser && props.appUser.accountType === AccountType.Brand &&
                          <>{props.appUserProfile?.brands[0].name}</>
                        }
                      </span></h1>
                    </Col>
                  </Row>
                  {
                    props.appUser.email &&
                    <Row>
                      <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Email:</Col>
                      <Col>{props.appUser.email}</Col>
                    </Row>
                  }
                  {
                    props.appUser && props.appUser.id.indexOf('auth0') !== -1 && props.appUser.id === appUserCtx.appUser.id &&
                    <Row>
                      <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Password:</Col>
                      <Col style={{ fontSize: 12 }}>To change your password: Log out and when logging in, select the "Forgot your Password?" link</Col>
                    </Row>
                  }
                  {
                    props.appUser && props.appUser.accountType === AccountType.Agency &&
                    <>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Contact Name:</Col>
                        <Col>{props.appUserProfile?.firstName} {props.appUserProfile?.lastName}</Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Personal LinkedIn:</Col>
                        <Col><a style={{ wordBreak: 'break-all' }} href={props.appUserProfile?.personalLinkedInUrl} target="_blank" rel="noreferrer">{props.appUserProfile?.personalLinkedInUrl}</a></Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Website:</Col>
                        <Col><a style={{ wordBreak: 'break-all' }} href={props.appUserProfile?.agencyWebsite} target="_blank" rel="noreferrer">{props.appUserProfile?.agencyWebsite}</a></Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Agency LinkedIn:</Col>
                        <Col><a style={{ wordBreak: 'break-all' }} href={props.appUserProfile?.linkedinUrl} target="_blank" rel="noreferrer">{props.appUserProfile?.linkedinUrl}</a></Col>
                      </Row>
                    </>
                  }
                  {
                    props.appUser && props.appUser.accountType === AccountType.Brand &&
                    <>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Contact Name:</Col>
                        <Col>{props.appUserProfile?.firstName} {props.appUserProfile?.lastName}</Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Personal LinkedIn:</Col>
                        <Col><a style={{ wordBreak: 'break-all' }} href={props.appUserProfile?.personalLinkedInUrl} target="_blank" rel="noreferrer">{props.appUserProfile?.personalLinkedInUrl}</a></Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Website:</Col>
                        <Col><a style={{ wordBreak: 'break-all' }} href={props.appUserProfile?.brands[0].website} target="_blank" rel="noreferrer">{props.appUserProfile?.brands[0].website}</a></Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Brand LinkedIn:</Col>
                        <Col><a style={{ wordBreak: 'break-all' }} href={props.appUserProfile?.brands[0].linkedIn} target="_blank" rel="noreferrer">{props.appUserProfile?.brands[0].linkedIn}</a></Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Sectors:</Col>
                        <Col>
                          {props.appUserProfile?.brands[0].sectors?.map((s: LookupItem, i: number) => { return i === (props.appUserProfile?.brands[0].sectors && props.appUserProfile?.brands[0].sectors?.length - 1) ? s.label : s.label + ", " })}
                        </Col>
                      </Row>
                    </>
                  }
                  {
                    props.appUser && props.appUser.accountType === AccountType.Creator &&

                    <>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Gender Identity:</Col>
                        <Col>{props.appUserProfile?.gender}</Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Age Bracket:</Col>
                        <Col>{props.appUserProfile?.age?.label}</Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Interested In:</Col>
                        <Col>{props.appUserProfile?.compensationTypes?.map((m, i) => i > 0 ? ", " + m : m)}</Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >Content Areas:</Col>
                        <Col>{props.appUserProfile?.contentAreas?.map((m, i) => i > 0 ? ", " + m.label : m.label)}</Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >I am a:</Col>
                        <Col>{props.appUser && props.appUser.accountType}</Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xl={5} className="sf-field-view-title" style={{ textAlign: 'left' }} >I'm based in:</Col>
                        <Col>{props.appUserProfile?.country}</Col>
                      </Row>
                    </>
                  }
                </Container>
              </Col>
            </Row>
            <Row style={{ marginLeft: -30, marginRight: -30, marginBottom: 40, marginTop: 40, position: 'relative' }}>
              <img src={blueHash} style={{ position: 'absolute', width: 80, top: -35, right: -10 }} alt="Bio" />
              <Col>
                <Container>
                  <Row>
                    <Col style={{ padding: 20, borderRadius: 3 }} className="sf-purple-fill">
                      <h5>Bio:</h5>
                      <p>{props.appUserProfile?.bio}</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            {
              props.appUser && props.appUser.accountType === AccountType.Creator &&
              <Row>
                <h4 ><span className="sf-underline sf-blue-underline">Social Accounts</span></h4>
                <Container style={{ marginTop: 20 }}>
                  {
                    props.appUserProfile?.socialMediaAccounts?.map((acct, i) => {
                      return (
                        <>
                          <Container key={i} style={{ padding: 10, marginBottom: 10, border: '2px #333 solid', borderRadius: 3 }}>
                            <Row>
                              <Col sm={1} xl={1} style={{ paddingTop: 5 }}>
                                {
                                  acct.site === SocialMediaSite.Instagram && <FontAwesomeIcon icon={faInstagram} size={"2x"} />
                                }
                                {
                                  acct.site === SocialMediaSite.TikTok && <FontAwesomeIcon icon={faTiktok} size={"2x"} />
                                }
                                {
                                  acct.site === SocialMediaSite.X && <FontAwesomeIcon icon={faXTwitter} size={"2x"} />
                                }
                                {
                                  acct.site === SocialMediaSite.YouTube && <FontAwesomeIcon icon={faYoutube} size={"2x"} />
                                }

                              </Col>
                              <Col xl={2} style={{ paddingTop: 5, fontSize: 18, fontWeight: 'bold' }}>
                                {acct.site}
                              </Col>
                              <Col sm={3} xl={3} style={{ paddingTop: 5 }}>
                                {
                                  acct.site === SocialMediaSite.Instagram &&
                                  <a href={`https://www.instagram.com/${acct.urlOrHandle}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>{acct.urlOrHandle}&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: 13 }} />
                                  </a>
                                }
                                {
                                  acct.site === SocialMediaSite.TikTok &&
                                  <a href={`https://tiktok.com/@${acct.urlOrHandle.replace("@", "")}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>{acct.urlOrHandle}&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: 13 }} />
                                  </a>
                                }
                                {
                                  acct.site === SocialMediaSite.X &&
                                  <a href={`https://twitter.com/${acct.urlOrHandle}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>{acct.urlOrHandle}&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: 13 }} />
                                  </a>
                                }
                                {
                                  acct.site === SocialMediaSite.YouTube &&
                                  <a href={`https://www.youtube.com/@${acct.urlOrHandle.replace("@", "")}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>{acct.urlOrHandle}&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: 13 }} />
                                  </a>
                                }
                              </Col>
                              <Col sm={12} md={12} lg={12} xl={4} style={{ paddingTop: 5 }}>
                                <strong>Follower Size Bracket:</strong> {acct.followerCount.label}
                              </Col>
                              <Col sm={12} md={12} lg={12} xl={2} style={{ paddingTop: 5 }}>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 800 }}
                                  overlay={
                                    <Tooltip id="button-tooltip">
                                      <div style={{ padding: 10 }}>
                                        <h3>Verified Account</h3>
                                        <p>Verified Account means we have received the demographics directly from the influencer via screenshots. If it is not verified, this information comes from our creator analytics reports.</p>
                                      </div>
                                    </Tooltip>
                                  }>
                                  <div>
                                    <strong>Verified</strong>
                                    <div style={{ display: 'inline-block', position: 'relative', paddingLeft: 3 }}><FontAwesomeIcon icon={faQuestionCircle} /></div>&nbsp;:
                                    &nbsp;
                                    <FontAwesomeIcon style={{ color: acct.verified ? '#5273EA' : '#990000' }} icon={acct.verified ? faCheckCircle : faTimesCircle} />
                                  </div>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                            {
                                acct.aqs && (acct.aqs.age || acct.aqs.country || acct.aqs.gender) &&
                                <>
                                  <hr />
                                  <Row>
                                    {/* <Col sm={6} style={{ textAlign: 'center' }}>
                                      <div>
                                        <div style={{ backgroundColor: '#161723', color: '#fff', padding: 15, borderRadius: 3, display: 'inline-block', fontSize: 16, margin: '10px auto' }}>
                                          AQS: {acct.aqs.score}%
                                        </div>
                                        <OverlayTrigger
                                          placement="right"
                                          delay={{ show: 250, hide: 800 }}
                                          overlay={
                                            <Tooltip id="button-tooltip">
                                              <div style={{ padding: 10 }}>
                                                <h3>Audience Quality Score</h3>
                                                <p>As quantified by an influencer analytics tool</p>
                                                <p>For more on an influencer analytics tool, see our <a href="/faq">FAQs</a></p>
                                              </div>
                                            </Tooltip>}
                                        >
                                          <div style={{ display: 'inline-block', position: 'relative', top: -15, paddingLeft: 5 }}><FontAwesomeIcon icon={faQuestionCircle} /></div>
                                        </OverlayTrigger>
                                      </div>
                                    </Col> */}
                                    <Col>
                                      <h5>Audience Demographics:</h5>
                                      <b>Age:</b> {acct.aqs.age} <br />
                                      <b>Gender:</b> {acct.aqs.gender} <br />
                                      <b>Country:</b> {acct.aqs.country} <br />
                                    </Col>
                                  </Row>
                                </>
                              }
                          </Container>
                        </>
                      );
                    })
                  }
                </Container>

              </Row>
            }
            {
              props.appUser && props.appUser.accountType === AccountType.Agency &&
              <Row>
                <Col>
                  <h4><span className="sf-underline sf-blue-underline">Brands</span></h4>
                  <Container style={{ marginTop: 20 }}>
                    {
                      props.appUserProfile?.brands?.map((brand, i) => {
                        return (
                          <Row key={i} style={{ padding: 10, marginBottom: 10, marginRight: -30, marginLeft: -30, border: '2px #333 solid', borderRadius: 3 }}>

                            <Col style={{ paddingTop: 5, fontSize: 18, fontWeight: 'bold' }}>
                              {brand.name}
                            </Col>
                            <Col style={{ paddingTop: 5 }}>
                              {brand.website}
                            </Col>
                            <Col style={{ paddingTop: 5 }}>
                              {brand.sectors?.map((s: LookupItem, i: number) => { return i === (brand.sectors && brand.sectors?.length - 1) ? s.label : s.label + ", " })}
                            </Col>
                          </Row>
                        );
                      })
                    }
                  </Container>

                </Col>
              </Row>
            }
          </Container>
        </Row >
        {
          props.appUser.id === appUserCtx.appUser.id &&

          <ProtectedItem requiredRoles={[Role.Creator]}>
            <Row style={{ position: 'relative' }}>
              <img src={purpleAsterix} style={{ position: 'absolute', width: 90, top: 20, left: -30 }} alt="Subscription Details" />
              <Col className="sf-black-fill" style={{ marginTop: 40, marginBottom: 40, padding: 20, borderRadius: 4 }}>
                <Container>
                  <Row>
                    <Col sm={6}>
                      <h2 style={{ marginTop: 40 }}>{
                        props.appUser.subscription ? <>Subscription <br />Details</> : <>Trial<br />Subscription</>
                      }</h2>
                    </Col>
                    <Col style={{ paddingTop: 20 }}>
                      {
                        props.appUser.subscriptionId === 'trial' && !props.appUser.subscription && props.appUser.trialEnd &&
                        <>
                          <div className="sf-payment-row"><span className="sf-payment-label">Plan:</span> Social Fuse Free Trial</div>

                          {new Date(props.appUser.trialEnd) < new Date() ?
                            <p style={{ fontStyle: 'italic', padding: 2 }}>Your free trial has ended. Feel free to browse the site for potential collaborations - but you won't be able to apply for any briefs until you subscribe.</p>
                            :
                            <div className="sf-payment-row"><span className="sf-payment-label">Trial Ends:</span> {new Date(props.appUser.trialEnd).toLocaleDateString()}</div>
                          }
                        </>
                      }
                      {
                        props.appUser.subscription && <>
                          <div className="sf-payment-row"><span className="sf-payment-label">Plan:</span> {product.Name}</div>
                          {!props.appUser.subscription?.CanceledAt && <>
                            <div className="sf-payment-row"><span className="sf-payment-label">Payment Schedule:</span> £{props.appUser.subscription?.Items[0].Plan.Amount / 100} / {props.appUser.subscription?.Items[0].Plan.Interval}</div>
                            <div className="sf-payment-row"><span className="sf-payment-label">Next Payment Date:</span> {new Date(props.appUser.subscription?.CurrentPeriodEnd).toLocaleDateString()}</div>
                          </>
                          }
                          <div className="sf-payment-row"><span className="sf-payment-label">Status:</span> {props.appUser.subscription?.CanceledAt ? 'Cancelled' : props.appUser.subscription?.Status}</div>
                          {
                            props.appUser.subscription?.CanceledAt &&
                            <>
                              <div className="sf-payment-row"><span className="sf-payment-label">Cancelled On:</span> {new Date(props.appUser.subscription?.CanceledAt).toLocaleDateString()}</div>
                              <div className="sf-payment-row"><span className="sf-payment-label">Subscription Ends:</span> {new Date(props.appUser.subscription?.CurrentPeriodEnd).toLocaleDateString()}</div>
                            </>
                          }
                        </>
                      }
                    </Col>
                  </Row>
                  <Row style={{ paddingRight: 0, marginRight: -20 }}>
                    <Col style={{ textAlign: 'right', paddingRight: 0 }}>
                      <a href={appConfig().paymentPortalLink} style={{ display: 'none' }} id="manage-sub" target="_blank" rel="noreferrer">Manage Subscription</a>
                      {props.appUser.subscription ?
                        <Button size="sm" variant="success" onClick={() => document.getElementById('manage-sub')?.click()}><FontAwesomeIcon icon={faCreditCard} /> Manage Subscription</Button>
                        :
                        <Button size="sm" variant="success"
                          disabled={creatingSession}
                          onClick={async () => {
                            setCreatingSession(true);
                            let sessionUrl = await apiCall('payments/session', 'POST', {
                              priceId: ProductId.INFLUENCER_MONTHLY_SUB,
                              successUrl: window.location.href,
                              cancelUrl: window.location.href,
                              mode: 'subscription',
                              paymentType: PaymentType.UserSubscription
                            } as PaymentSession, ResultType.Text);

                            let s = sessionUrl.substring(0, sessionUrl.length - 1)
                            s = s.substring(1);

                            window.location.href = s;
                          }}>{creatingSession ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faCreditCard} />} Create Subscription</Button>
                      }
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </ProtectedItem>
        }
      </Container>

      <Modal size="xl" show={showEdit} onHide={() => { setShowEdit(false); !props.adminMode && window.location.reload(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>{
          props.appUser && props.appUser.id &&
          <Register appUser={{ ...props.appUser }} adminMode={props.adminMode} submitCallback={(u: AppUser) => props.submitCallback && props.submitCallback(u)} />
        }
        </Modal.Body>
      </Modal>
    </>
  );
}
