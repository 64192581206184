import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/userContext';

interface Props {
  requiredRoles: string[]
  children: JSX.Element
  renderError?: boolean
}

export function ProtectedItem(props: Props) {
  const [userIsInRole, setUserIsInRole] = useState(false);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    let inRole = false;
    userCtx.appUser.roles && props.requiredRoles.forEach((role: string) => {
      if (userCtx.appUser.roles?.findIndex(f => (f.toLowerCase() === role.toLowerCase())) !== -1) {
        inRole = true;
      }
    });

    setUserIsInRole(inRole);
  }, [props.requiredRoles, userCtx]);

  const ErrorComponent = () => {
    return (
      <>
        <h4>You do not have access to this area</h4>
      </>
    )
  }

  return (
    userIsInRole ? props.children : props.renderError ? <ErrorComponent /> : <></>
  )
}

