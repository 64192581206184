import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../styles/img/logos/TSF_MINILOGO_WHITE.png";

export default function Footer() {

  return (
    <footer className="footer py-4">
      <Container fluid>
        <Row>
          <Col xs={2} md={1}>
            <a href="/#"><img src={logo} style={{ width: 80 }} alt="The Social Fuse" /></a>
          </Col>
          <Col xs={5} md={4}>
            <ul style={{ listStyle: 'none' }}>
              <li>
                <Link className="link-light text-decoration-none" to="/about-us">About Us</Link>
              </li>
              <li>
                <Link className="link-light text-decoration-none" to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link className="link-light text-decoration-none" to="/faq">FAQs</Link>
              </li>
              <li>
                <a className="link-light text-decoration-none" href="mailto:info@thesocialfuse.co.uk">Contact Us</a>
              </li>
            </ul>
          </Col>
          <Col xs={5} md={{ span: 4, offset: 3 }} className="text-lg-end align-items-center">
            <Row>
              <Col xs={12} sm={8}>
                <Link className="link-light text-decoration-none me-3" to="/privacy">Privacy Policy</Link>
              </Col>
              <Col>
                <Link className="link-light text-decoration-none" to="/terms-of-use">Terms of Use</Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={4} className="text-lg-start">Copyright &copy; The Social Fuse 2024</Col>
        </Row>
      </Container>
    </footer>
  );
}
