import { AppUser } from "./appUser";

export enum FormStages {
    WhoAreYou,
    YourDetails,
    Interests,
    Socials,
    BrandInfo,
    AgencyInfo,
    Brands,
    PictureBio,
    Confirm,
    AllDone
}

export interface Stages {
    creator: Array<FormStages>
    brand: Array<FormStages>
    agency: Array<FormStages>
}

export interface FormStageProps {
    u: AppUser
    nextCallback: (u: AppUser) => void
    prevCallback: (u: AppUser) => void
    localStages: Array<FormStages>
    currentStageName: FormStages 
}