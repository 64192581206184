import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { useAuthApiCall } from "../../utils/useAuthApiCall";
import { Brief } from "../../models/brief";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../contexts/userContext";
import ImageUploader from "../utils/ImageUploader";

export default function BriefCreatorBoard() {

  const [briefs, updateBriefs] = useState([] as Array<Brief>);
  const [isLoading, setIsLoading] = useState(true);
  const apiCall = useAuthApiCall();
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  useEffect(() => {

    try {
      // remove the background image
      (document.getElementsByClassName('sf-register-container')[0] as any).style.background = 'none';
    } catch (e) {
      // don't really care
    }
    
    // load briefs
    const getBriefs = async () => {
      const briefs = await apiCall('board', 'get');
      updateBriefs(briefs || []);
      setIsLoading(false);
    }

    getBriefs();
    return () => { try {(document.getElementsByClassName('sf-register-container')[0] as any).style = '';} catch (e) { }}
  }, [apiCall]);

  return (
    <Container style={{ margin: '40px auto' }}>
      <Row style={{ marginBottom: 10 }}>
        <Col md={{ offset: 2, span: 8 }} xs={6}>
          <h2 style={{ textAlign: 'center' }}><span className="sf-underline sf-blue-underline">Brief Board</span></h2>
        </Col>
      </Row>
      The following filters have been applied:
      <Row style={{ padding: '0 12px', marginBottom: 20 }}>
        <Col md={2} className="sf-green-fill" style={{ padding: '10px', borderRadius: '4px 0 0 4px' }}>
          Gender: <u>{userCtx.appUser.profile?.gender}</u>
        </Col>
        <Col md={2} className="sf-green-fill" style={{ padding: '10px' }}>
          Age: <u>{userCtx.appUser.profile?.age?.label}</u>
        </Col>
        <Col md={4} className="sf-green-fill" style={{ padding: '10px' }}>
          Content Areas: <u>{userCtx.appUser.profile?.contentAreas?.map(ca => ca.label).join(', ')}</u>
        </Col>
        <Col md={4} className="sf-green-fill" style={{ padding: '10px', borderRadius: '0 4px 4px 0' }}>
          Platforms: <u>{userCtx.appUser.profile?.socialMediaAccounts?.map(p => p.site).join(', ')}</u>
        </Col>

      </Row>
      <Row>
        <Col>
          {
            isLoading && <div style={{ textAlign: 'center', margin: 20 }}>
              <Spinner style={{}} animation="border" placeholder="Loading Briefs..." />
            </div>
          }
          {
            !isLoading && briefs.length === 0 &&
            <div style={{ textAlign: 'center', margin: 20 }}>
              <p>Sorry - there aren't any briefs that suit your profile at the moment. <br />Do come back soon though, as new briefs are posted daily.</p>
            </div>
          }
          {
            briefs.length > 0 &&
            <Container >
              <Row>
                <Col style={{ textAlign: 'center', marginBottom: 20 }}>
                  Found {briefs.length} brief(s) available for you, based on your profile details.
                </Col>
              </Row>
              <Row>
                <Col lg={1} style={{padding:0, margin:0}}>&nbsp;</Col>
                <Col style={{padding:0, margin:0}}>
                  <Container style={{padding:0, margin:0}}>
                    <Row>
                      {
                        briefs.map((b: Brief, i: number) => (
                          <Col key={i} lg={3} md={5} sm={5} xs={11} style={{ border: '2px #161723 solid', padding: 0, paddingBottom: 20, borderRadius: 4, margin: 10, backgroundColor: '#fff', position: 'relative' }}>
                            <Table borderless >
                              <thead style={{ backgroundColor: '#161723', color: '#fff', border: '2px #161723 solid' }}>
                                <tr>
                                  <th style={{ textAlign: 'center' }}>
                                    {b.title}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ padding: 0 }}>
                                    <ImageUploader
                                      fileId={b.bannerImage?.id && `${b.userId}/${b.bannerImage?.id}`}
                                      additionalStyles={{ height: 100, margin: '-1px' }}
                                      fallbackImageUrl="/brief-banner.webp" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div style={{ backgroundColor: '#C499EA', color: '#fff', margin: '-8px -9px 10px -9px', padding: 4, textAlign: 'center', fontSize: 14 }}>{b.brandName}</div>

                                    <span style={{ fontSize: 14 }}>
                                      <strong>Start:</strong>&nbsp;{b.campaignFrom && (new Date(b.campaignFrom)).toDateString()}<br />
                                      <strong>End:</strong>&nbsp;{b.campaignTo && (new Date(b.campaignTo)).toDateString()}<br /><br />
                                    </span>
                                    <p style={{ fontSize: '0.8rem' }}>
                                      {b.description.length < 200 ? b.description : b.description.substring(0, 200) + '...'}
                                    </p>
                                    <div style={{ textAlign: 'center', position: 'absolute', bottom: 10, width: '100%', left: 0 }}>
                                      <Button style={{ padding: '5px 15px' }} size="sm" variant="primary" onClick={(e) => { e.preventDefault(); navigate(`../brands/${b.brandId}/briefs/${b.id}`) }}><FontAwesomeIcon icon={faSearch} /> View</Button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        ))
                      }
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          }
        </Col>
      </Row>
    </Container>
  );

}
