import { BriefStatus } from "../../models/brief";

interface LifecycleProps {
  status: string;
}

export default function Lifecycle(props: LifecycleProps) {

  let stages = [
    BriefStatus.Draft,
    BriefStatus.InReview,
    BriefStatus.ChangesRequired,
    BriefStatus.Approved,
    BriefStatus.Live,
    BriefStatus.Closed,
  ];

  if (props.status !== BriefStatus.ChangesRequired) stages.splice(2, 1);

  const currentStageNum = stages.findIndex((s: BriefStatus) => s === props.status);

  return (
    <div style={{textAlign:'center'}}>
      <div style={{ position: 'relative', display: 'inline-block' }} className="brief-stage-breadcrumb-line">
        <div style={{ position: 'absolute', left: (props.status === BriefStatus.ChangesRequired ? 35 : 25), top: -4, width: '88%', borderBottom: '2px solid #c499ea' }} className="brief-stage-breadcrumb-line">&nbsp;</div>
        {
          stages.map((s: BriefStatus, i: number) => {
            return (
              <div key={s} style={{ display: "inline-block", textAlign: 'center', padding: '10px 20px', position: 'relative' }}>
                <div className={i <= currentStageNum ? "brief-stage-breadcrumb-full" : "brief-stage-breadcrumb-empty"}>
                </div>
                <div style={{ display: 'block' }}>{s.replace("InReview", "In Review").replace("ChangesRequired", "Changes Required")}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
