import { Row, Form, Button, Col, Dropdown, DropdownButton, InputGroup, Container, FormCheck } from "react-bootstrap";
import { AppUser, FollowerCount, Role, SocialMediaAccount, SocialMediaSite } from "../../models/appUser";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import FormStageNav from "./FormStageNav";
import { faInstagram, faYoutube, faXTwitter, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faLock, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useState } from "react";
import { FormStageProps } from "../../models/forms";
import Select from "react-select";
import { ProtectedItem } from "../../utils/ProtectedItem";

interface Props extends FormStageProps {
  followerCounts: Array<FollowerCount>
}

export default function SocialsStage(props: Props) {
  const { control, register, handleSubmit, getValues } = useForm<AppUser>({ defaultValues: props.u });
  const [validated, setValidated] = useState(false);
  const [selectedSocials, setSelectedSocials] = useState(0);

  document.title = 'The Social Fuse: Register - Your Socials';

  const onSubmit = (u: AppUser) => {
    console.log(u);
    let socials = getValues(`profile.socialMediaAccounts`);
    if (!socials || socials.length === 0) {
      setSelectedSocials(-1);
      return;
    }
    props.nextCallback(u);
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  const { fields: socialMediaAccounts, append: appendSMAccount, remove: removeSMAccount } = useFieldArray({
    control,
    name: 'profile.socialMediaAccounts'
  });

  const addSMAccount = (site: SocialMediaSite) => {
    appendSMAccount({
      site: site,
      followerCount: { label: "Follower Count..." } as FollowerCount,
      verified: false
    } as SocialMediaAccount);
    setSelectedSocials(1);
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container className="sf-form-body">
          <Row style={{ marginBottom: 40 }}>
            <Col>
              <div className="sf-registration-stage-title">
                <h2>Your Socials</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={{ offset: 2, span: 8 }}>
              <div className="sf-add-social">
                <DropdownButton id="social-add" title="Add social account" variant="secondary">
                  <Dropdown.Item href="#" onClick={() => { addSMAccount(SocialMediaSite.Instagram) }}><FontAwesomeIcon icon={faInstagram} /> Instagram</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => { addSMAccount(SocialMediaSite.TikTok) }}><FontAwesomeIcon icon={faTiktok} /> TikTok</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => { addSMAccount(SocialMediaSite.YouTube) }}><FontAwesomeIcon icon={faYoutube} /> YouTube</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => { addSMAccount(SocialMediaSite.X) }}><FontAwesomeIcon icon={faXTwitter} /> X <i>(Twitter)</i></Dropdown.Item>
                </DropdownButton>
              </div>
              <TransitionGroup component={null}>
                {
                  socialMediaAccounts.map((f: any, i: any) => (
                    <CSSTransition key={f.id} timeout={300} classNames="social-row">
                      <Container fluid className={i === socialMediaAccounts.length - 1 ? "social-row-last" : i % 2 === 0 ? "social-row social-row-even" : "social-row social-row-odd"}>
                        <Row className="mb-0 align-items-center">
                          <Col xs={3} sm={2} md={2} className="text-end sf-social-row-icon">
                            {
                              f.site === SocialMediaSite.Instagram && <FontAwesomeIcon icon={faInstagram} size={"3x"} />
                            }
                            {
                              f.site === SocialMediaSite.TikTok && <FontAwesomeIcon icon={faTiktok} size={"3x"} />
                            }
                            {
                              f.site === SocialMediaSite.X && <FontAwesomeIcon icon={faXTwitter} size={"3x"} />
                            }
                            {
                              f.site === SocialMediaSite.YouTube && <FontAwesomeIcon icon={faYoutube} size={"3x"} />
                            }
                          </Col>
                          <Col xs={7} sm={9} md={9}>
                            <Row className="align-items-center">
                              <Col xs={12} sm={6} style={{ paddingTop: 5 }}>
                                {
                                  (f.site === SocialMediaSite.YouTube || f.site === SocialMediaSite.X || f.site === SocialMediaSite.TikTok || f.site === SocialMediaSite.Instagram) &&
                                  <InputGroup className="mb-2">
                                    <InputGroup.Text>@</InputGroup.Text>
                                    <Form.Control type="text" placeholder="handle" {...register(`profile.socialMediaAccounts.${i}.urlOrHandle`, { required: true })} required />
                                    <Form.Control.Feedback type="invalid" style={{ position: 'absolute', top: 40 }}>Handle is required</Form.Control.Feedback>
                                  </InputGroup>
                                }
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Controller
                                    name={`profile.socialMediaAccounts.${i}.followerCount`}
                                    control={control}
                                    rules={{
                                      validate: (value) => {
                                        if (typeof value === 'object' && (Object.keys(value).length === 0 || value.label === "Follower Count...")) {
                                          return "Follower Count is required";
                                        } else {
                                          return true;
                                        }
                                      },
                                    }}
                                    render={({ field }) =>
                                      <Select {...field} options={props.followerCounts} placeholder="Follower Count..."
                                        styles={{
                                          option: (base) => ({
                                            ...base,
                                            backgroundColor: '#fff',
                                            color: '#333'
                                          })
                                        }} />
                                    } />
                                </Form.Group>
                              </Col>
                            </Row>

                          </Col>
                          <Col xs={2} sm={1} md={1} className="pt-0">
                            <Button size="sm" onClick={() => { removeSMAccount(i) }} title="Remove Account" variant="outline-primary">
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </Col>
                        </Row>

                        <ProtectedItem requiredRoles={[Role.AppAdmin]}>
                          <Row style={{ backgroundColor: '#fefefe', padding: 10, border: '1px #990000 solid', borderRadius: 4, margin: '20px 5px 0 5px' }}>
                            <Col xs={12}>
                              <h3 style={{ fontSize: 18, color: '#990000' }}><FontAwesomeIcon icon={faLock} style={{ fontSize: 16, marginRight: 10 }} />Admin Fields</h3>
                              <p style={{ fontSize: 13 }}>The following fields are not editable by Creators.</p>


                            </Col>
                            <Col>
                              {/* <Form.Group style={{ marginBottom: 10 }}>
                                <Form.Label>AQS Score:</Form.Label>
                                <Form.Control type="number" placeholder="AQS Score" {...register(`profile.socialMediaAccounts.${i}.aqs.score`, { valueAsNumber: true })} />
                              </Form.Group> */}
                              <Form.Group style={{ marginBottom: 20, padding: 10 }}>
                                <FormCheck
                                  type="checkbox"
                                  label="Account Verified"
                                  {...register(`profile.socialMediaAccounts.${i}.verified`)}
                                />
                              </Form.Group>
                              <Form.Group style={{ marginBottom: 20 }}>
                                <Form.Label>Age:</Form.Label>
                                <Form.Control type="text" placeholder="Age" {...register(`profile.socialMediaAccounts.${i}.aqs.age`)} />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group style={{ marginBottom: 10 }}>
                                <Form.Label>Gender:</Form.Label>
                                <Form.Control type="text" placeholder="Gender" {...register(`profile.socialMediaAccounts.${i}.aqs.gender`)} />
                              </Form.Group>
                              <Form.Group style={{ marginBottom: 10 }}>
                                <Form.Label>Country:</Form.Label>
                                <Form.Control type="text" placeholder="Age" {...register(`profile.socialMediaAccounts.${i}.aqs.country`)} />
                              </Form.Group>
                            </Col>
                          </Row>
                        </ProtectedItem>

                      </Container>
                    </CSSTransition>
                  ))
                }
              </TransitionGroup>
              {selectedSocials === -1 && <div className="invalid-feedback" style={{ display: 'inline-block', paddingLeft: 20 }}>Please add at least one Social Media account</div>}
            </Col>
          </Row>
        </Container>
        <FormStageNav
          onNext={handleSubmit(onSubmit, onError)}
          onPrev={() => props.prevCallback(getValues())}
          localStages={props.localStages}
          currentStageName={props.currentStageName} />
      </Form>
    </>
  );
}
