import { useContext, useEffect, useState } from "react";
import { Button, Container, Row, Col, Alert, Spinner, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ResultType, useAuthApiCall } from "../../utils/useAuthApiCall";
import { Brief, BriefApplication, BriefStatus, loadBrief, setBriefForPost } from "../../models/brief";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import AttachmentItem from "../utils/AttachmentItem";
import ImageUploader from "../utils/ImageUploader";
import { ProtectedItem } from "../../utils/ProtectedItem";
import { AccountStatus, Role } from "../../models/appUser";
import AddBriefApplication from "./NewBriefApplication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft, faChevronRight, faCreditCard, faLock, faPencil, faReceipt, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import blueHash from "../../styles/img/doodles/blue/hashtag.webp";
import { PaymentSession, PaymentType, ProductId } from "../../models/payment";
import { LookupItem } from "../../models/lookupItem";
import Lifecycle from "./Lifecycle";

interface ViewBriefProps {
  briefId?: string;
  brandId?: string;
  adminMode?: boolean;
  refresh?: string;
}

export default function ViewBrief(props: ViewBriefProps) {

  const apiCall = useAuthApiCall();
  const [brief, setBrief] = useState({} as Brief);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { appUser } = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [existingApplication, setExistingApplication] = useState({} as BriefApplication);
  const [disableApplyButton, setDisableApplyButton] = useState(true);

  useEffect(() => {
    document.title = 'The Social Fuse: View Brief';
    try {
      // remove the background image
      (document.getElementsByClassName('sf-register-container')[0] as any).style.background = 'none';
    } catch (e) {
      // don't really care
    }

    // load brief
    const getBrief = async () => {
      const brief = await apiCall(`brands/${props.brandId}/briefs/${props.briefId}`, 'get');
      if (!brief) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      document.title = `The Social Fuse: ${brief.title}`;

      if (appUser.roles?.includes(Role.Creator)) {
        const existingApp = await apiCall(`brands/${brief.brandId}/briefs/${brief.id}/check`, 'get');
        if (existingApp && existingApp.id) {
          setExistingApplication(existingApp);
        } else {
          setDisableApplyButton(false);
        }
      }

      // get receipt if it's paid
      try {
        if (brief.isPaid && appUser.id === brief.userId) {
          const receipt = await apiCall(`payments/receipts/brief/${props.briefId}`, 'get', undefined, ResultType.Text);
          if (receipt) {
            brief.receiptUrl = receipt;
          }
        }
      } catch {
        console.warn("No receipt URL found for brief");
      }

      setBrief(loadBrief({ ...brief }) || {});
    }
    getBrief();
    return () => { try { (document.getElementsByClassName('sf-register-container')[0] as any).style = ''; } catch (e) { } }
  }, [apiCall, appUser.id, appUser.roles, props.brandId, props.briefId, props.refresh]);

  const submitForPayment = async (e: any) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      let sessionUrl = await apiCall('payments/session', 'POST', {
        priceId: ProductId.BRAND_AGENCY_POSTING_FEE,
        successUrl: `${window.location.protocol}//${window.location.host}/app/brands/${props.brandId}/briefs/${props.briefId}`,
        cancelUrl: window.location.href,
        mode: 'payment',
        briefId: props.briefId,
        brandId: props.brandId,
        paymentType: PaymentType.BriefPosting
      } as PaymentSession, ResultType.Text);

      let s = sessionUrl.substring(0, sessionUrl.length - 1)
      s = s.substring(1);

      window.location.href = s;
    } catch {
      alert('Error submitting brief');
    }
  }

  const submitForReview = async (e: any) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      let b = setBriefForPost(JSON.parse(JSON.stringify(brief)));
      await apiCall(`brands/${props.brandId}/briefs/${props.briefId}/submit`, 'put', b);
      navigate(`../brands/${props.brandId}/briefs`);
    } catch {
      alert('Error submitting brief');
    }
  }

  const addedApplication = (b: BriefApplication) => {
    console.log(b);
    setExistingApplication(b);
    setDisableApplyButton(true);
    setShowModal(false);
  }



  const noSub = (appUser.subscriptionId === 'trial' && appUser.trialEnd && new Date(appUser.trialEnd) < new Date()) ||
    (appUser.subscription && (appUser.subscription.Status !== 'active')) ||
    appUser.accountStatus === AccountStatus.Inactive;

  return (
    <>
      {
        isLoading &&
        <div style={{ textAlign: 'center', margin: 20 }}>
          <Spinner style={{}} animation="border" placeholder="Loading Briefs..." />
        </div>
      }

      {!isLoading && !brief.id && !props.adminMode && <Alert style={{ textAlign: 'center' }} variant="warning">Brief not found: Is the brief ID correct? Please get in touch if this is a problem.</Alert>}

      {!isLoading && brief.id &&
        <>

          {
            brief.userId === appUser.id && !props.adminMode &&
            <>
              {brief.approvalMessage && brief.approvalMessage.length > 0 && brief.briefStatus !== BriefStatus.Live && !props.adminMode &&
                <Alert variant="info" style={{ textAlign: 'center' }}>
                  Social Fuse Feedback: <b>{brief.approvalMessage}</b>
                </Alert>
              }
              <Lifecycle status={brief.briefStatus} />
            </>
          }
          {
            existingApplication && existingApplication.id &&
            <Alert variant="info" style={{ textAlign: 'center' }}>You have applied for this brief. You can see the status of your application on your <Link to="../creators/dashboard">Applications</Link> page</Alert>
          }

          <Container style={{ padding: '15px 30px 30px 30px' }}>

            {
              !props.adminMode &&
              <>
                <Row style={{ marginBottom: -20, paddingBottom: 0, marginTop: -15, minHeight: 60 }}>
                  <Col xs={12} sm={6} md={3}>
                    {
                      <ProtectedItem requiredRoles={[Role.Brand, Role.Agency]} renderError={false}>
                        <Button size="sm" variant="" style={{ marginTop: 20 }} onClick={() => navigate(`../brands/${props.brandId}/briefs`)}><FontAwesomeIcon icon={faChevronLeft} /> Back to Dashboard</Button>
                      </ProtectedItem>
                    }
                    {
                      <ProtectedItem requiredRoles={[Role.Creator]} renderError={false}>
                        <Button size="sm" variant="" style={{ marginTop: 20 }} onClick={() => navigate(`../creators/brief-board`)}><FontAwesomeIcon icon={faChevronLeft} /> Back to Brief Board</Button>
                      </ProtectedItem>
                    }
                  </Col>
                  <Col style={{ textAlign: 'right', margin: '20px 0' }}>
                    <ProtectedItem requiredRoles={[Role.Brand, Role.Agency]}>
                      <>
                        {
                          (brief.briefStatus === BriefStatus.Draft || brief.briefStatus === BriefStatus.ChangesRequired) &&
                          <>
                            <Button style={{ margin: '0 10px' }} size="sm" variant="secondary" onClick={(e) => { e.preventDefault(); navigate('./edit') }}><FontAwesomeIcon icon={faPencil} /> Make Changes</Button>
                            <Button style={{ margin: '0 10px' }} size="sm" variant="success" onClick={submitForReview} disabled={isSubmitting}>
                              {isSubmitting ? <Spinner size="sm" style={{ marginRight: 10 }} animation="border" placeholder="Submitting..." /> :
                                <FontAwesomeIcon icon={faCheck} />} Submit for Review <FontAwesomeIcon icon={faChevronRight} />
                            </Button>
                          </>
                        }
                        {!brief.isPaid && brief.briefStatus === BriefStatus.Approved &&
                          <Button style={{ margin: '0 10px' }} size="sm" variant="success" onClick={submitForPayment} disabled={isSubmitting}>
                            {isSubmitting ? <Spinner size="sm" style={{ marginRight: 10 }} animation="border" placeholder="Submitting..." /> :
                              <FontAwesomeIcon icon={faCreditCard} />} Payment <FontAwesomeIcon icon={faChevronRight} />
                          </Button>
                        }
                        {brief.isPaid && brief.receiptUrl && brief.receiptUrl.length > 0 && <>
                          <a href={brief.receiptUrl.substring(1, brief.receiptUrl.length - 1)} target="_blank" rel="noreferrer" style={{ display: 'none' }} id="receipt-link">View Receipt</a>
                          <Button style={{ margin: '0 10px' }} size="sm" variant="success" onClick={(e) => { e.preventDefault(); document.getElementById('receipt-link')?.click() }}><FontAwesomeIcon icon={faReceipt} /> View Receipt</Button>
                        </>
                        }
                      </>
                    </ProtectedItem>
                    {
                      <ProtectedItem requiredRoles={[Role.Creator]} renderError={false}>
                        <>
                          {
                            !disableApplyButton &&
                            <>
                              {
                                noSub ?
                                  <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 800 }}
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        <div style={{ padding: 10 }}>
                                          <h3>Unable to apply</h3>
                                          <p>You do not currently have an active subscription, so cannot apply for briefs.</p>
                                          <p>Visit your <Link to="/app/account/profile">Account</Link> to get yourself signed up :)</p>
                                        </div>
                                      </Tooltip>}
                                  >
                                    <Button variant="success" size="sm" style={{ cursor: 'default' }}><FontAwesomeIcon icon={faSquareCheck} /> Apply Now</Button>
                                  </OverlayTrigger>
                                  :
                                  <Button variant="success" size="sm"
                                    onClick={(e) => { e.preventDefault(); setShowModal(true); }}>
                                    <FontAwesomeIcon icon={faSquareCheck} /> Apply Now
                                  </Button>
                              }
                            </>
                          }
                        </>
                      </ProtectedItem>
                    }
                  </Col>
                </Row>
                <hr />
              </>
            }
            <Row>
              <Col style={{ textAlign: 'center' }} >
                <h2><span className="sf-underline sf-blue-underline">{brief.title}</span></h2>
              </Col>
            </Row>

            <Row style={{ margin: '10px 0 40px 0' }}>
              <ImageUploader
                fileId={brief.bannerImage?.id && `${brief.userId}/${brief.bannerImage?.id}`}
                additionalStyles={{ width: '100%', height: 200, margin: '10px 0 10px 0' }}
                fallbackImageUrl="/brief-banner.webp" />
            </Row>

            <Row>
              <Col sm={6}>
                <Container style={{ padding: 0 }}>
                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Brand website:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}><a href={brief.brandWebsite} target="_blank" rel="noreferrer">{brief.brandName}</a></span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Applications close:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.campaignTo && (new Date(brief.campaignTo)).toDateString()}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Campaign start month:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.campaignStartMonth}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Activity type:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.activityType?.map((l: LookupItem) => (l.label)).join(', ')}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Social platforms:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.platforms?.map((l: LookupItem) => (l.label)).join(', ')}</span>
                    </Col>
                  </Row>

                  {
                    (brief.userId === appUser.id || props.adminMode) &&
                    <>
                      <Row style={{ backgroundColor: '#fefefe', padding: 10, border: '1px #990000 solid', borderRadius: 4, margin: '20px 5px 0 5px' }}>
                        <Col>
                          <h3 style={{ fontSize: 18, color: '#990000' }}><FontAwesomeIcon icon={faLock} style={{ fontSize: 16, marginRight: 10 }} />Private Fields</h3>
                          <p style={{ fontSize: 13 }}>The following fields are not shared with Creators.</p>

                          <Row>
                            <Col sm={4}>
                              <span className="sf-label-regular">Total Campaign budget (£):</span>
                            </Col>
                            <Col>
                              <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.totalCampaignBudget}</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={4}>
                              <span className="sf-label-regular">No. of Influencers Required:</span>
                            </Col>
                            <Col>
                              <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.numberInfluencersRequired}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  }

                </Container>
              </Col>
              <Col>
                <Container style={{ padding: 0 }}>
                  <Row>
                    <Col>
                      <span className="sf-label-regular">Required content:</span>
                      <div style={{ width: '100%', background: 'none', minHeight: '150px', padding: 5 }}>
                        {brief.requiredContent}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row style={{ position: 'relative' }}>
              <img src={blueHash} style={{ position: 'absolute', width: 80, top: 0, right: -5 }} alt="Brief Description" />
              <Col style={{ padding: 40, margin: 10, borderRadius: 3 }} className="sf-purple-fill">
                <h3>Brief Description</h3>
                <div style={{ width: '100%', background: 'none', border: 'none', minHeight: '150px', padding: 5 }}>
                  {brief.description}
                </div>
                <Container>
                  <Row>
                    <Col sm={8} style={{ marginLeft: -30 }}>
                      <h3>Attachments</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ margin: '0 -30px' }}>
                      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                        {brief.attachments?.map((file: any, index: Number) => (
                          <AttachmentItem
                            key={`attachment-${file.name || file.fileName}`}
                            file={file}
                            userId={brief.userId}
                          />
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col sm={12} md={6}>
                <h2 style={{ padding: '10px 0 0 0px' }}>Creator Requirements</h2>
                <Container style={{ padding: 0 }}>
                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Follower size:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.requiredCreator?.followerCount?.map((l: LookupItem) => (l.label)).join(', ')}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Age ranges:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.requiredCreator?.ages?.map((l: LookupItem) => (l.label)).join(', ')}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Gender identity:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.requiredCreator?.gender?.map((g) => g.label.replace("PreferNotToSay", "Other / Prefer not to say")).join(', ')}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Content sectors:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}>{brief.requiredCreator?.contentAreas?.map((l: LookupItem) => (l.label)).join(', ')}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Other considerations:</span>
                    </Col>
                    <Col>
                      <div style={{ width: '100%', background: 'none', minHeight: '50px', padding: 5 }} >
                        {brief.requiredCreator?.otherConsiderations}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col>
                <h2 style={{ padding: '10px 0 0 0px' }}>Audience Demographics:</h2>
                <Container style={{ padding: 0 }}>
                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Age range:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}> {brief.audienceDemographics?.ages?.map((l: LookupItem) => (l.label)).join(', ')}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <span className="sf-label-regular">Gender identity:</span>
                    </Col>
                    <Col>
                      <span className="sf-label-regular" style={{ fontWeight: 'normal' }}> {brief.audienceDemographics?.gender?.map((g) => g.label.replace("PreferNotToSay", "Other / Prefer not to say")).join(', ')}</span>
                    </Col>
                  </Row>

                </Container>
              </Col>
            </Row>
          </Container>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Apply for Brief</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddBriefApplication briefId={props.briefId || ''} brandId={props.brandId || ''} onSubmit={addedApplication} />
            </Modal.Body>
          </Modal>

        </>
      }



    </>
  );

}
