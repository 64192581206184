export interface QueryModel {
    filters: FilterModel[],
    sort?: SortModel,
    page?: number,
    pageSize?: number
}

export interface SortModel {
    property: string,
    direction: string
}

export interface FilterModel {
    property: string,
    value: string,
    filterType: string
}

export enum FilterType {
    Equals = 'Equals',
    StringEquals = 'StringEquals',
    BooleanEquals = 'BooleanEquals',
    Contains = 'Contains',
    Custom = 'Custom',
    Exists = 'Exists',
}

export interface QueryResultModel<T> {
    data: T[],
    total: number
}
