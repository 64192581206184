import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useAuthApiCall } from "../../utils/useAuthApiCall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileAlt, faHand, faMagnifyingGlass, faMailBulk, faPaperPlane, faPencil, faSpinner, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import TSFTable from "../../utils/TSFTable";
import { ColumnDef, ColumnFilter, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { FilterModel, FilterType, QueryModel, QueryResultModel, SortModel } from "../../models/query";
import { Brief, BriefStatus, loadBrief } from "../../models/brief";
import ViewBrief from "../brief/ViewBrief";
import Status from "../brief/Status";
import AddUpdateBrief from "../brief/NewBrief";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppUser } from "../../models/appUser";

export default function BriefAdmin() {
  const [briefs, setBriefs] = useState({ data: [], total: 0 } as QueryResultModel<Brief>);
  const [{ showModal, selectedBrief }, setShowModal] = useState({ showModal: false, selectedBrief: {} as Brief });
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [sendingEmails, setSendingEmails] = useState(false);
  const [emailsSentTo, setEmailsSentTo] = useState(Array<AppUser>())
  const [briefRefresh, setBriefRefresh] = useState(new Date().toTimeString());
  const apiCall = useAuthApiCall();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(new Date().toTimeString());
  const [loading, setLoading] = useState(true);

  let preFilters = [] as FilterModel[];
  searchParams.forEach((v, k) => {
    preFilters.push({ property: k, value: v, filterType: FilterType.StringEquals } as FilterModel)
  });

  let query: QueryModel = {
    filters: preFilters,
    sort: { property: "dateAdded", direction: "desc" } as SortModel,
    page: 0,
    pageSize: pageSize
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [briefs] = await Promise.all([
        apiCall('brief/all', 'post', query)
      ]);
      setBriefs(briefs);
      setLoading(false);
    }
    getData();
  }, [apiCall, refresh]);

  const updateBriefStatus = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let update = {
        status: selectedBrief.briefStatus,
        message: selectedBrief.approvalMessage
      }
      let updatedBrief = await apiCall(`brands/${selectedBrief.brandId}/briefs/${selectedBrief.id}/status`, 'post', update);
      let loadedBrief = loadBrief(updatedBrief);
      setIsSubmitting(false);

      // replace updated brief in briefs list
      let newBriefs = [...briefs.data];
      let index = newBriefs.findIndex((x: Brief) => x.id === updatedBrief.id);
      newBriefs[index] = loadedBrief;
      setBriefs({ data: newBriefs, total: briefs.total });

      setShowModal({ showModal: false, selectedBrief: loadedBrief });
    } catch (e) {
      setIsSubmitting(false);
      alert("Could not update status - please see browser console.")
      console.log(e);
    }
  }

  const filterBriefs = async (filters: ColumnFiltersState, sorting: SortingState, pagination: PaginationState) => {
    setLoading(true);
    setPageSize(pagination.pageSize);

    filters.forEach((f: ColumnFilter) => {
      let meta = (columns.find((c: ColumnDef<Brief>) => c.id === f.id)?.meta) as any;

      if (meta?.filterType === FilterType.Custom) {
        if (f.value === "Yes") {
          query.filters.push({
            property: f.id,
            value: 'true',
            filterType: FilterType.BooleanEquals
          });
        }
        else if (f.value === "No") {
          query.filters.push({
            property: f.id,
            value: 'false',
            filterType: FilterType.BooleanEquals
          });
        }
      } else {
        query.filters.push({
          property: f.id,
          value: (f.value as string),
          filterType: meta?.filterType || FilterType.Contains
        });
      }
    });

    if (sorting.length > 0) {
      query.sort = {
        property: sorting[0].id,
        direction: sorting[0].desc ? "desc" : "asc"
      }
    }

    query.page = pagination.pageIndex;
    query.pageSize = pagination.pageSize;

    let u = await apiCall('brief/all', 'post', query);
    setBriefs(u);
    setLoading(false);
  }

  const columns: ColumnDef<Brief>[] = [
    {
      header: "Brand",
      accessorKey: "brandName",
      cell: (info: any) => info.getValue(),
      id: "brandName",
      footer: props => <strong>Total: {briefs.total}</strong>,
    },
    {
      header: "Title",
      accessorKey: "title",
      cell: (info: any) => info.getValue(),
      id: "title"
    },
    {
      header: "Added",
      accessorKey: "dateCreated",
      enableColumnFilter: false,
      cell: (info: any) => new Date(info.getValue()).toLocaleDateString(),
      id: "dateCreated"
    },
    {
      header: "From",
      accessorKey: "campaignFrom",
      enableColumnFilter: false,
      cell: (info: any) => new Date(info.getValue()).toLocaleDateString(),
      id: "campaignFrom"
    },
    {
      header: "To",
      accessorKey: "campaignTo",
      enableColumnFilter: false,
      cell: (info: any) => new Date(info.getValue()).toLocaleDateString(),
      id: "campaignTo"
    },

    {
      header: "Paid?",
      accessorKey: "isPaid",
      meta: {
        opts: ["Yes", "No"],
        filterType: FilterType.Custom
      },
      cell: (info: any) => {
        return info.getValue() ? <div style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faCheck} /></div> : "";
      },
      id: "isPaid"
    },
    {
      header: "Status",
      accessorKey: "briefStatus",
      cell: (info: any) => <div style={{ textAlign: 'center' }}><Status status={info.getValue()} /></div>,
      id: "briefStatus",
      meta: {
        opts: Object.keys(BriefStatus),
        filterType: FilterType.StringEquals
      },
    },
    {
      header: "App. Count",
      accessorKey: "applicationCount",
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info: any) => { return <div style={{ textAlign: 'center' }}>{info.getValue()}</div> },
      id: "applicationCount"
    },
    {
      header: " ",
      accessorKey: "actions",
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info: any) => {
        let b = info.row.original as Brief;
        return (
          <div style={{ width: 105 }}>
            <Button size="sm" variant={'primary'} onClick={() => { setShowModal({ showModal: true, selectedBrief: b }) }}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
            <Button style={{ marginLeft: 5 }} title="Brand Account" size="sm" variant="secondary" onClick={() => navigate(`/app/admin/users?id=${b.userId}`)}><FontAwesomeIcon icon={faUser} /></Button>
            {b.applicationCount > 0 && <Button style={{ marginLeft: 5 }} title="Applications" size="sm" variant="danger" onClick={() => navigate(`/app/admin/applications?briefId=${b.id}`)}><FontAwesomeIcon icon={faHand} /></Button>}
          </div>
        );
      },
      id: "actions"
    }
  ];

  const sendEmails = async () => {
    setSendingEmails(true);
    try {
      let emails = await apiCall(`brands/${selectedBrief.brandId}/briefs/${selectedBrief.id}/emails`, 'get');
      setEmailsSentTo(emails);
    } catch (e) {
      console.log(e);
      alert("Could not send emails - please see browser console.")
    }
    setSendingEmails(false);
  }

  return (
    <>
      <h2 style={{ margin: '10px 0', borderBottom: '3px #5CD490 solid', textAlign: 'right', color: '#5CD490' }}><FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 24 }} /> Briefs</h2>
      {
        preFilters.length > 0 &&
        <Alert variant="info">
          Page filters applied:
          <ul style={{ margin: 0 }}>
            {
              preFilters.map((f: FilterModel) => <li>{f.property}: {f.value}</li>)
            }
          </ul>
          <div style={{ textAlign: 'right' }}>
            <Button size="sm" onClick={() => { navigate('/app/admin/briefs'); setRefresh(new Date().toTimeString()) }}>Clear <FontAwesomeIcon icon={faTimes} /></Button>
          </div>
        </Alert>
      }
      <TSFTable
        columns={columns}
        data={briefs.data}
        filter={filterBriefs}
        pageCount={Math.floor(briefs.total / pageSize) + 1}
        loading={loading}
      />
      <Modal size="xl" show={showModal} onHide={() => setShowModal({ showModal: false, selectedBrief: selectedBrief })}>
        <Modal.Header closeButton>
          <Modal.Title>Administer Brief</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="info">
            <Container>
              <Row><Col><h5>Update Status & Notify Brand / Agency:</h5></Col></Row>
              <Row>
                <Col sm={4} style={{}}>
                  <select
                    style={{ display: 'inline-block' }}
                    className="form-select"
                    onChange={(e) => { let s = { ...selectedBrief }; s.briefStatus = e.target.value as BriefStatus; setShowModal({ showModal: true, selectedBrief: s }) }}
                    value={selectedBrief.briefStatus}>
                    {
                      Object.keys(BriefStatus).map((a) => {
                        return <option key={a} value={a}>{a}</option>
                      })
                    }
                  </select>
                </Col>
                <Col sm={6}>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Feedback for user if not making Active ..."
                    value={selectedBrief.approvalMessage || ""}
                    onChange={(e) => { let s = { ...selectedBrief }; s.approvalMessage = e.target.value; setShowModal({ showModal: true, selectedBrief: s }) }} />
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button size="sm" disabled={isSubmitting} variant="success" onClick={updateBriefStatus}>
                    {isSubmitting ? <Spinner size="sm" style={{ marginRight: 10 }} animation="border" placeholder="Submitting..." /> : <FontAwesomeIcon icon={faPaperPlane} />} Update</Button>
                </Col>
              </Row>
            </Container>
          </Alert>
          <div style={{ textAlign: 'right' }}>
            <Button size="sm" variant="danger" onClick={() => { setShowEmailModal(true) }}><FontAwesomeIcon icon={faMailBulk} /> Email Creators</Button>&nbsp;
            <Button size="sm" variant="secondary" onClick={() => { setShowEditModal(true) }}><FontAwesomeIcon icon={faPencil} /> Edit</Button>
          </div>
          <ViewBrief briefId={selectedBrief.id} brandId={selectedBrief.brandId} adminMode={true} refresh={briefRefresh} />
        </Modal.Body>
      </Modal>

      {selectedBrief?.id &&
        <Modal size="xl" show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Brief</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddUpdateBrief briefId={selectedBrief.id} brandId={selectedBrief.brandId} adminMode={true} submitCallback={(b: Brief) => {
              let newBriefs = [...briefs.data];
              let index = newBriefs.findIndex((x: Brief) => x.id === b.id);
              newBriefs[index] = b;
              setBriefs({ data: newBriefs, total: briefs.total });
              setBriefRefresh(new Date().toTimeString());
              setShowEditModal(false);
            }} />
          </Modal.Body>
        </Modal>
      }

      {showEmailModal &&
        <Modal size="lg" show={showEmailModal} onHide={() => { setEmailsSentTo([]); setShowEmailModal(false) }}>
          <Modal.Header closeButton>
            <Modal.Title>Email Creators</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>When you're ready to post this brief to all matching creators, click this button. Be patient, it may take a while.</p>
            <div style={{ textAlign: 'center', backgroundColor: '#fefefe', padding: 10, border: '1px #990000 solid', borderRadius: 4, margin: '20px 5px 0 5px' }}>
              <Button size="lg" variant="danger" disabled={sendingEmails} onClick={() => sendEmails()}>{sendingEmails ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faMailBulk} />} SEND EMAILS (DEMO)</Button>
            </div>
            {!sendingEmails && emailsSentTo.length === 0 && <p style={{ padding: 10, textAlign: 'center' }}>No emails sent</p>}
            {!sendingEmails && emailsSentTo.length > 0 && <p style={{ padding: 10, textAlign: 'center' }}>{emailsSentTo.length} matchings creators found:</p>}
            {!sendingEmails &&
              <ul>
                {emailsSentTo.map((u: AppUser) => <li>{u.email}</li>)}
              </ul>
            }
          </Modal.Body>
        </Modal>
      }


    </>
  );
}
