import { useAuth0 } from "@auth0/auth0-react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Role } from "../models/appUser";
import { ProtectedItem } from "../utils/ProtectedItem";
import logo from "../styles/img/logos/tsf-logo-white-sm.png";
import { useContext } from "react";
import { UserContext } from "../contexts/userContext";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TopNav() {
  const { logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const { appUser } = useContext(UserContext);

  const isActive = (path: string) => {
    return location.pathname.indexOf(path) !== -1;
  }

  const nav = (e: any, url: string) => {
    e.preventDefault();
    navigate(url);
  }

  return (
    <Navbar id="sf-public-nav" sticky="top" collapseOnSelect={true} expand="lg" bg="dark" variant="dark">
      <Container>
        <a className="navbar-brand" href="/#">
          <img src={logo} alt="The Social Fuse" className="sf-logo" />
        </a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <ProtectedItem requiredRoles={[Role.Creator]}>
              <>
                <Nav.Item>
                  <Nav.Link className={isActive("/app/creators/ad-board") ? "nav-link active" : "nav-link"} href="/app/creators/brief-board" onClick={(e) => nav(e, "/app/creators/brief-board")}>Brief Board</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className={isActive("/app/creators/dashboard") ? "nav-link active" : "nav-link"} href="/app/creators/dashboard" onClick={(e) => nav(e, "/app/creators/dashboard")}>My Applications</Nav.Link>
                </Nav.Item>
              </>
            </ProtectedItem>
            <ProtectedItem requiredRoles={[Role.Agency]}>
              <Nav.Item>
                <Nav.Link className={isActive("/app/brands") ? "nav-link active" : "nav-link"} href="/app/brands" onClick={(e) => nav(e, "/app/brands")}>Dashboard</Nav.Link>
              </Nav.Item>
            </ProtectedItem>
            {
              appUser.profile?.brands && appUser.profile?.brands.length > 0 &&
              <ProtectedItem requiredRoles={[Role.Brand]}>
                <Nav.Item>
                  <Nav.Link className={isActive("/app/brands/dashboard") ? "nav-link active" : "nav-link"} href={`/app/brands/${appUser.profile?.brands[0]?.brandGuid}/briefs`} onClick={(e) => nav(e, `/app/brands/${appUser.profile?.brands[0]?.brandGuid}/briefs`)}>Dashboard</Nav.Link>
                </Nav.Item>
              </ProtectedItem>
            }
          </Nav>
          <Nav>
            <ProtectedItem requiredRoles={[Role.Brand, Role.Agency, Role.Creator, Role.AppAdmin]}>
              <Nav.Item>
                <Nav.Link className={isActive("/app/account/profile") ? "nav-link active" : "nav-link"} href="/app/account/profile" onClick={(e) => nav(e, "/app/account/profile")}>Account</Nav.Link>
              </Nav.Item>
            </ProtectedItem>
            <ProtectedItem requiredRoles={[Role.AppAdmin]}>
              <Nav.Item>
                <Nav.Link href="/app/profile" onClick={(e) => nav(e, "/app/admin/users")}>Admin</Nav.Link>
              </Nav.Item>
            </ProtectedItem>
            <Nav.Item>
              <Button variant="primary" size="sm" onClick={(e) => { e.preventDefault(); logout({ returnTo: window.location.origin }) }}>Log out &nbsp;&nbsp;<FontAwesomeIcon icon={faSignOut} /></Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
