import { Container, Col, Row, Accordion } from "react-bootstrap";


export default function FAQ() {

  return (
    <>
      <Container fluid>
        <Container>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h1 style={{ marginTop: 60 }}>
                <span className="sf-underline sf-blue-underline">FAQs</span>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container fluid className='sf-green-banner' style={{ margin: '40px 0 80px 0' }}>
                Have a question? Please take a look at the below frequently asked questions. Can't see your question? Drop us a <a href="mailto:info@thesocialfuse.co.uk" style={{ color: '#fff' }}>message</a> and we'd be happy to help.
              </Container>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} style={{ marginBottom: 40 }}>
              <h2 style={{ textAlign: 'center', marginBottom: 20 }}>Brands and Agencies</h2>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>What social platforms can we post a campaign brief for?</Accordion.Header>
                  <Accordion.Body>
                    Currently, we focus on Instagram, YouTube, TikTok, and X (Twitter).
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>How do you ensure creators are of high quality?</Accordion.Header>
                  <Accordion.Body>
                    We recognise that every brand has its unique requirements and objectives for campaigns. While we never want to make assumptions about your specific needs, there are certain standards we believe are essential for all brands to uphold when collaborating with creators. Here's how we ensure that creators meet these standards:
                    <br/><br/>
                    <ul>
                      <li>
                        <b>Brand Safety:</b>
                        <p>
                          To maintain a safe and reputable platform, we actively monitor and exclude Instagram and YouTube accounts for content flagged for toxic behaviour, religious extremism, negative sentiments, offensive language, overt political statements, crime-related themes, adult content, and pranks. This vetting is facilitated through an influencer analytics tool to ensure that the content aligns with general brand safety standards while still accommodating brands that may have specific content preferences, such as those involving alcohol-related promotions. We also cross-reference with any significant negative news about the creator through a search engine check. However, the final due diligence and responsibility lie with the brand/agency when deciding to engage with a creator.
                        </p>
                      </li>
                      <li>
                        <b>Relevant Audience for UK Campaigns:</b>
                        <p>
                          We only onboard creators whose social profiles show more than 40% of their audience based in the UK. This is verified either through the influencer analytics tool data or directly from the creator. (For social platforms that provide this data)
                        </p>
                      </li>
                      <li>
                        <b>Avoiding Fake Followers on Instagram and TikTok:</b>
                        <p>We vet Instagram and TIkTok profiles of creators for signs of fake followers. If a creator's account shows more than 25% suspicious followers, as determined by the influencer analytics tool data, we do not include them in our community.</p>
                      </li>
                      <li>
                        <b>Low YouTube / TikTok Video Views:</b>
                        <p>For YouTube and TikTok creators, we uphold a benchmark where accounts with under 500 average views per video are not considered.</p>
                      </li>
                      <li>
                        <b>Upholding Content Quality:</b>
                        <p>Though we respect varied artistic interpretations, we do filter out creators who consistently produce content of subpar technical quality - be it poor lighting, low resolution, or unclear imagery. In addition, we champion originality. Therefore, if a considerable portion of their content isn't original, they won't make the cut. To gauge this, we look at factors like style consistency and the frequency with which creators appear in their content.</p>
                        <p>Note: Our assessment criteria mentioned above are implemented during the creator's registration process. However, the ultimate decision to collaborate with a creator rests with the brand or agency. If you come across a creator on our platform that doesn't align with the outlined quality benchmarks, please bring it to our attention by emailing us at <a href="mailto:info@thesocialfuse.co.uk">info@thesocialfuse.co.uk</a> with the subject "REPORT". We will promptly investigate and take necessary actions, including removing the creator from The Social Fuse if needed</p>
                      </li>
                      <li>
                        <b>Promoting Originality:</b>
                        <p>While we understand that creators might occasionally share content from other sources, we champion originality. If a considerable portion of their content isn't original, they won't make the cut. To gauge this, we look at factors like style consistency and the frequency with which creators appear in their content.</p>
                      </li>
                    </ul>
                    <b>Note</b>:
                    Our assessment criteria mentioned above are implemented during the creator's registration process. However, the ultimate decision to collaborate with a creator rests with the brand or agency. If you come across a creator on our platform that doesn't align with the outlined quality benchmarks, please bring it to our attention by emailing us at <a href="mailto:info@thesocialfuse.co.uk">info@thesocialfuse.co.uk</a> with the subject "REPORT". We will promptly investigate and take necessary actions, including removing the creator from The Social Fuse if needed.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>We're a media agency, can we use The Social Fuse on our client's behalf?</Accordion.Header>
                  <Accordion.Body>
                    Yes, media or PR agencies managing influencer marketing for clients are welcome to register using an Agency account. We kindly request that you don't create a brand-specific account for each client. Instead, with your Agency account, you can represent multiple clients on The Social Fuse. However, when posting a brief, it's imperative to specify the client's name. Anonymous briefs, such as those intended for pitches, are not permitted. If there's a reason preventing you from disclosing the client's name when submitting a brief, please reach out to us, and we'll explore possible solutions.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Can my brief be rejected?</Accordion.Header>
                  <Accordion.Body>
                    Yes, briefs can be rejected if they lack sufficient details, are ambiguous, request inappropriate content, or violate our terms of use. If a brief is rejected, we will inform you of the reason, allowing you to amend and resubmit it.  Rest assured, you will only be charged once your brief receives approval.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>What kind of support does The Social Fuse offer for campaign management?</Accordion.Header>
                  <Accordion.Body>
                    The Social Fuse specialises in streamlining the influencer identification and selection process. Our platform is designed to help you quickly find relevant and high-quality creators, significantly cutting down the time typically required for influencer discovery and outreach. We focus on connecting you with creators who are not only relevant but also genuinely interested in your brand, allowing you to establish direct contact and build relationships with them off-platform. For full campaign management beyond the discovery and initial outreach stages, please <a href="mailto:info@thesocialfuse.co.uk">reach out</a> to us. We can facilitate further assistance through our network of verified partners, tailored to meet your specific campaign needs. This approach ensures you receive specialised support that aligns with your project objectives.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col style={{ marginBottom: 40 }}>
              <h2 style={{ textAlign: 'center', marginBottom: 20 }}>Creators</h2>
              <Accordion>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Do I need to list all my social channels during registration?</Accordion.Header>
                  <Accordion.Body>
                    No, only add the channels where you're comfortable posting sponsored content. If you suspect that we might reject a specific channel, based on our requirements, it's best to omit it during registration.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>Can any creator/ influencer use The Social Fuse?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Currently, The Social Fuse is a platform exclusive to the UK, meaning we only accept UK-based creators who are over 18 years old and can participate in UK-focused campaign briefs. If you're based outside of the UK and are interested in joining, email <a href="mailto:info@thesocialfuse.co.uk">info@thesocialfuse.co.uk</a> to join our waiting list for future expansions.  To ensure the highest quality of partnerships, we have set criteria for creators before they can register. Here's a breakdown of our requirements:
                    </p>
                    <ul>
                      <li>
                        <b>Relevant Audience for UK Campaigns:</b>
                        <p>As only UK based briefs will be posted on The Social Fuse, it's essential for us that our creators cater mainly to a UK audience. Therefore, we'll only accept your social platforms that have over 40% audience in from the UK. This will be based on influencer analytics tool data and can be verified directly by you (applicable to platforms that offer such data).</p>
                      </li>
                      <li>
                        <b>Minimum Follower Count of 1,000:</b>
                        <p>To be a part of The Social Fuse, you should have at least 1,000 followers on any of the platforms we support: Instagram, YouTube, TikTok and Twitter.</p>
                      </li>
                      <li>
                        <b>Fake Followers Check (For Instagram and TikTok):</b>
                        <p>Integrity is key. If your Instagram or TikTok account has more than 25% suspicious followers, as indicated by an influencer analytics tool, you won't be eligible to join our community.</p>
                      </li>
                      <li>
                        <b>Engagement Rate Threshold (For Instagram): </b>
                        <p>While the "Engagement" metric has evolved, we still require a decent rate. If your engagement consistently falls below 1%, you might not meet our criteria. If you hide likes then this will be based in minimal or no comments on posts. This is derived from the formula: (Likes + Comments) ÷ Total Followers.</p>
                      </li>
                      <li>
                        <b>Video View Requirements (For YouTube and TikTok):</b>
                        <p>For YouTube and TikTok influencers, your account should maintain an average of 500 views per video (based on your last 25 videos).</p>
                      </li>
                      <li>
                        <b>Upholding Content Standards:</b>
                        <p>We value quality. If your content is often deemed as of low technical quality or potentially offensive, you might not fit our platform. We assess content for clarity, resolution, and potential controversy. Additionally, we keep an eye out for significant negative news about creators. However, brands are responsible for final checks before collaborating.</p>
                      </li>
                      <li>
                        <b>Brand Safety:</b>
                        <p>
                          To ensure a safe and positive environment for brand collaborations, we actively monitor for content involving toxic behaviour, religious extremism, negative sentiments, offensive language, overt political statements, crime-related themes, adult content, and pranks. Creators whose content frequently includes these elements may not be suitable for our platform. Additionally, we monitor for significant negative news about creators via a search engine check.
                        </p>
                        <p>
                          Please note: If you think any of your social channels might not align with our standards, it's better to exclude them from your registration.
                        </p>
                      </li>
                    </ul>
                    <p>
                      Please note: If you think any of your social channels might not align with our standards, it's better to exclude them from your registration.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>Can I be removed from The Social Fuse?</Accordion.Header>
                  <Accordion.Body>
                    Our primary goal is to maintain the high quality of The Social Fuse community. If at any point your social channels don't uphold the standards we've set, we might reach out for clarification. If necessary, this could result in removal from the platform. We routinely review creators' profiles, and brands also have the capability to report any creators they believe aren't meeting our criteria or violating our terms of use. Rest assured, we won't take any hasty decisions; we'll always investigate concerns and discuss them with you before making a final decision.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>If I'm approved for a brief on The Social Fuse, am I automatically confirmed for that campaign?</Accordion.Header>
                  <Accordion.Body>
                    Receiving approval on The Social Fuse indicates that, based on an initial review of your social account and the data we provide, the brand is impressed by your profile and believes you'd be a good fit for their campaign. However, this approval simply provides the brand access to your contact details, allowing them to communicate with you directly. It doesn't guarantee a confirmed booking for the campaign. The brand may request additional details or wish to discuss the campaign further with you. After connecting you with the brand, The Social Fuse's role in the process concludes.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>Can my manager or agent sign up and apply for briefs on my behalf?</Accordion.Header>
                  <Accordion.Body>
                    <p>Yes, your manager or agent can register you on The Social Fuse, as long as they possess all the necessary information. However, we strongly advise creators to apply for briefs personally. This ensures that you are genuinely interested in the campaign, preventing situations where a brand believes you're on board, only to later discover you're not engaged. Repeated incidents of this nature could lead to removal from our platform.</p>
                    <p>While your manager can apply for a brief on your behalf, it's crucial you're informed and have shown genuine interest in it. A significant advantage of The Social Fuse is the direct connection it fosters between brands and creators. This direct engagement is a primary reason brands are drawn to our platform.</p>
                    <p>We urge creators to personally apply for briefs, giving you the chance to add a unique touch to your application, with a tailored message. This can distinguish your application from others. Once a brand approves your application and reaches out, if you wish to transition communication to your manager or agent, that decision rests with you.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>What happens if I don't get selected for a brief I applied for?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      At The Social Fuse, we are committed to ensuring that every application for a brief is a valuable experience, regardless of the outcome. We understand that not being selected can be disappointing, which is why we have implemented a mandatory feedback system. Brands and agencies are required to provide constructive feedback on your application, offering insights into the decision-making process and helping you identify areas for improvement. Our aim is to turn each application into a learning opportunity that contributes to your professional growth and enhances your chances of success in future opportunities. To further increase your rate of success, we've implemented several strategic measures. We conduct a strict influencer vetting process to ensure that our platform hosts creators who are more likely to meet brands' requirements. Additionally, we limit the number of applications a brand can receive to a maximum of 30, reducing competition and significantly boosting your chances of being selected. We also apply filters on the Brief Board, ensuring you only see briefs that are most relevant to your profile. This decreases the likelihood of being rejected due to irrelevance, making each application more likely to succeed.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>How does The Social Fuse ensure fair compensation for creators?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      At The Social Fuse, we understand the importance of fair compensation and strive to ensure that every brief you consider is worth your time. While negotiations regarding rates take place directly between you and the brand or agency, we facilitate this process by implementing key measures. Each brief explicitly states the compensation type—be it monetary payment or product gifting—allowing you to understand the terms right from the start. Additionally, we conduct a thorough budget check, a behind-the-scenes process where our experienced team reviews the proposed budget against the campaign's objectives to ensure it aligns with industry standards. This check is based on our deep understanding of typical rates for influencers of various sizes and niches. While this review is at the campaign level, and specific rate discussions will occur directly with the brand if you are selected, our initial vetting process helps set the stage for fair and realistic compensation discussions, ensuring transparency in every engagement.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header>Why does The Social Fuse charge a fee for access?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      At The Social Fuse, we charge a minimal monthly fee for several important reasons. Firstly, this fee enables us to continuously enhance and improve the platform, ensuring we deliver great service. Secondly, it provides us with the resources to scout new and exciting opportunities on behalf of our creators, acting much like a partner invested in your growth. Finally, it helps us subsidise the costs for brands, encouraging a broader range of participation from innovative start-ups to well-established brands with substantial campaign budgets, which might not typically invest in costly subscription models. This approach increases the variety and breadth of collaborations available to you. To ensure we offer you good value, we have kept the fee minimal at £3.49 per month. Moreover, to let you experience the real value of our platform, we offer a 3-month free trial, without requiring any upfront payment or payment details. <a href="/app">Register today</a> to get started.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>Why should I apply for briefs if I already receive offers directly?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      With The Social Fuse, you take control of your career by actively choosing collaborations that ignite your passion and meet your professional criteria, instead of passively waiting for offers to land in your inbox. In an industry teeming with thousands of creators, it's all too easy to be overlooked. By applying for collaborations, you ensure that you are visible to your favourite brands. This proactive approach not only increases your chances of engaging in campaigns that truly excite you but also gives you the freedom to align with opportunities that best match your skills and audience. Using The Social Fuse empowers you with choice, opening doors to a broader spectrum of potential collaborations and helping you grow your business.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
