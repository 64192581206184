import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { useAuthApiCall } from "../../utils/useAuthApiCall";
import { Brief } from "../../models/brief";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Status from "./Status";


export default function BriefDashboard() {

  const [briefs, updateBriefs] = useState([] as Array<Brief>);
  const { brandId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const apiCall = useAuthApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    // load briefs
    const getBriefs = async () => {
      const briefs = await apiCall(`brands/${brandId}/briefs`, 'get');
      updateBriefs(briefs || []);
      setIsLoading(false);
    }

    document.title = 'The Social Fuse: Brief Dashboard';

    getBriefs();
  }, [apiCall, brandId]);

  return (
    <Container style={{ margin: '40px auto' }}>
      <Row style={{ marginBottom: 30 }}>
        <Col md={{ offset: 2, span: 8 }} xs={6}>
          <h2 style={{ textAlign: 'center' }}><span className="sf-underline sf-blue-underline">Brief Dashboard</span></h2>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button size="sm" variant="secondary" onClick={(e) => { e.preventDefault(); navigate(`../brands/${brandId}/briefs/create`) }}>
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />Post New Brief</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            isLoading && <div style={{ textAlign: 'center', margin: 20 }}>
              <Spinner style={{}} animation="border" placeholder="Loading Briefs..." />
            </div>
          }
          {
            !isLoading && briefs.length === 0 &&
            <div style={{ textAlign: 'center', margin: 20 }}>
              <p>Get started by clicking 'Post New Brief'</p>
            </div>
          }
          {
            briefs.length > 0 &&
            <Table borderless>
              <thead style={{ backgroundColor: '#333', color: '#fff', border: '2px #333 solid' }}>
                <tr>
                  <th>
                    Title
                  </th>
                  <th style={{textAlign: 'center'}}>
                    Applications Period
                  </th>
                  <th style={{textAlign: 'center'}}>
                    Date Posted
                  </th>
                  <th style={{textAlign: 'center'}}>
                    No. Applications Received
                  </th>
                  <th style={{textAlign: 'center'}}>
                    View Brief
                  </th>
                  <th style={{textAlign: 'center'}}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody style={{ border: '2px #333 solid', borderRadius: 5, backgroundColor: '#fff' }}>
                {
                  briefs.map((b: Brief) => (
                    <tr key={b.id}>
                      <td style={{padding: '15px'}}>
                        {b.title}
                      </td>
                      <td style={{padding: '15px', textAlign: 'center'}}>
                        {b.campaignFrom && (new Date(b.campaignFrom)).toLocaleDateString()} - {b.campaignTo && (new Date(b.campaignTo)).toLocaleDateString()}
                      </td>
                      <td style={{padding: '15px', textAlign: 'center'}}>
                        {b.dateCreated && (new Date(b.dateCreated)).toLocaleDateString()}
                      </td>
                      <td style={{padding: '15px', textAlign: 'center'}}>
                        <Link to={`/app/brands/${b.brandId}/briefs/${b.id}/applications`}>{b.applicationCount} <span style={{fontSize: '12px'}}>/30</span></Link> 
                      </td>
                      <td style={{padding: '15px', textAlign: 'center'}}>
                        <Link to={`/app/brands/${b.brandId}/briefs/${b.id}`}>View</Link>
                      </td>
                      <td style={{padding: '15px', textAlign: 'center'}}>
                        <Status status={b.briefStatus} />
                      </td>                      
                    </tr>))
                }
              </tbody>
            </Table>
          }
        </Col>
      </Row>
    </Container>
  );

}
