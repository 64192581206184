import { useContext } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import { Brand } from "../../models/appUser";
import { LookupItem } from "../../models/lookupItem";


export default function BrandsChooser() {
  const navigate = useNavigate();
  const { appUser } = useContext(UserContext);

  return (
    <Container style={{ margin: '40px auto' }}>
      <Row style={{ marginBottom: 30 }}>
        <Col md={{ offset: 2, span: 8 }} xs={6}>
          <h2 style={{ textAlign: 'center' }}><span className="sf-underline sf-blue-underline">Select a Brand</span></h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            <Table borderless>
              <thead style={{ backgroundColor: '#333', color: '#fff', border: '2px #333 solid' }}>
                <tr>
                  <th>
                    Brand Name
                  </th>
                  <th style={{ textAlign: 'center' }}>
                    Website
                  </th>
                  <th style={{ textAlign: 'center' }}>
                    Sectors
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody style={{ border: '2px #333 solid', borderRadius: 5, backgroundColor: '#fff' }}>
                {
                  appUser.profile?.brands.map((b: Brand) => (
                    <tr key={b.brandGuid}>
                      <td style={{ padding: '15px' }}>
                        {b.name}
                      </td>
                      <td style={{ padding: '15px', textAlign: 'center' }}>
                        {b.website}
                      </td>
                      <td style={{ padding: '15px', textAlign: 'center' }}>
                        {b.sectors?.map((s: LookupItem) => `${s.label}, `)}
                      </td>
                      <td style={{ padding: '15px', textAlign: 'center' }}>
                        <Button size="sm" variant="primary" onClick={(e) => { e.preventDefault(); navigate(`./${b.brandGuid}/briefs/`) }}>View Briefs &gt;</Button>
                      </td>
                    </tr>))
                }
              </tbody>
            </Table>
          }
        </Col>
      </Row>
    </Container>
  );

}
