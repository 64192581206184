export enum ProductId {
    INFLUENCER_MONTHLY_SUB_TEST = 'price_1NzHSiFwjHFaS0Cg5E8cq9cs',
    BRAND_AGENCY_POSTING_FEE_TEST = 'price_1O0Q25FwjHFaS0Cg4y776h9Q',
    INFLUENCER_MONTHLY_SUB = 'price_1MeIwPFwjHFaS0Cg0iR9jTgE',
    BRAND_AGENCY_POSTING_FEE = 'price_1MeNAwFwjHFaS0CgSSzw56YD'
}

export interface PaymentSession {
    successUrl: string,
    cancelUrl: string,
    priceId: string,
    mode: string,
    briefId?: string,
    brandId?: string,
    paymentType: PaymentType
}

export enum PaymentType {
    UserSubscription = "UserSubscription",
    BriefPosting = "BriefPosting"
}