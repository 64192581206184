import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useEffect, useState } from 'react';

import { Table as BTable, Col, Container, Pagination, Row, Spinner } from 'react-bootstrap'
import { propTypes } from 'react-bootstrap/esm/Image';

interface TableProps {
  columns: any;
  data: any;
  filter: (columnFilters: ColumnFiltersState, sorting: SortingState, pagination: PaginationState) => void;
  pageCount: number;
  loading?: boolean;
}

export default function TSFTable({ columns, data, filter, pageCount, loading }: TableProps) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    []
  )
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });

  const table = useReactTable({
    data: data,
    columns: columns,
    manualFiltering: true,
    manualSorting: true,
    manualPagination: true,
    pageCount: pageCount,
    state: {
      columnFilters,
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  useEffect(() => {
    filter(columnFilters, sorting, pagination);
  }, [columnFilters, sorting, pagination])

  // build pages array for pagination
  const pages = [];
  for (let i = 0; i < table.getPageCount(); i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    pages.push(<Pagination.Item active={table.getState().pagination.pageIndex === i} onClick={() => table.setPageIndex(i)}>{i + 1}</Pagination.Item>);
  }

  return (
    <>
      <BTable striped bordered hover responsive size="sm">
        <thead>
          {
            table.getHeaderGroups().map(headerGroup => (
              <>
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} colSpan={header.colSpan}>
                      {
                        header.isPlaceholder ? null :
                          (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>

                          )}
                    </th>
                  ))}
                </tr>
                {
                  headerGroup.headers.find((h: any) => h.column.getCanFilter()) &&
                  <tr key={`${headerGroup.id}-filters`}>
                    {headerGroup.headers.map(header => (
                      <th key={`${header.id}-filter`} colSpan={header.colSpan} style={{ backgroundColor: '#fefefe', padding: '5px 15px' }}>
                        {header.column.getCanFilter() ? (
                          <div>
                            {(header.column.columnDef.meta && (header.column.columnDef.meta as any).opts) ?
                              <select className='form-select' onChange={(e) => header.column.setFilterValue(e.target.value)}>
                                <option value="">All</option>
                                {(header.column.columnDef.meta as any).opts.map((opt: any) => {
                                  return <option key={opt} value={opt}>{opt}</option>
                                })}
                              </select>
                              :
                              <DebouncedInput
                                type="text"
                                value={(header.column.getFilterValue() ?? '') as string}
                                onChange={e => {
                                  if (header.column.getFilterValue()) {
                                    if (header.column.getFilterValue() !== e) {
                                      header.column.setFilterValue(e);
                                    }
                                  } else {
                                    if (e !== '') header.column.setFilterValue(e);
                                  }
                                }}
                                placeholder={`Filter...`}
                                className="form-control"
                              />
                            }
                          </div>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                }
              </>
            ))}
        </thead>
        <tbody>
          {loading ?
            <tr>
              <td colSpan={columns.length} style={{ textAlign: 'center', padding: 20 }}>
                <Spinner animation="border" />
              </td>
            </tr>
            :
            data.length === 0 ?
              <tr>
                <td colSpan={columns.length} style={{ textAlign: 'center', padding: 20 }}>
                  No records found.
                </td>
              </tr>
              :
              table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.footer,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </BTable>
      <Container style={{ padding: 0, margin: 0 }}>
        <Row style={{ padding: 0, margin: 0 }}>
          <Col sm={9} style={{ padding: 0, margin: 0 }}>
            <Pagination>
              <Pagination.First onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} />
              <Pagination.Prev onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
              {
                pages.map((m) => m)
              }
              <Pagination.Next onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
              <Pagination.Last onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} />
            </Pagination>
          </Col>
          <Col style={{ padding: 0, margin: 0 }}>
            <select className='form-select'
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

