import { AccountStatus, AppUser, AppUserProfile } from "../../models/appUser";
import FormStageChooser from "./FormStageChooser";

// hook form examples - https://codesandbox.io/s/079xx?file=/src/index.js:2955-3000
// css animation examples - https://csshint.com/css-animation/ - last one for brand / creator selector? 
// https://www.sliderrevolution.com/resources/css-text-animation/ - for css text

interface Props {
  user: AppUser
  useProposedProfile?: boolean
  adminMode?: boolean
  submitCallback?: (u: AppUser) => void
}

export default function RegistrationForm(props: Props) {
  const u = props.user;
  u.profile = u.profile || {} as AppUserProfile;
  if (props.useProposedProfile) {
    u.profile = u.proposedProfile || u.profile;
  }

  // defaults for new users
  if(u.accountStatus === AccountStatus.New) u.profile.displayLastName = true;

  //init arrays if needed
 // if (!u.profile.brands || u.profile.brands.length === 0) u.profile.brands = [{ name: "", website: "" }];
 // if (!u.profile.socialMediaAccounts || u.profile.socialMediaAccounts.length === 0) u.profile.socialMediaAccounts = [];

  return (
    <FormStageChooser u={props.user} adminMode={props.adminMode} submitCallback={(u: AppUser) => props.submitCallback && props.submitCallback(u)} />
  );
}
