import { Row, Form, Col, Container } from "react-bootstrap";
import { AppUser } from "../../models/appUser";
import { Controller, useForm } from "react-hook-form";
import FormStageNav from "./FormStageNav";
import { useState } from "react";
import Select from "react-select";
import { FormStageProps } from "../../models/forms";
import { LookupItem } from "../../models/lookupItem";

interface Props extends FormStageProps {
  sectors: Array<LookupItem>
}

export default function BrandInfoStage(props: Props) {
  let u = props.u;
  if (u.profile && !u.profile.brands) u.profile.brands = [];
  if (u.profile && u.profile.brands && u.profile.brands.length > 1) u.profile.brands.splice(1);

  document.title = 'The Social Fuse: Register - Your Brand details';

  const { control, register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<AppUser>({ defaultValues: u });
  const [validated, setValidated] = useState(false);
  const onSubmit = (u: AppUser) => {
    props.nextCallback(u);
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid className="sf-form-body">
          <Row style={{marginBottom: 40}}>
            <Col>
              <div className="sf-registration-stage-title">
                <h2>Your brand</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={{ offset: 3, span: 6 }}>
              <Form.Group className="mb-3">
                <Form.Label>Brand Name:</Form.Label>
                <Form.Control type="text" placeholder="Brand Name" {...register(`profile.brands.0.name` as const, { required: true })} required />
              </Form.Group>
            </Col>
            <Col sm={12} md={{ offset: 3, span: 6 }}>
              <Form.Group className="mb-3">
                <Form.Label>Brand Website:</Form.Label>
                <Form.Control type="text" placeholder="Brand Website (http://...)" {...register(`profile.brands.0.website` as const, { onBlur: (e) => {if(e.target.value && !e.target.value.toLowerCase().startsWith('http')) setValue(`profile.brands.0.website`, `http://${e.target.value}`);}, validate: (value) => { if (!value || (value && !value.startsWith('http://') && !value.startsWith('https://'))) return false } })} required />
                {errors.profile?.brands?.length && errors.profile.brands.length > 0 && errors.profile.brands[0]?.website && <div className="invalid-feedback" style={{ display: 'inline-block' }}>A website must begin with http:// or https://</div>}
              </Form.Group>
            </Col>
            <Col sm={12} md={{ offset: 3, span: 6 }}>
              <Form.Group className="mb-3">
                <Form.Label>Brand LinkedIn:</Form.Label>
                <Form.Control type="text" placeholder="Brand LinkedIn (https://www.linkedin.com/...)" {...register(`profile.brands.0.linkedIn` as const, { required: true })} required />
                {errors.profile?.brands?.length && errors.profile.brands.length > 0 && errors.profile.brands[0]?.linkedIn && <div className="invalid-feedback" style={{ display: 'inline-block' }}>Please supply a LinkedIn address</div>}
              </Form.Group>
            </Col>
            <Col sm={12} md={{ offset: 3, span: 6 }}>
              <Form.Group className="mb-3">
                <Form.Label>Sectors:</Form.Label>
                <Controller
                  key={`select-brand-info`}
                  name={`profile.brands.0.sectors` as const}
                  control={control}
                  render={({ field }) =>
                    <Select placeholder="Sectors" {...field} options={props.sectors} isMulti />
                  } />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <FormStageNav
          onNext={handleSubmit(onSubmit, onError)}
          onPrev={() => props.prevCallback(getValues())}
          localStages={props.localStages}
          currentStageName={props.currentStageName} />

      </Form>
    </>
  );
}
