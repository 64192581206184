import { AppUser } from "../models/appUser";
import RegistrationForm from "./registration/RegistrationForm";

interface Props {
  appUser: AppUser
  adminMode?: boolean
  submitCallback?: (u: AppUser) => void
}

export default function Register(props: Props) {

  return (
    <>
      <RegistrationForm user={props.appUser} useProposedProfile={true} adminMode={props.adminMode} submitCallback={(u: AppUser) => props.submitCallback && props.submitCallback(u)}/>
    </>
  );
}
