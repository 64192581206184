import { Row, Form, Button, Col, ListGroup, Container } from "react-bootstrap";
import { AppUser } from "../../models/appUser";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import FormStageNav from "./FormStageNav";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useState } from "react";
import { FormStageProps } from "../../models/forms";
import { LookupItem } from "../../models/lookupItem";
import Select from "react-select";

interface Props extends FormStageProps {
  sectors: Array<LookupItem>
}

export default function BrandsStage(props: Props) {
  let tempUser = props.u;
  // if(tempUser.profile) tempUser.profile.brands = [];

  const { control, register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<AppUser>({ defaultValues: tempUser });
  const [validated, setValidated] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'profile.brands'
  });

  document.title = 'The Social Fuse: Register - Brands you represent';

  // let watchedBrands = watch('profile.brands');

  const addBrand = () => {
    append({
      name: "", website: "", sectors: [], brandGuid: ""
    });
  }

  const onSubmit = (u: AppUser) => {
    props.nextCallback(u);
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid className="sf-form-body">
          <Row style={{marginBottom: 40}}>
            <Col>
              <div className="sf-registration-stage-title">
                <h2>Brands you represent</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <Button size="sm" onClick={addBrand}><FontAwesomeIcon icon={faPlus} /> Add Brand</Button>
            </Col>
          </Row>

          <hr className="mb-4" />
          <ListGroup style={{boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)'}}>
            <TransitionGroup component={null}>
              {
                fields.map((f: any, i) => (
                  <CSSTransition key={f.id} timeout={300} classNames="social-row">
                    <ListGroup.Item>
                      <Row key={`row-${f.id}`} className="mb-3">
                        <Col sm={12} md={6} lg={3} style={{marginBottom: 10}}>
                          <Form.Control type="text" placeholder="Brand Name" {...register(`profile.brands.${i}.name` as const, { required: true })} required />
                        </Col>
                        <Col sm={12} md={6} lg={4} style={{marginBottom: 10}}>
                          <Form.Control type="text" placeholder="Brand Website (http://...)" {...register(`profile.brands.${i}.website` as const, { onBlur: (e) => {if(e.target.value && !e.target.value.toLowerCase().startsWith('http')) setValue(`profile.brands.${i}.website`, `http://${e.target.value}`)}, validate: (value) => { if (!value || (value && !value.startsWith('http://') && !value.startsWith('https://'))) return false } })} />
                          {errors.profile?.brands?[i-1] && <div className="invalid-feedback" style={{ display: 'inline-block' }}>A website must begin with http:// or https://</div> : ''}
                        </Col>
                        <Col sm={12} md={12} lg={4}>
                          <Form.Group className="mb-3">
                            <Controller
                              key={`select-${f.id}`}
                              name={`profile.brands.${i}.sectors` as const}
                              control={control}
                              render={({ field }) =>
                                <Select placeholder="Sectors" {...field} options={props.sectors} isMulti />
                              } />
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={1} style={{textAlign: 'right'}}>
                          <Button size="sm" onClick={() => { remove(i) }} title="Remove Brand">
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </CSSTransition>
                ))
              }
            </TransitionGroup>
          </ListGroup>
          <div className="invalid-feedback" style={{ display: 'inline-block', textAlign: 'right', paddingTop:10 }}>Please add at least one brand. Others can be added / amended later.</div>
        </Container>
        <FormStageNav
          onNext={handleSubmit(onSubmit, onError)}
          onPrev={() => { console.log(getValues()); props.prevCallback(getValues()) }}
          localStages={props.localStages}
          currentStageName={props.currentStageName} />
      </Form>
    </>
  );
}
