import React from 'react';
import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/custom.scss';

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

const myApp =
  <React.StrictMode>
   <App />   
  </React.StrictMode>

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(myApp, rootElement);
} else {
  root.render(myApp);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
