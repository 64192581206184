import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row, Container, Spinner } from "react-bootstrap";
import { FormStages } from "../../models/forms";

interface Props {
  onNext: any
  onPrev: any
  localStages: Array<FormStages>
  currentStageName: FormStages,
  disableSubmit?: boolean,
  isSubmitting?: boolean
}

export default function FormStageNav(props: Props) {

  let currentStageIndex = props.localStages.findIndex((s: FormStages) => (s === props.currentStageName));
  let isSubmitStage = currentStageIndex === props.localStages.length - 2;

  return (
    <Container fluid className="sf-form-nav">
      <Row>
        <Col>
          <Button variant="outline-primary" onClick={props.onPrev} className="text-s">
            <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
          </Button>
        </Col>
        <Col className="text-end">
          <Button className="text-end" variant="outline-primary" type="submit" onClick={props.onNext} disabled={props.disableSubmit === true}>
            {isSubmitStage ? <>Submit&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} /></> : <>Next&nbsp;&nbsp;{props.isSubmitting ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faArrowRight} />}</>}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
