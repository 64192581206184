import { LookupItem } from "./lookupItem";
import { UserFile } from "./utils";

export interface AppUser {
    id: string
    email?: string
    accountType?: AccountType
    subscriptionId?: string
    trialEnd?: Date
    subscription?: any
    accountStatus?: AccountStatus
    feedback?: string
    roles?: Array<Role>
    profile?: AppUserProfile
    proposedProfile?: AppUserProfile
    createdWhen?: Date
    updatedWhen?: Date
}

export interface AppUserProfile {
    firstName?: string
    lastName?: string
    displayLastName?: boolean
    age?: Age
    gender?: Gender
    contentAreas?: Array<LookupItem>
    country?: string
    agencyName?: string
    agencyWebsite?: string
    linkedinUrl?: string
    personalLinkedInUrl?: string
    brands: Array<Brand>
    socialMediaAccounts: Array<SocialMediaAccount>
    compensationTypes?: Array<CompensationType>
    consentToTC: boolean
    consentToMarketing: boolean,
    bio: string,
    picture?: UserFile
}

export interface Brand {
    name: string
    website: string
    linkedIn?: string
    sectors?: Array<LookupItem>
    brandGuid: string
}

export interface SocialMediaAccount {
    site: SocialMediaSite
    urlOrHandle: string
    followerCount: FollowerCount
    aqs?: AQS
    verified: boolean
}

export interface AQS {
    score: number
    age: string
    gender: string
    country: string
}

export interface FollowerCount {
    label: string
    min: number
    max: number
}

export enum AccountStatus {
    New = "New",
    Updated = "Updated",
    RequiresChange = "RequiresChange",
    Active = "Active",
    Rejected = "Rejected",
    Inactive = "Inactive",
}

export enum SocialMediaSite {
    Instagram = "Instagram",
    TikTok = "TikTok",
    X = "X",
    YouTube = "YouTube",
}

export enum Role {
    Creator = "Creator",
    Brand = "Brand",
    Agency = "Agency",
    AppAdmin = "AppAdmin",
    None = "None"
}

export enum AccountType {
    Creator = "Creator",
    Brand = "Brand",
    Agency = "Agency"
}

export enum Gender {
    Male = "Male",
    Female = "Female",
    PreferNotToSay = "PreferNotToSay"
}

export const genders = Object.keys(Gender).map((g) => { return { label: g.replace("PreferNotToSay", "Other / Prefer not to say"), value: g } });

export interface Age {
    label: string,
    min: number,
    max: number
}

export enum CompensationType {
    Gifting = "Gifting",
    Paid = "Paid"
}