import { Container, Button, Col, Row } from "react-bootstrap";
import yellowGirl from "../styles/img/photography/yellow-girl-webp.webp";
import brownHat from "../styles/img/photography/homepageguy_whiteframe.webp";
import blueGirl from "../styles/img/photography/blue-girl.webp";
import graffiti from "../styles/img/photography/graffiti.webp";
import selfie from "../styles/img/photography/selfie.webp";
import blueHash from "../styles/img/doodles/blue/hashtag.webp";
import greenAsterix from "../styles/img/doodles/green/asterix.webp";
import greenArrow from "../styles/img/doodles/green/CURLY ARROW.png";
import ladyTablet from "../styles/img/photography/howitworks_brand2.webp";
import phone from "../styles/img/photography/howitworks_brand3.webp";
import keyboard from "../styles/img/photography/howitworks_brand1.webp";
import laptop from "../styles/img/photography/howitworks_creator1.webp";
import camera from "../styles/img/photography/howitworks_creator3.webp";
import greenhouse from "../styles/img/photography/howitworks_creator2.webp";
import TermsOfUse from "./TermsOfUse";
import { Routes, Route, Link } from "react-router-dom";
import Privacy from "./Privacy";
import { useAuth0 } from "@auth0/auth0-react";
import config from "../config.json";

export default function HomePageContent() {
  const { loginWithRedirect } = useAuth0();
  const register = async () => {
    console.log("registering...", config.auth0ClientId, config.auth0Domain);
    loginWithRedirect({ screen_hint: 'signup', redirect_uri: `${window.location.origin}/app/register`, client_id: config.auth0ClientId, domain: config.auth0Domain });
  }

  return (
    <>
      <Container fluid className='sf-banner'>
        <Container>
          <Row>
            <Col xs={12} sm={5} className="sf-banner-text">
              <h1>
                <div className="sf-text-hider" style={{ paddingRight: 10 }}>
                  <div className="first">
                    <span className="sf-underline sf-green-underline">Creator</span>
                  </div>
                  <div className="second">
                    <span className="sf-underline sf-green-underline">Brand</span>
                  </div>
                </div>
                <div className="sf-banner-line-2" style={{ display: 'inline-block' }}><span className="sf-meet">meet</span>
                  <div className="sf-text-hider" style={{ top: 0, marginLeft: '0%' }}>
                    <div className="first">
                      <span>Brand.</span>
                    </div>
                    <div className="second">
                      <span>Creator.</span>
                    </div>
                  </div>
                </div>
              </h1>
              <ul className='sf-doodle-list'>
                <li>Discover genuine collaborations in our curated network, where quality meets authenticity.</li>
              </ul>
              <br />
            </Col>
            <Col xs={12} sm={7}>
              <Row>
                <Col md={7} sm={12} className="sf-banner-img-col-1">
                  <img src={yellowGirl} alt="Social media influencer connecting with a brand" className="sf-banner-img-1" />
                </Col>
                <Col md={5} className="sf-banner-img-col-2 d-none d-md-block">
                  <img src={brownHat} alt="Social media user" className="sf-banner-img-2" />
                  <img src={blueGirl} alt="Social media influencer promoting a product" className="sf-banner-img-3" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <div className="sf-pull-up">
        <Container className='sf-how-works'>
          <Row>
            <Col lg={{ span: 1, offset: 2 }} md={{ span: 1, offset: 1 }} sm={{ span: 1, offset: 1 }} style={{ position: 'relative' }}>
              <img src={greenArrow} alt="green arrow doodle" className="sf-green-arrow d-none d-lg-block" />
            </Col>
            <Col lg={{ span: 6 }} md={{ span: 8, offset: 0 }} sm={{ span: 8 }}>
              <div className="sf-how-works-text">
                <h4 className="sf-collaborations">Ready to ignite seamless collaborations?</h4>
                <p className='lead'>Explore how it works below...</p>
                <Button href='#creator' id="brand" variant='primary' style={{ marginBottom: 10 }}>I'm a creator</Button>
                <Button href='#brand'  variant='primary' style={{ marginBottom: 10 }}>I'm a brand/agency</Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="sf-brand">
          <Row className="sf-navy-header">
            <Col>
              <Container>
                <Row>
                  <Col md={{ span: 6, offset: 6 }}>
                    <h3>Listen up, Brands &amp; Agencies...</h3>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col md={{ span: 5, offset: 0 }} style={{ textAlign: 'right' }}>
                    <img src={graffiti} alt="Only deal with influencers who are interested in your campaign" className="sf-brand-img" />
                  </Col>
                  <Col md={{ span: 5, offset: 1 }} >
                    <div className="sf-brand-text">
                      <h4>Maximize Your Budget, Minimize the Fuss</h4>
                      <p>Only pay when you post – no contracts or subscriptions</p>
                      <h4>Effortlessly Attract Relevant Creators</h4>
                      <p>No more endless searches – interested influencers come directly to you.</p>
                      <h4>Only the Best: Vetted & Authentic Audiences</h4>
                      <p>Partner with creators who have genuine followers and view their comprehensive audience insights.</p>
                    </div>
                  </Col>
                  <Col md={{ span: 1, offset: 0 }} >
                    <img src={greenAsterix} alt="Green asterix mark" className="sf-brand-icon rotate linear infinite" />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid style={{ padding: '80px 0 60px 0', textAlign: 'center' }}>
          <Container fluid style={{ marginBottom: 20 }}>
            <Row>
              <Col md={{ span: 4, offset: 4 }}>
                <h3>So, how does it work?</h3>
              </Col>
              <Col md={{ span: 1 }} style={{ position: 'relative', top: -40 }}>
                <img src={greenArrow} alt="green arrow doodle" className="sf-green-arrow d-none d-lg-block" style={{ transform: 'scaleX(-1)' }} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }} sm={{ span: 12, offset: 0 }}>
                <Container style={{ textAlign: 'left' }}>
                  <Row>
                    <Col sm={6} style={{ position: 'relative', paddingTop: 20 }}>
                      <div className="sf-how-works-num">1.</div>
                      <h4 className="sf-how-works-title"><span className="sf-underline sf-purple-underline">Register</span></h4>
                      <div className="sf-how-works-text">
                        <p className="sf-how-works-text-inner">
                          Sign up for free in just 2 minutes or less
                        </p>
                      </div>

                    </Col>
                    <Col sm={6} style={{ textAlign: 'center' }}>
                      <img src={keyboard} alt="Brand registering on The Social Fuse" style={{ maxWidth: '100%' }} className="sf-how-works-img" />
                    </Col>
                    <Col sm={6} style={{ textAlign: 'center' }} className="d-none d-lg-block">
                      <img src={ladyTablet} alt="A lady posting a brief on The Social Fuse" style={{ maxWidth: '100%' }} className="sf-how-works-img" />
                    </Col>
                    <Col md={6} style={{ position: 'relative', paddingTop: 20 }}>
                      <div className="sf-how-works-num">2.</div>
                      <h4 className="sf-how-works-title"><span className="sf-underline sf-purple-underline">Craft Your Brief</span></h4>
                      <div className="sf-how-works-text">
                        <p className="sf-how-works-text-inner">
                          Provide a few key details about your campaign, and we'll find the perfect creators for you. (See <a href="/pricing" title="Pricing">pricing page</a> for details)
                        </p>
                      </div>

                    </Col>
                    <Col sm={6} style={{ textAlign: 'center' }} className="d-lg-none">
                      <img src={ladyTablet} alt="A lady posting a brief on The Social Fuse" style={{ maxWidth: '100%' }} className="sf-how-works-img" />
                    </Col>
                    <Col md={6} style={{ paddingTop: 20 }}>
                      <div className="sf-how-works-num">3.</div>
                      <h4 className="sf-how-works-title"><span className="sf-underline sf-purple-underline">Review & Select Creators</span></h4>
                      <div className="sf-how-works-text">
                        <p className="sf-how-works-text-inner">
                          Browse creators who've shown interest in your campaign. Approve your top choices to unlock their contact information. Now it's over to you!
                        </p>
                      </div>
                    </Col>
                    <Col md={6} style={{ textAlign: 'center' }}>
                      <img src={phone} alt="A brand reviewing creators who have applied for their campaign" style={{ maxWidth: '100%' }} className="sf-how-works-img" />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row style={{ marginTop: 80, textAlign: 'left' }}>
              <Col xl={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }}>
                <h3><span className="sf-underline sf-purple-underline">The Best Bit?</span></h3>
                <p style={{ fontSize: '1rem' }}>
                  Instantly view profiles of interested creators complete with social handles, application messages, and verified audience demographics*. Streamline your approval process and save valuable hours!
                </p>
                <p style={{ fontSize: '0.7rem' }}>*Available for select social platforms.</p>
                <div style={{ textAlign: 'center' }}>
                  <div className="sf-purple-highlight-left">
                    <div style={{ fontSize: '1.2rem' }}>
                      <Button variant="success" id="creator" onClick={() => register()}>Register Now!</Button>
                    </div>
                    <div className="sf-purple-highlight-right">&nbsp;</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container fluid className="sf-creator" id="creator">
          <Row className="sf-navy-header">
            <Col>
              <Container>
                <Row>
                  <Col md={{ span: 6, offset: 1 }} >
                    <h3>This is for you Creators...</h3>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col md={{ span: 6, offset: 1 }} >
                    <div className="sf-creator-text">
                      <h4>Turn Your Creative Spark into Steady Work</h4>
                      <p>With The Social Fuse, 'The Brief Board' is your gateway to connect with brands eagerly searching for creators like you.</p>
                      <h4>Take Control of Your Collaborations</h4>
                      <p>Why wait to be chosen? With The Social Fuse, you take the reins. Browse active campaigns and hand-pick collaborations that resonate with your passions.</p>
                    </div>
                  </Col>
                  <Col md={{ span: 1 }}>
                    <img src={blueHash} alt="Blue hashtag" className="sf-creator-icon" />
                  </Col>
                  <Col md={{ span: 4, offset: 0 }} style={{ textAlign: 'left' }}>
                    <img src={selfie} alt="Only deal with brands you're passionate about" className="sf-creator-img" />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid style={{ padding: '80px 0 60px 0', textAlign: 'center' }}>
          <Container fluid style={{ marginBottom: 20 }}>
            <Row>
              <Col md={{ span: 4, offset: 4 }}>
                <h3>So, how does it work?</h3>
              </Col>
              <Col md={{ span: 1 }} style={{ position: 'relative', top: -40 }}>
                <img src={greenArrow} alt="green arrow doodle" className="sf-green-arrow d-none d-lg-block" style={{ transform: 'scaleX(-1)' }} />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }} sm={{ span: 12, offset: 0 }}>
                <Container style={{ textAlign: 'left' }}>
                  <Row>
                    <Col sm={6} style={{ position: 'relative', paddingTop: 20 }}>
                      <div className="sf-how-works-num">1.</div>
                      <h4 className="sf-how-works-title"><span className="sf-underline sf-purple-underline">Sign Up</span></h4>
                      <div className="sf-how-works-text">
                        <p className="sf-how-works-text-inner">
                          Simply provide a few details about your social channels, your content style, and your collaboration preferences. This information helps us match you with brands that align with your unique style and interests, ensuring relevant opportunities for you.
                        </p>
                      </div>
                    </Col>
                    <Col sm={6} style={{ textAlign: 'center' }}>
                      <img src={laptop} alt="Creator registering on The Social Fuse" style={{ maxWidth: '100%' }} className="sf-how-works-img" />
                    </Col>
                    <Col sm={6} style={{ textAlign: 'left' }} className="d-none d-lg-block">
                      <img src={greenhouse} alt="A content creator thinking about a brand collaboration " style={{ maxWidth: '100%' }} className="sf-how-works-img" />
                    </Col>
                    <Col md={6} style={{ position: 'relative', paddingTop: 20 }}>
                      <div className="sf-how-works-num">2.</div>
                      <h4 className="sf-how-works-title"><span className="sf-underline sf-purple-underline">Discover Exciting Opportunities</span></h4>
                      <div className="sf-how-works-text">
                        <p className="sf-how-works-text-inner">
                          Head to 'The Brief Board' to review a curated selection of campaigns tailored for you. Plus, receive regular email updates with relevant briefs, ensuring you never miss out on a great collaboration opportunity.
                        </p>
                      </div>
                    </Col>
                    <Col sm={6} style={{ textAlign: 'center' }} className="d-lg-none">
                      <img src={greenhouse} alt="A creator applying for a brand campaign" style={{ maxWidth: '100%' }} className="sf-how-works-img" />
                    </Col>
                    <Col md={6} style={{ paddingTop: 20 }}>
                      <div className="sf-how-works-num">3.</div>
                      <h4 className="sf-how-works-title"><span className="sf-underline sf-purple-underline">Make Your Pitch</span></h4>
                      <div className="sf-how-works-text">
                        <p className="sf-how-works-text-inner">
                          When a campaign sparks your interest, reach out by clicking 'Apply'. Share your vision with the brand, highlighting why you're the perfect fit.
                        </p>
                      </div>
                    </Col>
                    <Col md={6} style={{ textAlign: 'center' }}>
                      <img src={camera} alt="A brand reviewing creators who have applied for their campaign" style={{ maxWidth: '100%' }} className="sf-how-works-img" />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row style={{ marginTop: 80, textAlign: 'left' }}>
              <Col xl={{ span: 10, offset: 1 }}>
                <h3><span className="sf-underline sf-purple-underline">What Happens Next?</span></h3>
                <p style={{ fontSize: '1rem' }}>
                  Once you've applied to a campaign, keep an eye on your inbox. If a brand is interested in collaborating, they'll be provided with your contact details and will connect with you directly.
                </p>
                <div style={{ textAlign: 'center' }}>
                  <div className="sf-purple-highlight-left">
                    <div style={{ fontSize: '1.2rem' }}>
                      <Button variant="success" onClick={() => register()}>Register Now!</Button>
                    </div>
                    <div className="sf-purple-highlight-right">&nbsp;</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container style={{ marginTop: 40, backgroundColor: '#5273EA', color: '#fff', padding: '40px 0' }}>
          <Row>
            <Col className="sf-have-a-question" sm={6}>
              <h2 >Have a <br /><span className="sf-underline sf-purple-underline">question?</span></h2>
            </Col>
            <Col sm={6} lg={6} style={{ padding: '0 30px' }}>
              <h5 style={{ paddingTop: 20, fontSize: '1.4rem', fontWeight: 'normal' }}>Questions About The Social Fuse?</h5>
              <p>
                Check out our <Link to="/faq" style={{ color: '#fff' }}>FAQ page for more information</Link>
              </p>
              <h5 style={{ paddingTop: 20, fontSize: '1.4rem', fontWeight: 'normal' }}>For Other Inquiries or Feedback...</h5>
              <p>
                We'd love to hear from you! Reach out to us at <a href="mailto:info@thesocialfuse.co.uk" style={{ color: '#fff' }}>info@thesocialfuse.co.uk</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Routes>
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </>
  );
}
