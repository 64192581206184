import { Row, Col, Container } from "react-bootstrap";
import { FormStageProps } from "../../models/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface Props extends FormStageProps {
}

export default function AllDoneStage(props: Props) {

  document.title = 'The Social Fuse: Registered!';

  return (
    <Container fluid className="sf-form-body">
      <Row>
        <Col style={{ textAlign: 'center', paddingTop: 40}}>
          <div className="sf-registration-stage-title">
            <h1>Thanks {props.u.proposedProfile?.firstName}!</h1>
            <p className="lead">We'll take it from here - you'll get an email as soon as we've verified your information.</p>
            <p style={{paddingTop: 40}}><Link className="btn btn-lg btn-secondary" to="/"><FontAwesomeIcon icon={faHome} /> Home</Link></p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
