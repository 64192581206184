import { Row, Form, Col, Container, ToggleButton } from "react-bootstrap";
import { AppUser, CompensationType } from "../../models/appUser";
import { Controller, useForm } from "react-hook-form";
import FormStageNav from "./FormStageNav";
import { useState } from "react";
import Select from "react-select";
import { LookupItem } from "../../models/lookupItem";
import { FormStageProps } from "../../models/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift, faPiggyBank } from "@fortawesome/free-solid-svg-icons";

interface Props extends FormStageProps {
  contentAreas: Array<LookupItem>
}

export default function CreatorInterestsStage(props: Props) {
  const { control, handleSubmit, getValues, setValue } = useForm<AppUser>({ defaultValues: props.u });
  const [compTypes, setCompTypes] = useState(props.u.profile?.compensationTypes || [] as Array<CompensationType>);
  const [validated, setValidated] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState(0); // 0 = not set, 1 = set, -1 = invalid
  const [selectedCompType, setSelectedCompType] = useState(0) // ^

  document.title = 'The Social Fuse: Register - Your Interests';

  const onSubmit = (u: AppUser) => {
    let compTypes = getValues(`profile.compensationTypes`);
    if (!compTypes || compTypes.length === 0) {
      setSelectedCompType(-1);
    } else {
      setSelectedCompType(1);
      props.nextCallback(u);
    }
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  const toggleInArray = (compType: CompensationType) => {
    let types = [...compTypes]
    let i = types?.findIndex((f: CompensationType) => f === compType);
    if (i === -1) {
      types.push(compType);
    } else {
      types.splice(i, 1);
    }
    setValue(`profile.compensationTypes`, types);
    setCompTypes(types);
    setSelectedCompType(types && types.length > 0 ? 1 : -1);
  }

  const isChecked = (compType: CompensationType) => {
    if (!compTypes) return false;
    return compTypes.findIndex((f: CompensationType) => f === compType) > -1;
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid className="sf-form-body">
          <Row style={{marginBottom: 40}}>
            <Col>
              <div className="sf-registration-stage-title">
                <h2>Your Interests</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={{offset:3, span:6}}>
              <Form.Group className="mb-3">
                <Form.Label>Content Areas:</Form.Label>
                <Controller
                  name="profile.contentAreas"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) {
                        setSelectedAreas(-1);
                        return false;
                      } else {
                        setSelectedAreas(1);
                        return true;
                      }
                    },
                  }}
                  render={({ field }) =>
                    <Select {...field} options={props.contentAreas} isMulti />
                  } />
                  {selectedAreas === -1 && <div className="invalid-feedback" style={{display: 'inline-block'}}>Please select at least one content area</div>}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={{offset:3, span:6}}>
              <Form.Label>I'm interested in...</Form.Label><br/>
              <div className="invalid-feedback" style={{display: 'inline-block', fontStyle: 'italic'}}>Please note that you will only see briefs for the type you select, we therefore recommend keeping both selected</div>
              
              <ToggleButton
                id="compensationTypeGifting"
                variant="outline-success"
                value={CompensationType.Gifting}
                type="checkbox"
                checked={isChecked(CompensationType.Gifting)}
                onChange={() => toggleInArray(CompensationType.Gifting)}
                style={{ width: '40%', height: '120px', display: 'inline-block', margin: '10px 10px 0 0', borderRadius: '3px' }}
              >
                <FontAwesomeIcon icon={faGift} size='4x'/><br/>
                Gifting
              </ToggleButton>

              <ToggleButton
                id="compensationTypePaid"
                variant="outline-secondary"
                value={CompensationType.Paid}
                type="checkbox"
                checked={isChecked(CompensationType.Paid)}
                onChange={() => toggleInArray(CompensationType.Paid)}
                style={{ width: '40%', height: '120px', display: 'inline-block', margin: '10px 10px 0 0', borderRadius: '3px' }}
              >
                <FontAwesomeIcon icon={faPiggyBank} size='4x'/><br/>
                Paid
              </ToggleButton>
              {selectedCompType === -1 && <div className="invalid-feedback" style={{display: 'inline-block'}}>Please select at least one compensation type</div>}
            </Col>
          </Row>
          
        </Container>
        <FormStageNav
          onNext={handleSubmit(onSubmit, onError)}
          onPrev={() => props.prevCallback(getValues())}
          localStages={props.localStages}
          currentStageName={props.currentStageName} />
      </Form>
    </>
  );
}
