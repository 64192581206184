import PublicTopNav from './PublicTopNav';
import { Routes, Route, useLocation } from "react-router-dom";
import HomePageContent from "./HomePageContent";
import Pricing from "./Pricing";
import AboutUs from './AboutUs';
import FAQ from './FAQ';
import { useEffect } from 'react';

export default function PublicSiteWrapper() {
  let home = 'The Social Fuse: Connecting Brands and Social Media Creators'

  let curLoc = useLocation();
  useEffect(() => {
    const titleMap = [
      {path: '/', title: home},
      {path: '/pricing', title:'The Social Fuse: Pricing'},
      {path: '/about-us', title:'The Social Fuse: About Us'},
      {path: '/faq', title:'The Social Fuse: Frequently Asked Questions'},
      {path: '/privacy', title: 'The Social Fuse: Privacy Policy'},
      {path: '/terms-of-use', title: 'The Social Fuse: Terms of Use'},
    ]
    const curTitle = titleMap.find(item => item.path === curLoc.pathname)
    if(curTitle && curTitle.title){
      document.title = curTitle.title
    }
  }, [curLoc, home])

  return (
    <>
      {/* <Container fluid className='sf-top-banner'>
        A new way for brands and creators to connect
      </Container> */}
      <PublicTopNav />

      <Routes>
        <Route path="*" element={
          <HomePageContent />
        } />
        <Route path="pricing" element={
          <Pricing />
        } />
        <Route path="about-us" element={
          <AboutUs />
        } />
        <Route path="faq" element={
          <FAQ />
        } />
      </Routes>
    </>
  );
}
