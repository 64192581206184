import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormStages } from "../../models/forms";

interface Props {
  localStages: Array<FormStages>
  currentStageName: number
}

export default function FormStageBreadcrumb(props: Props) {
  let currentStageIndex = props.localStages.findIndex((s: FormStages) => (s === props.currentStageName));

  return (
    <div className="form-stage-breadcrumb">     
      {
        props.localStages.map((f: FormStages, i: number) => {
          if(f === FormStages.WhoAreYou || f === FormStages.AllDone) return <></>
          if(i === props.localStages.length -1) return <div key={f} className="form-stage-breadcrumb-empty"><FontAwesomeIcon style={{position:'relative',top:'-7px', left:1, width:10}} icon={faCheck} className="" color="#C499EA"/></div>
          if(i < currentStageIndex) return <div key={f} className="form-stage-breadcrumb-full"></div>
          if(i === currentStageIndex) return <div key={f} className="form-stage-breadcrumb-current"></div>
          if(i > currentStageIndex) return <div key={f} className="form-stage-breadcrumb-empty"></div>
          return <></>
        })
      }
    </div>
  );
}
