import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import { useState } from "react";
import { AppUser } from "./models/appUser";
import { Auth0Provider } from "@auth0/auth0-react";
import { appConfig } from "./appConfig";
import { UserContext } from "./contexts/userContext";
import { ProtectedApp } from "./ProtectedApp";
import PublicSiteWrapper from "./components/PublicSiteWrapper";
import ScrollTopWrapper from "./components/ScrollTopWrapper";

export default function App() {
  const [appUser, setAppUser] = useState({} as AppUser);

  return (
    <>
      <Auth0Provider
        domain={appConfig().auth0Domain}
        clientId={appConfig().auth0ClientId}
        redirectUri={`${window.location.origin}/app`}
        audience={appConfig().apiAudience}
        scope={appConfig().apiScope}
      >
        <UserContext.Provider value={{ appUser: appUser, setAppUser: (u: AppUser) => { setAppUser(u) } }}>
          <BrowserRouter>
            <ScrollTopWrapper>
              <>
                <Routes>
                  <Route path="*" element={
                    <PublicSiteWrapper />
                  } />
                  <Route path="app/*" element={

                    (window as any).navigator.userAgent.indexOf("ReactSnap") === -1 ?
                      <ProtectedApp /> :
                      <></>

                  } />
                </Routes>
                <Footer />
              </>
            </ScrollTopWrapper>
          </BrowserRouter>
        </UserContext.Provider>
      </Auth0Provider>
    </>
  );
}
