import { Button, Col, Container, Row } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { AccountStatus, AccountType } from "../../models/appUser";
import { FormStageProps } from "../../models/forms";
import { useState } from "react";

interface Props extends FormStageProps {
}

export default function WhoAreYouStage(props: Props) {
  const [override, setOverride] = useState(false);
  const { user } = useAuth0();

  document.title = 'The Social Fuse: Register - Who are you?';

  const chooseAccountType = (type: AccountType) => {
    let u = props.u;
    u.accountType = type;
    props.nextCallback(u);
  }

  return (
    user?.email_verified === false ?
      <div style={{padding:20, textAlign: 'center', marginTop: 60}}>
        <h1 style={{textAlign: 'center'}}>Awaiting email verification...</h1>
        <p className="lead">We've sent you an email to confirm your address. Please click the link in the email and we'll get you registered.</p>
      </div>
      :
      <>
        {
          props.u.proposedProfile && props.u.accountStatus === AccountStatus.New && !override ?
            <Container style={{marginTop:'20px'}}>
              <Row>
                <Col style={{ textAlign: 'center' }}>
                  <h1>Hi {props.u.proposedProfile?.firstName}!</h1>
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: 'center' }}>
                  <p className="lead">You've already registered with us, and we're looking at it now.<br />If you want to make changes in the meantime, go for it :)</p><br />
                  <Button variant="secondary" onClick={() => setOverride(true)}>Make Changes</Button>
                </Col>
              </Row>
            </Container>
            :
            <Container fluid>
              <Row>
                <Col className="sf-registration-title">
                  <h1 className="display-1">Let's get you registered.</h1>
                </Col>
              </Row>
              <Row>
                <Col md={12} style={{ textAlign: 'center', marginBottom: 40, marginTop: 60 }}>
                  <h3>Who Are You?</h3>
                </Col>
                <Col lg={{ offset: 3, span: 2 }} md={{ offset: 1, span: 3 }} sm={{ offset: 0, span: 4 }}>
                  <Button variant="outline-primary" style={{ width: '100%', marginBottom: '10px' }} size={'lg'} onClick={() => chooseAccountType(AccountType.Creator)}>Creator</Button>
                </Col>
                <Col lg={{ offset: 0, span: 2 }} md={{ offset: 0, span: 4 }} sm={{ offset: 0, span: 4 }}>
                  <Button variant="outline-secondary" style={{ width: '100%', marginBottom: '10px' }} size={'lg'} onClick={() => chooseAccountType(AccountType.Brand)}>Brand</Button>
                </Col>
                <Col lg={{ offset: 0, span: 2 }} md={{ offset: 0, span: 3 }} sm={{ offset: 0, span: 4 }}>
                  <Button variant="outline-success" style={{ width: '100%', marginBottom: '10px' }} size={'lg'} onClick={() => chooseAccountType(AccountType.Agency)}>Agency</Button>
                </Col>
              </Row>
            </Container>
        }
      </>
  );
}

