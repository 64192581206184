import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../config.json";
import { useAuth0 } from "@auth0/auth0-react";

import windowPic from "../styles/img/photography/circle-window-blue-lines.webp";
import smile from "../styles/img/photography/smile-purple-circle.webp";
import phoneArrow from "../styles/img/photography/phone-arrow.webp";
import manHearts from "../styles/img/photography/man-hearts.webp";
import greenArrow from "../styles/img/doodles/green/CURLY ARROW.png";

import jess from "../styles/img/photography/jess-b.png";

export default function AboutUs() {
  const { loginWithRedirect } = useAuth0();

  const register = async () => {
    console.log("registering...", config.auth0ClientId, config.auth0Domain);
    loginWithRedirect({ screen_hint: 'signup', redirect_uri: `${window.location.origin}/app/register`, client_id: config.auth0ClientId, domain: config.auth0Domain });
  }

  return (
    <>
      <Container fluid>
        <Container style={{ marginTop: 60 }}>
          <Row>
            <Col xs={12} md={6} sm={6}>
              <h1 style={{display:'none'}}>About The Social Fuse</h1>
              <h3 style={{ fontSize: '2.6rem' }}>
                The Social Fuse is a platform that streamlines connections between brands and influencers efficiently and <span className="sf-underline sf-green-underline" style={{ marginRight: 5 }}>authentically. </span>
              </h3>
              <p className="lead" style={{ display: 'block', paddingLeft: 80, paddingTop: 20 }}>
                We bridge the gap between brands and influencers, fostering meaningful collaborations. Our platform emphasizes quality and authenticity, ensuring influencers have genuine audiences. We offer brands efficiency and transparency, while providing influencers autonomy and growth. Explore our journey and values further below.
              </p>
            </Col>
            <Col lg={3} md={6} sm={6}>
              <img src={windowPic} style={{ maxWidth: '100%', marginTop: 100 }} className="d-none d-sm-block" alt="A creator having an idea" />
            </Col>
            <Col lg={3}>
              <img src={smile} style={{ maxWidth: '100%' }} className="d-none d-lg-block" alt="A smiling creator generating content" />
            </Col>
          </Row>
          <Row style={{ marginTop: 40, overflow: 'visible' }} className="sf-banner" >
            <Col xs={12} md={5} sm={5}>
              <img src={jess} style={{ maxWidth: '90%', marginBottom: 0, position: 'relative', top: -40, left: 10 }} className="d-sm-none" alt="The Social Fuse Founder Jessica Brennan" />
              <img src={jess} style={{ maxWidth: '90%', position: 'relative', left: -100, top: 60 }} className="d-none d-sm-block" alt="The Social Fuse Founder Jessica Brennan" />
            </Col>
            <Col sm={7} md={7} style={{paddingRight: 50}}>
              <h3>The Story Behind <br />The Social Fuse</h3>
              <p style={{ paddingLeft: 40 }}>by founder Jessica Brennan</p>
              <p style={{ paddingTop: 20 }}>
                The Social Fuse was inspired by my own challenges in influencer marketing. Working at a media agency, I often found myself spending unnecessary hours trying to source the right influencers for campaigns. What should have been a straightforward process often took much longer than anticipated. To add to the challenge, many of the influencers I contacted were either unavailable, uninterested, or wouldn't respond at all. Accessing a platform to assist with the process was often out of reach due to high costs and lengthy subscription terms.
              </p>
              <p>
                Yet, on the flip side, there were many passionate and proactive creators eager to collaborate and share their authentic voice. They reached out, expressing a desire for more control over their collaborations and a platform that valued genuine engagement over superficial metrics.
              </p>
              <p>
                It was during a particularly long and fruitless night of searching for the right influencers that the idea hit me. What the industry truly needed was a marketplace – a place where brands could simply and efficiently post their requirements and where creators, who genuinely resonated with a brand's ethos, could actively search and apply.
              </p>
              <p>
                And so, The Social Fuse was born, rooted in the core principles of efficiency, authenticity, and mutual respect.
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: 80 }}>
            <Col xs={12} md={6}>
              <h3 style={{ fontWeight: 'bold' }}>Balancing Quality with Accessibility</h3>
              <div style={{ paddingTop: 40, paddingLeft: 30 }}>
                <p>
                  At The Social Fuse, our primary goal is to maintain a marketplace that champions both quality and accessibility.
                </p>
                <p>
                  <b>Quality Assurance</b>: We set a high bar for quality. Influencers are carefully vetted to ensure they maintain a genuine follower base and produce original content. Brands, too, undergo a review process to ensure legitimacy. Any party, be it a brand or creator, that doesn't align with our standards will be removed.
                </p>
                <p>
                  <b>Accessible Opportunities</b>: The beauty of The Social Fuse is its openness. Brands and agencies, regardless of their size or budget, can post campaign briefs without being tied down by lengthy contracts. Our competitive rates make influencer marketing attainable for a diverse range of businesses, from start-ups to established names.
                </p>
              </div>
            </Col>
            <Col md={3}>
              <img src={phoneArrow} style={{ maxWidth: '100%' }} alt="A creator taking a pic" />
            </Col>
            <Col md={3}>
              <img src={manHearts} style={{ maxWidth: '100%', marginTop: 120 }} className="d-none d-md-block" alt="A man with hearts bubbling behing him" />
            </Col>
          </Row>
        </Container>
        <Container fluid style={{ marginTop: 40 }} className="sf-brand">
          <Container>
            <Row>
              <Col xs={12} md={4}>
                <h2 className="sf-simple">
                  Our Mission is <span className="sf-underline sf-blue-underline">Simple</span>...
                </h2>
              </Col>
              <Col xs={12} md={8}>
                <p style={{ fontSize: '0.9rem', fontWeight: 'normal', padding: 10 }}>
                  To foster an environment where authenticity thrives. We aim to cultivate successful partnerships that resonate with brands and creators alike, regardless of their size. By reshaping the traditional selection process, we provide creators with the autonomy to choose collaborations that truly align with their passion and ethos. In turn, this ensures that brands are paired with enthusiasts who are genuinely excited about their products, leading to more meaningful campaigns.
                </p>
              </Col>
              <Col >
                <h5 style={{ fontSize: '1.1rem', fontWeight: 'normal', marginTop: 30, padding: 10 }}>
                  The Social Fuse is not just a platform; it's a movement towards a more transparent and honest influencer industry. Join the community and embrace authentic collaborations today!
                </h5>
                <div style={{ textAlign: 'center', padding: 20 }} >
                  <Button variant="success" onClick={() => register()}>Register For Free Now</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container style={{ marginTop: 40 }}>
          <Row>
            <Col sm={4} className="sf-want-to-hear">
              <h2>We want to hear <br />from you!</h2>
              <img src={greenArrow} alt="Green curly arrow" className="d-none d-lg-block" style={{ position: 'relative', left: 180, top: 5, width: 80 }} />
            </Col>
            <Col sm={8} lg={6}>
              <div className="sf-purple-frame">

                <h5 style={{ paddingTop: 20 }}>Have an account or product question?</h5>
                <p>
                  Check out our <Link to="/faq">FAQ page</Link>
                </p>

                <h5 style={{ paddingTop: 20 }}>Do you have a press inquiry?</h5>
                <p>
                  Email us at <a href="mailto:info@thesocialfuse.co.uk">info@thesocialfuse.co.uk</a> with PRESS in the subject line
                </p>

                <h5 style={{ paddingTop: 20 }}>Something else? Get in touch</h5>
                <p>
                  Email us at <a href="info@thesocialfuse.co.uk">info@thesocialfuse.co.uk</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
