import { BriefApplicationStatus, BriefStatus } from "../../models/brief";

interface StatusProps {
  status: string;
}

export default function Status(props: StatusProps) {
  return (
    <div style={{textAlign: 'center', display:'inline-block'}}>
      {
        props.status === BriefStatus.Draft &&
        <div className="sf-grey-outline">Draft</div>
      }
      {
        props.status === BriefStatus.Live &&
        <div className="sf-green-outline">Live</div>
      }
      {
        (props.status === BriefStatus.InReview || props.status === BriefApplicationStatus.InReview) &&
        <div className="sf-purple-outline">In Review</div>
      }
      {
        props.status === BriefStatus.Closed &&
        <div className="sf-blue-outline">Closed</div>
      }
      {
        props.status === BriefStatus.ChangesRequired &&
        <div className="sf-red-outline">Changes Required</div>
      }
      {
        props.status === BriefApplicationStatus.Approved &&
        <div className="sf-green-outline">Approved</div>
      }
      {
        props.status === BriefApplicationStatus.Rejected &&
        <div className="sf-red-outline">Rejected</div>
      }
    </div>

  );

}
