import { useState } from "react";
import { ResultType, useAuthApiCall } from "../../utils/useAuthApiCall";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faFilePdf, faFileWord, faTrash } from "@fortawesome/free-solid-svg-icons";
import { UserFile } from "../../models/utils";
import { appConfig } from "../../appConfig";

interface AttachmentItemProps {
  uploadedFileCallback?: (file: UserFile, selected: File) => void;
  removeFileCallback?: (file: UserFile) => void;
  file: UserFile;
  showRemove?: boolean;
  userId: string;
}

export default function AttachmentItem(props: AttachmentItemProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const apiCall = useAuthApiCall();

  const removeFile = async (file: UserFile) => {
    // delete the file via the api
    setIsDeleting(true);
    apiCall(`files/${file.id}`, 'DELETE', null, ResultType.None)
      .finally(() => {
        setIsDeleting(false);
        if (props.removeFileCallback) props.removeFileCallback(file);
      });
  }

  return (
    <>
      <li key={props.file.fileName} style={{ backgroundColor: '#e8dbf3', borderRadius: 4, margin: '10px', display: 'inline-block', padding: 10 }}>
          <>
            {props.showRemove &&
              <Button disabled={isDeleting} variant="danger" size="sm" style={{ marginRight: 10 }} onClick={() => { removeFile(props.file) }}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            }

            <a id={props.file.id} target="_blank" href='/' rel="noreferrer" style={{ display: 'none' }}>Download File</a>
            <a href={`${appConfig().fileBase}/${props.userId}/${props.file.id}`} target="_blank" rel="noreferrer" id={props.file.id} style={{ textDecoration: 'none' }} title="Download file">
              {props.file.extension.toLowerCase() === 'docx' && <FontAwesomeIcon icon={faFileWord} />}
              {props.file.extension.toLowerCase() === 'doc' && <FontAwesomeIcon icon={faFileWord} />}
              {props.file.extension.toLowerCase() === 'pdf' && <FontAwesomeIcon icon={faFilePdf} />}
              {(props.file.extension.toLowerCase() === 'png' || props.file.extension.toLowerCase() === 'jpg') && <FontAwesomeIcon icon={faFileImage} />}
              &nbsp;&nbsp;{decodeURIComponent(props.file.fileName)}
            </a>
          </>
        

      </li>
    </>
  )
}