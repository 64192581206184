import { Row, Form, Col, Container, Spinner } from "react-bootstrap";
import { AccountType, AppUser } from "../../models/appUser";
import { useForm } from "react-hook-form";
import FormStageNav from "./FormStageNav";
import { useEffect, useState } from "react";
import { FormStageProps } from "../../models/forms";
import ImageUploader from "../utils/ImageUploader";
import { BodyType, ResultType, useAuthApiCall } from "../../utils/useAuthApiCall";

interface Props extends FormStageProps {
}

export default function PictureBioStage(props: Props) {
  let u = props.u;

  const { handleSubmit, getValues, setValue, register } = useForm<AppUser>({ defaultValues: u });
  const [validated, setValidated] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState({} as File);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiCall = useAuthApiCall();

  document.title = 'The Social Fuse: Register - Picture & Bio';

  useEffect(() => {
    const postPicture = async () => {
      try {
        if (selectedPicture && selectedPicture.name) {
          setIsSubmitting(true);
          let d = new FormData();
          d.append(selectedPicture.name, selectedPicture);
          const response = await apiCall(
            'files',
            'POST',
            d,
            ResultType.JSON,
            BodyType.Form);

          if (u.profile) {
            setValue("profile.picture", response);
          }
          setIsSubmitting(false);
        }
      } catch (e) {
        alert("Error uploading picture, please try again.");
        console.error(e);
        setIsSubmitting(false);
      }
    }
    postPicture();

  }, [selectedPicture, apiCall, u.profile, setValue]);

  const onSubmit = async (u: AppUser) => {
    props.nextCallback(u);
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid className="sf-form-body">
          <Row style={{marginBottom: 40}}>
            <Col>
              <div className="sf-registration-stage-title">
                {props.u.accountType === AccountType.Creator && <h2>Picture & Bio</h2>}
                {props.u.accountType === AccountType.Agency && <h2>Logo & Agency Bio</h2>}
                {props.u.accountType === AccountType.Brand && <h2>Logo & Brand Bio</h2>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={{ offset: 3, span: 6 }} style={{ marginBottom: 20 }}>
              <Form.Group className="mb-3">
                <Form.Label>Picture: <i style={{ textTransform: 'none' }}>(Optional)</i></Form.Label>

                <div style={{ textAlign: 'center' }}>
                  <ImageUploader
                    fileId={u.profile && u.profile.picture && `${u.id}/${u.profile.picture.id}`}
                    showEdit={true}
                    customKey={'bio-stage'}
                    selectedFileCallback={(file) => setSelectedPicture(file)}
                    additionalStyles={{ display: 'inline-block', width: 200, height: 200, borderRadius: '50%', margin: '10px 0 10px 0' }}
                    fallbackImageUrl="/green-default-sq.png" />
                    {isSubmitting && <i style={{display:'block'}}>Uploading Picture...<Spinner size="sm" /></i>}
                </div>

              </Form.Group>
            </Col>
            <Col sm={12} md={{ offset: 3, span: 6 }}>
              <Form.Group className="mb-3">
                <Form.Label>Bio: <i style={{ textTransform: 'none' }}>(Optional)</i></Form.Label>
                <textarea style={{ color: '#333', width: '100%', background: 'none', border: '1px #c499ea96 solid', borderRadius: 4, marginBottom: 20, minHeight: '150px', padding: 5 }}
                  placeholder="Tell us a bit about yourself..." {...register("profile.bio")} required />

              </Form.Group>
            </Col>
          </Row>
        </Container>
        <FormStageNav
          onNext={handleSubmit(onSubmit, onError)}
          onPrev={() => props.prevCallback(getValues())}
          disableSubmit={isSubmitting}
          localStages={props.localStages}
          currentStageName={props.currentStageName}
          isSubmitting={isSubmitting} />
      </Form>
    </>
  );
}
