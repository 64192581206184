import { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useAuthApiCall } from "../../utils/useAuthApiCall";
import { BriefApplication } from "../../models/brief";
import { Link } from "react-router-dom";
import Status from "./Status";
import purpleHash from "../../styles/img/doodles/purple/hashtag.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

export default function MyApplications() {

  const [applications, updateApplications] = useState([] as Array<BriefApplication>);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const apiCall = useAuthApiCall();

  useEffect(() => {
    // load briefs
    const getBriefs = async () => {
      const briefApplications = await apiCall('creators/applications', 'get');
      if (briefApplications && briefApplications.length > 0) {
        for (let i = 0; i < briefApplications.length; i++) {
          console.log("getting brief");
          let brief = await apiCall(`brands/${briefApplications[i].brandId}/briefs/${briefApplications[i].briefId}`, 'get');
          briefApplications[i].attachedBrief = brief;
        }

        updateApplications(briefApplications || []);
      }
      setIsLoading(false);
    }

    getBriefs();
  }, [apiCall]);

  return (
    <>
      <Container style={{ margin: '40px auto' }}>
        <Row style={{ marginBottom: 30 }}>
          <Col md={{ offset: 2, span: 8 }} xs={12}>
            <h2 style={{ textAlign: 'center' }}><span className="sf-underline sf-blue-underline">My Applications</span></h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              isLoading && <div style={{ textAlign: 'center', margin: 20 }}>
                <Spinner style={{}} animation="border" placeholder="Loading Applications..." />
              </div>
            }
            {
              !isLoading && applications.length === 0 &&
              <div style={{ textAlign: 'center', margin: 20 }}>
                <p>You haven't applied for any briefs yet. Check out the Brief Board to see if there are any listings you can apply for.</p>
              </div>
            }
            {
              applications.length > 0 &&
              <Table borderless>
                <thead style={{ backgroundColor: '#333', color: '#fff', border: '2px #333 solid' }}>
                  <tr>
                    <th>
                      Brand Name
                    </th>
                    <th>
                      Campaign Name
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      Applications Period
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      Date Applied
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      Application Message
                    </th>
                    <td style={{ textAlign: 'center' }}>
                      View Brief
                    </td>
                    <th style={{ textAlign: 'center' }}>
                      Status
                    </th>
                    <th style={{ position: 'relative' }}>
                      Brand Feedback
                      
                      <img src={purpleHash} style={{position: 'absolute', width: 50, top: -30, right: -20}} alt="Bio" />
                    </th>
                  </tr>
                </thead>
                <tbody style={{ border: '2px #333 solid', borderRadius: 5, backgroundColor: '#fff' }}>
                  {
                    applications.map((b: BriefApplication) => (
                      <tr key={b.id}>
                        <td style={{ padding: '15px' }}>
                          <a href={b.attachedBrief?.brandWebsite} target="_blank" rel="noreferrer">{b.attachedBrief?.brandName}</a> {b.attachedBrief?.brandWebsite && b.attachedBrief.brandWebsite.length > 0 && <>&nbsp;<a href={b.attachedBrief?.brandWebsite} target="_blank" rel="noreferrer"><FontAwesomeIcon style={{fontSize: 12}} icon={faArrowUpRightFromSquare} /></a></>}
                        </td>
                        <td style={{ padding: '15px' }}>
                          {b.attachedBrief?.title}
                        </td>
                        <td style={{ padding: '15px', textAlign: 'center' }}>
                          {b.attachedBrief?.campaignFrom && (new Date(b.attachedBrief?.campaignFrom)).toLocaleDateString()} - {b.attachedBrief?.campaignTo && (new Date(b.attachedBrief?.campaignTo)).toLocaleDateString()}
                        </td>
                        <td style={{ padding: '15px', textAlign: 'center' }}>
                          {b.dateCreated && (new Date(b.dateCreated)).toLocaleDateString()}
                        </td>
                        <td style={{ padding: '15px', textAlign: 'left', maxWidth: 300 }}>
                          {
                            b.message.length > 100 ?
                              <div>
                                {b.message.substring(0, 100)}...
                                <div style={{ textAlign: 'right' }}>
                                  <Link to="" style={{ fontSize: 12 }} onClick={() => { setSelectedMessage(b.message); setShowMessageModal(true) }}>[Show message]</Link>
                                </div>
                              </div>
                              :
                              b.message
                          }
                        </td>
                        <td style={{ padding: '15px', textAlign: 'center' }}>
                          <Link to={`/app/brands/${b.attachedBrief?.brandId}/briefs/${b.attachedBrief?.id}`}>View</Link>
                        </td>
                        <td style={{ padding: '15px', textAlign: 'center' }}>
                          <Status status={b.status} />                          
                        </td>
                        <td>
                        {
                            b.reviewMessage.length > 100 ?
                              <div>
                                {b.reviewMessage.substring(0, 100)}...
                                <div style={{ textAlign: 'right' }}>
                                  <Link to="" style={{ fontSize: 12 }} onClick={() => { setSelectedMessage(b.reviewMessage); setShowMessageModal(true) }}>[Show message]</Link>
                                </div>
                              </div>
                              :
                              b.reviewMessage
                          }
                        </td>
                      </tr>))
                  }
                </tbody>
              </Table>
            }
          </Col>
        </Row>
      </Container>
      <Modal show={showMessageModal} onHide={() => setShowMessageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMessage}
        </Modal.Body>
      </Modal>
    </>
  );

}
