import { useContext, useEffect, useState } from "react";
import { Button, Container, Row, Col, Form, Spinner, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Age, CompensationType, FollowerCount, SocialMediaSite, genders } from "../../models/appUser";
import { BodyType, ResultType, useAuthApiCall } from "../../utils/useAuthApiCall";
import { Brief, BriefStatus, loadBrief, setBriefForPost } from "../../models/brief";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select, { GroupBase } from "react-select";
import UTCDatePicker from "../utils/UTCDatePicker";
import { UserContext } from "../../contexts/userContext";
import ImageUploader from "../utils/ImageUploader";
import { UserFile } from "../../models/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faLock, faPaperclip, faPencil, faQuestionCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import AttachmentItem from "../utils/AttachmentItem";
import Lifecycle from "./Lifecycle";

interface AddUpdateBriefProps {
  brandId?: string;
  briefId?: string;
  adminMode?: boolean;
  submitCallback?: (b: Brief) => void;
}

export default function AddUpdateBrief(props: AddUpdateBriefProps) {
  const { control, handleSubmit, register, reset, getValues, formState: { errors } } = useForm<Brief>({ defaultValues: {} });
  const apiCall = useAuthApiCall();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [{ ages, contentAreas, followerCounts }, setLookupData] = useState({ ages: [], contentAreas: [], followerCounts: [] });
  let { brandId, briefId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { appUser } = useContext(UserContext);
  const [isUploading, setIsUploading] = useState(false);
  const [attachments, setAttachments] = useState([] as UserFile[]);
  const [selectedBannerImage, setSelectedBannerImage] = useState({} as File);
  const [loadedBrief, setLoadedBrief] = useState({} as Brief);

  if (!brandId) brandId = props.brandId;
  if (!briefId) briefId = props.briefId;

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const opts = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];

  useEffect(() => {
    document.title = 'The Social Fuse: Add / Update Brief';
    try {
      // remove the background image
      (document.getElementsByClassName('sf-register-container')[0] as any).style.background = 'none';
    } catch (e) {
      // don't really care
    }

    const getData = async () => {
      const [ages, contentAreas, followerCounts, brief] = await Promise.all([
        apiCall('lookup/ages', 'get'),
        apiCall('lookup/contentareas', 'get'),
        apiCall('lookup/followercounts', 'get'),
        briefId ? apiCall(`brands/${brandId}/briefs/${briefId}`, 'get') : {} as Brief
      ]);

      if (briefId) {
        let b = loadBrief(brief);
        setAttachments(b.attachments || []);
        reset(b);
        setLoadedBrief(b);
      }

      setLookupData(
        {
          ages: ages.map((a: Age) => { return { label: a.label, value: a.label } }),
          contentAreas: contentAreas,
          followerCounts: followerCounts.map((f: FollowerCount) => { return { label: f.label, value: f.label } })
        });

      setIsLoading(false);
    }
    getData();

    return () => { try { (document.getElementsByClassName('sf-register-container')[0] as any).style = ''; } catch (e) { } }
  },
    [apiCall, brandId, briefId, reset]);

  const handleFileChange = async (event: any) => {
    try {
      let responses = [] as UserFile[];
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size > 5 * 1024 * 1024) {
          alert(`Oops! ${event.target.files[i].name} is to big. The maximum attachment size is 5 MB`);
        } else {
          setIsUploading(true);
          let d = new FormData();
          let f = event.target.files[i] as File
          if (f.name) {
            d.append(f.name, f);
            const response = await apiCall(
              'files',
              'POST',
              d,
              ResultType.JSON,
              BodyType.Form);
            responses.push(response)
          }
        }
      }
      setAttachments([...attachments, ...responses]);
    } catch (e) {
      console.error(e);
      setIsUploading(false);
    }

    setIsUploading(false);
  };

  const onSubmit = async (b: Brief) => {
    setIsSubmitting(true);
    b.attachments = attachments;
    b = setBriefForPost(b);
    if (selectedBannerImage && selectedBannerImage.name) {
      // delete the previous one
      if (b.bannerImage?.id) {
        await apiCall(`files/${b.bannerImage.id}`, 'delete', undefined, ResultType.None);
      }

      let d = new FormData();
      d.append(selectedBannerImage.name, selectedBannerImage);
      const response = await apiCall(
        'files',
        'POST',
        d,
        ResultType.JSON,
        BodyType.Form);
      b.bannerImage = response;
    }

    if (!props.adminMode) {
      let addedBrief = await apiCall(briefId ? `brands/${brandId}/briefs/${briefId}` : `brands/${brandId}/briefs`, briefId ? 'put' : 'post', b);
      navigate(`../brands/${brandId}/briefs/${addedBrief.id}`);
    } else {
      let addedBrief = await apiCall(`brands/${brandId}/briefs/${briefId}/admin`, 'put', b);
      props.submitCallback && props.submitCallback(addedBrief);
    }
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  return (
    <Form validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>

      {!props.adminMode &&
        <>
          <Lifecycle status={loadedBrief && loadedBrief.id ? loadedBrief.briefStatus : BriefStatus.Draft} />
          <hr />
        </>
      }

      <Container style={{ padding: '10px 30px 30px 30px' }}>
        <Row style={{ marginBottom: -20, paddingBottom: 0, marginTop: -15 }}>
          <Col xs={12} sm={6} md={3}>
            {!props.adminMode && <Button size="sm" variant="" style={{ marginTop: 20 }} onClick={() => navigate(`../brands/${brandId}/briefs`)}><FontAwesomeIcon icon={faChevronLeft} /> Back to Dashboard</Button>}
          </Col>
          <Col>
            <div style={{ textAlign: 'right', margin: '20px 0' }}>
              {!props.adminMode ? <Button size="sm" type="submit" variant="primary" disabled={isSubmitting || isUploading}>
                {isSubmitting && <Spinner size="sm" style={{ marginRight: 10 }} animation="border" placeholder="Submitting..." />}Review <FontAwesomeIcon icon={faChevronRight} /></Button> :
                <Button size="sm" type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner size="sm" style={{ marginRight: 10 }} animation="border" placeholder="Submitting..." /> : <FontAwesomeIcon icon={faSave} />} Save</Button>
              }
            </div>
          </Col>
        </Row>
        <hr />
        {
          isLoading &&
          <div style={{ textAlign: 'center', margin: 20 }}>
            <Spinner style={{}} animation="border" placeholder="Loading brief data..." />
          </div>
        }
        {
          !isLoading &&
          <>
            <ImageUploader
              fileId={loadedBrief && loadedBrief.id && `${loadedBrief.userId}/${loadedBrief.bannerImage?.id}`}
              selectedFileCallback={(file: File) => setSelectedBannerImage(file)}
              showEdit={true}
              additionalStyles={{ width: '100%', height: 200, margin: '10px 0 10px 0' }}
              fallbackImageUrl="/brief-banner.webp" />

            <Container style={{ padding: 0 }}>
              <Row style={{ marginBottom: 20 }}>
                <Col>
                  <Form.Control type="text" placeholder="* Enter campaign name here... *" {...register("title")} required maxLength={60}
                    style={{ fontSize: '2rem', background: 'none', textAlign: 'center', border: 'none', borderBottom: '1px #333 solid', padding: '10px' }} />
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Container style={{ padding: 0 }}>
                    <Row>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Brand website:</Form.Label>
                      </Col>
                      <Col style={{ paddingTop: 5 }}>
                        {appUser.profile && appUser.profile.brands && appUser.profile.brands.length > 0 &&
                          <a href={appUser.profile?.brands.find((b) => b.brandGuid === brandId)?.website} target="_blank" rel="noreferrer">{appUser.profile?.brands.find((b) => b.brandGuid === brandId)?.website}</a>}
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Applications open: *</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          control={control}
                          name='campaignFrom'
                          rules={{
                            validate: (value) => {
                              if (new Date(value) < new Date()) return "Aplication open date cannot be in the past";
                            }
                          }}
                          render={({ field }) => (
                            <UTCDatePicker
                              placeholderText='Select date'
                              onChange={(date: any) => field.onChange(date)}
                              selected={field.value}
                              dateFormat="dd MMM yyyy"
                              timeFormat="HH:mm"
                              required
                            />
                          )}
                        />
                        {errors.campaignFrom && <div className="invalid-feedback" style={{ display: 'inline-block' }}> {errors.campaignFrom.message} </div>}
                      </Col>
                    </Row> */}
                    <Row>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Applications close: *</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          control={control}
                          name='campaignTo'
                          render={({ field }) => (
                            <UTCDatePicker
                              placeholderText='Select date'
                              onChange={(date: any) => field.onChange(date)}
                              selected={field.value}
                              dateFormat="dd MMM yyyy"
                              required
                            />
                          )}
                        />
                        {errors.campaignTo && <div className="invalid-feedback" style={{ display: 'inline-block' }}> {errors.campaignTo.message} </div>}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Campaign start month:</Form.Label>
                      </Col>
                      <Col>
                        <select className="form-select" {...register("campaignStartMonth")}>
                          <option value="">...</option>
                          {
                            months.map((c: string, i: number) => (
                              <option value={c} key={i}>{c}</option>
                            ))
                          }
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Activity type: *</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          key={`activity-type`}
                          name={`activityType` as const}
                          control={control}
                          rules={{
                            validate: (value) => {
                              if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) return "Select an activity type";
                            }
                          }}
                          render={({ field }) =>
                            <Select placeholder="..." {...field} options={Object.keys(CompensationType).map((s) => { return { label: s, value: s } })} isMulti />
                          } />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 3 }}>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Social platforms: *</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          key={`social-platforms`}
                          name={`platforms` as const}
                          control={control}
                          rules={{
                            validate: (value) => {
                              if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) return "Select a social platform";
                            }
                          }}
                          render={({ field }) =>
                            <Select placeholder="..." {...field} options={Object.keys(SocialMediaSite).map((s) => { return { label: s, value: s } })} isMulti />
                          } />
                      </Col>
                    </Row>

                    <Row style={{ backgroundColor: '#fefefe', padding: 10, border: '1px #990000 solid', borderRadius: 4, margin: '20px 5px 0 5px' }}>
                      <Col>
                        <h3 style={{ fontSize: 18, color: '#990000' }}><FontAwesomeIcon icon={faLock} style={{ fontSize: 16, marginRight: 10 }} />Private Fields</h3>
                        <p style={{ fontSize: 13 }}>The following fields are not shared with Creators.</p>
                        <Row style={{ marginTop: 3 }}>
                          <Col sm={4}>
                            <Form.Label className="sf-label-regular">Total Campaign Budget: *
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 800 }}
                                overlay={
                                  <Tooltip id="button-tooltip-budget" style={{ width: 400 }}>
                                    <div style={{ padding: 10 }}>
                                      <h3>Why We Ask for Your Budget</h3>
                                      <p> Providing your campaign budget helps us ensure that the influencers who see
                                        your brief align with your financial expectations. Your budget details remain confidential
                                        and are only used for internal review. If you're unsure what budget you need or don't have
                                        this information yet, please provide an estimate. We will advise you before the brief goes
                                        live if we feel adjustments to the number or size of influencer audience are needed.</p>
                                    </div>
                                  </Tooltip>}
                              >
                                <div style={{ display: 'inline-block', marginLeft: 10 }}>
                                  <FontAwesomeIcon icon={faQuestionCircle} />
                                </div>
                              </OverlayTrigger>
                            </Form.Label>
                          </Col>
                          <Col>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
                              <Form.Control type="number" placeholder="Enter budget (or 0 for gifting campaigns)" {...register("totalCampaignBudget", { valueAsNumber: true })} required />
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 3 }}>
                          <Col sm={4}>
                            <Form.Label className="sf-label-regular">No. of Influencers Required:
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 800 }}
                                overlay={
                                  <Tooltip id="button-tooltip-budget" style={{ width: 400 }}>
                                    <div style={{ padding: 10 }}>
                                      <h3>Why We Ask for the Number of Influencers</h3>
                                      <p> Knowing how many influencers you need helps us ensure your budget aligns with your campaign goals.
                                        Feel free to add a range if you're uncertain (e.g., 5-10). Your details remain confidential and are used only for internal review.
                                        Provide your best estimate, and we will advise you before the brief goes live if adjustments are needed.</p>
                                    </div>
                                  </Tooltip>}
                              >
                                <div style={{ display: 'inline-block', marginLeft: 10 }}>
                                  <FontAwesomeIcon icon={faQuestionCircle} />
                                </div>
                              </OverlayTrigger>

                            </Form.Label>
                          </Col>
                          <Col>
                            <Form.Control type="number" placeholder="" {...register("numberInfluencersRequired", { valueAsNumber: true })} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </Container>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="sf-label-regular">Required content:</Form.Label>
                    <textarea style={{ borderRadius: 3, width: '100%', background: 'none', border: '1px #333 solid', minHeight: '150px', padding: 5 }} placeholder="Optional. Please enter the content each creator will be required to create" {...register("requiredContent")} />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col style={{ padding: 40, margin: 10, borderRadius: 3 }} className="sf-purple-fill">
                  <h3>Brief Description *</h3>
                  <textarea style={{ color: '#fff', width: '100%', background: 'none', border: '1px #fff solid', borderRadius: 4, marginBottom: 20, minHeight: '150px', padding: 5 }}
                    placeholder="Please explain a bit about the campaign and what you're trying to achieve. This is your opportunity to get creators excited about the campaign." {...register("description")} required />

                  <Container>
                    <Row>
                      <Col sm={10} style={{ marginLeft: -30 }}>
                        <h3>Attachments</h3>
                        <p>Add up to 5 attachments, each one up up to 5MB in size. <br /><i>Be aware that creators will be able to download these attachments.</i></p>
                      </Col>
                      <Col style={{ textAlign: 'right', marginRight: -30 }}>
                        <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="attachmentPicker" multiple={true} />
                        <Button variant="primary" size="sm" title={attachments.length >= 5 ? "Max 5 attachments limit reached" : "Add attachment to this brief"} disabled={attachments.length >= 5} onClick={() => document.getElementById('attachmentPicker')?.click()}>
                          <FontAwesomeIcon icon={faPaperclip} /> Add Attachment
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ margin: '0 -30px' }}>
                        {isUploading && <>Uploading Attachments <Spinner size="sm" animation="border" /> <hr /></>}

                        {attachments.map((file: UserFile, index: Number) => (
                          <AttachmentItem
                            key={`attachment-${file.fileName}-${index}`}
                            file={file}
                            userId={appUser.id}
                            showRemove={true}
                            removeFileCallback={(f: UserFile) => {
                              console.log(f);
                              let a = [...attachments];
                              setAttachments(a.filter((af: any) => af.id !== f.id));
                            }}
                          />
                        ))}
                      </Col>
                    </Row>
                  </Container>

                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col sm={6} style={{ padding: 0 }}>
                  <h2 style={{ padding: '10px 0 0 20px' }}>Creator Requirements</h2>
                  <Container fluid>
                    <Row>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Follower size: *</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          key={`required-creator-followerCount`}
                          name={`requiredCreator.followerCount`}
                          control={control}
                          rules={{
                            validate: (value) => {
                              if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) return "Select at least one follower size";
                            }
                          }}
                          render={({ field }) =>
                            <Select placeholder="..." {...field} options={followerCounts} isMulti={true} required />
                          } />
                        {errors.requiredCreator?.followerCount && <div className="invalid-feedback" style={{ display: 'inline-block' }}> {errors.requiredCreator?.followerCount.message} </div>}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 3, padding: 0 }}>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Age ranges: *</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          key={`required-creator-ages`}
                          name={`requiredCreator.ages`}
                          control={control}
                          rules={{
                            validate: (value) => {
                              if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) return "Select at least one age range";
                            }
                          }}
                          render={({ field }) =>
                            <Select placeholder="..." {...field} options={ages} isMulti={true} required />
                          } />
                        {errors.requiredCreator?.ages && <div className="invalid-feedback" style={{ display: 'inline-block' }}> {errors.requiredCreator?.ages.message} </div>}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 3 }}>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Gender identity: *</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          key={`required-creator-gender`}
                          name={`requiredCreator.gender` as const}
                          control={control}
                          rules={{
                            validate: (value) => {
                              if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) return "Select at least one gender identity";
                            }
                          }}
                          render={({ field }) =>
                            <Select placeholder="..." {...field} options={genders} isMulti required />
                          } />
                        {errors.requiredCreator?.gender && <div className="invalid-feedback" style={{ display: 'inline-block' }}> {errors.requiredCreator?.gender.message} </div>}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 3 }}>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Content sectors: *</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          key={`content-areas`}
                          name={`requiredCreator.contentAreas` as const}
                          control={control}
                          rules={{
                            validate: (value) => {
                              if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) return "Select at least one content sector";
                            }
                          }}
                          render={({ field }) =>
                            <Select placeholder="..." {...field} options={contentAreas} isMulti required />
                          } />
                        {errors.requiredCreator?.contentAreas && <div className="invalid-feedback" style={{ display: 'inline-block' }}> {errors.requiredCreator?.contentAreas.message} </div>}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 3 }}>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Other considerations:</Form.Label>
                      </Col>
                      <Col>
                        <textarea style={{ borderRadius: 3, width: '100%', background: 'none', border: '1px #333 solid', minHeight: '150px', padding: 5 }}
                          placeholder="Optional. E.g. are you looking for creators with skin complaints or who have a pet? Put this here so the most relevant creators can apply."
                          {...register("requiredCreator.otherConsiderations")}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col>
                  <h2 style={{ padding: '10px 0 0 20px' }}>Audience Demographics</h2>
                  <Container fluid>
                    <Row>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Age range:</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          key={`audience-demographics-ages`}
                          name={`audienceDemographics.ages`}
                          control={control}
                          render={({ field }) =>
                            <Select placeholder="..." {...field} options={ages} isMulti={true} />
                          } />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 3 }}>
                      <Col sm={4}>
                        <Form.Label className="sf-label-regular">Gender identity:</Form.Label>
                      </Col>
                      <Col>
                        <Controller
                          key={`audience-demographics-gender`}
                          name={`audienceDemographics.gender` as const}
                          control={control}
                          render={({ field }) =>
                            <Select placeholder="..." {...field} options={genders} isMulti />
                          } />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 3 }}>
                      <Col>
                        <div style={{ padding: 20 }}>
                          As all creators are currently UK based, their audience will predominantly be also. We focus on creators with over 40% of their audience in the UK on platforms that include this information.
                        </div></Col>
                    </Row>
                  </Container>
                </Col>
              </Row>

            </Container>


          </>
        }
      </Container >
    </Form>
  );
}


