import { Container, Row, Col } from "react-bootstrap";
import { BriefApplication } from "../../models/brief";

interface ViewBriefApplicationProps {
  briefApplication: BriefApplication,
  adminMode?: boolean
}

export default function ViewBriefApplication(props: ViewBriefApplicationProps) {

  return (
    <>
      <Container>
        <Row style={{marginTop:10, paddingTop: 10, borderTop: '1px #ccc solid'}}>
          <Col>
            <b>Message:</b> <br />{props.briefApplication.message}
          </Col>
        </Row>
        {/* <Row style={{marginTop:10, paddingTop: 10, borderTop: '1px #ccc solid'}}>
          <Col>
            <b>Rate:</b> <br />{props.briefApplication.rate}
          </Col>
        </Row> */}
      </Container>
    </>
  );
}
