import { Alert, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import TopNav from "./components/TopNav";
import { useContext, useEffect, useRef, useState } from "react";
import { AccountType, AppUser, AppUserProfile, Role } from "./models/appUser";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuthApiCall } from "./utils/useAuthApiCall";
import Register from "./components/Register";
import { UserContext } from "./contexts/userContext";
import Admin from "./components/admin/Admin";
import { ProtectedItem } from "./utils/ProtectedItem";
import AppHome from "./components/AppHome";
import ProfileView from "./components/ProfileView";
import BriefDashboard from "./components/brief/BriefDashboard";
import NewBrief from "./components/brief/NewBrief";
import ViewBrief from "./components/brief/ViewBrief";
import BriefCreatorBoard from "./components/brief/BriefCreatorBoard";
import MyApplications from "./components/brief/MyApplications";
import BrandsChooser from "./components/brief/BrandsChooser";
import BriefApplications from "./components/brief/BriefApplications";
import { GenericErrorBoundary } from "./components/utils/GenericErrorBoundary";

function App() {
  const { user, isLoading } = useAuth0();
  const [appUserLoading, setAppUserLoading] = useState(true);
  const apiCall = useAuthApiCall();
  const navigate = useNavigate();
  const userWriteCtx = useRef(useContext(UserContext));
  const userCtx = useContext(UserContext);
  const [error, setError] = useState('');
  let home = 'The Social Fuse: Secure Zone';

  let curLoc = useLocation();
  useEffect(() => {
    const titleMap = [
      { path: '/app', title: home },
      { path: '/app/account/profile', title: 'The Social Fuse: My Profile' },
      { path: '/app/admin', title: 'The Social Fuse: Admin' },
      { path: '/app/admin/users', title: 'The Social Fuse: Admin - Users' },
      { path: '/app/admin/briefs', title: 'The Social Fuse: Admin - Briefs' },
      { path: '/app/admin/applications', title: 'The Social Fuse: Admin - Applications' },
      { path: '/app/creators/brief-board', title: 'The Social Fuse: Brief Board' },
      { path: '/app/creators/dashboard', title: 'The Social Fuse: My Brief Applications' }
    ]
    const curTitle = titleMap.find(item => item.path === curLoc.pathname)
    if (curTitle && curTitle.title) {
      document.title = curTitle.title
    }
  }, [curLoc, home]);

  useEffect(() => {
    const getUser = async () => {
      try {
        var u: AppUser = await apiCall('user', 'get');
      } catch (e) {
        console.error("nope");
        setError("Oops! We couldn't fetch your user account. Please try again, and let us know so we can get things fixed up for you.");
        setAppUserLoading(false);
        return;
      }

      u.email = user?.email;
      u.profile = u.profile || {} as AppUserProfile;
      u.profile.firstName = u.profile.firstName || user?.given_name;
      u.profile.lastName = u.profile.lastName || user?.family_name;

      userWriteCtx.current.setAppUser(u);
      setAppUserLoading(false);
      if ((!u.roles || u.roles.length === 0) && window.location.pathname !== '/app/register/complete') navigate('register'); // not been approved yet     

      // decide where to send the user on home page landing
      if (window.location.pathname === '/app' || window.location.pathname === '/app/') {
        switch (u.accountType) {
          case AccountType.Creator:
            navigate('creators/brief-board');
            break;
          case AccountType.Brand:
            navigate(`brands/${u.profile?.brands[0].brandGuid}/briefs`);
            break;
          case AccountType.Agency:
            navigate(`brands/`);
        }
      }
    };

    if (!isLoading) {
      getUser();
    }
  }, [apiCall, isLoading, navigate, user]);

  const BriefWrapper = () => {
    const { brandId, briefId } = useParams();
    return <ViewBrief briefId={briefId} brandId={brandId} />
  }

  return (
    <>
      <TopNav />
      <Container fluid className="sf-app-container">
        <Row className="p-0 m-0">
          <Col className="p-0 m-0">

            <div className="sf-register-container">
              <Container className="sf-register-panel">
                <Row>
                  <Col>
                    <GenericErrorBoundary>
                      {isLoading || appUserLoading || error.length > 0 ?
                        <>
                          {isLoading || appUserLoading ?
                            <ProgressBar variant="secondary" style={{ height: 25 }} label="Setting up app..." animated now={isLoading ? 50 : 75} />
                            :
                            <Alert variant="warning">{error}</Alert>
                          }
                        </> :
                        <Routes>
                          <Route path="*" element={
                            <AppHome />
                          } />
                          <Route path="register/*" element={
                            <Register appUser={userCtx.appUser} />
                          } />
                          <Route path="account/*" element={
                            <>
                              <Routes>
                                <Route path="*" element={<ProfileView appUser={userCtx.appUser} appUserProfile={userCtx.appUser.profile} showEditButton={true} />} />
                                <Route path="profile" element={<ProfileView appUser={userCtx.appUser} appUserProfile={userCtx.appUser.profile} showEditButton={true} />} />
                              </Routes>
                            </>
                          } />

                          <Route path="creators/brief-board" element={
                            <BriefCreatorBoard />
                          } />
                          <Route path="creators/dashboard" element={
                            <MyApplications />
                          } />
                          <Route path="brands/" element={
                            <BrandsChooser />
                          } />
                          <Route path="brands/:brandId/briefs" element={
                            <BriefDashboard />
                          } />
                          <Route path="brands/:brandId/briefs/create" element={
                            <NewBrief />
                          } />
                          <Route path="brands/:brandId/briefs/:briefId/edit" element={
                            <NewBrief />
                          } />
                          <Route path="brands/:brandId/briefs/:briefId" element={
                            <BriefWrapper />
                          } />
                          <Route path="brands/:brandId/briefs/:briefId/applications" element={
                            <BriefApplications />
                          } />
                          <Route path="admin/*" element={
                            <ProtectedItem requiredRoles={[Role.AppAdmin]} renderError={true}>
                              <Admin />
                            </ProtectedItem>
                          } />
                        </Routes>
                      }
                    </GenericErrorBoundary>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export const ProtectedApp = withAuthenticationRequired(App, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (
    <>
      <TopNav />

      <Container fluid className="sf-app-container">
        <Row className="p-0 m-0">
          <Col className="p-0 m-0">

            <div className="sf-register-container">
              <Container className="sf-register-panel">
                <Row>
                  <Col>
                    <ProgressBar variant="secondary" style={{ height: 25 }} animated now={25} label="Logging in..." />
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>)
});

