import { Row, Col, Form, ToggleButton, ToggleButtonGroup, Container } from "react-bootstrap";
import { AccountType, Age, AppUser, Gender } from "../../models/appUser";
import { Controller, useForm } from "react-hook-form";
import FormStageNav from "./FormStageNav";
import { useEffect, useState } from "react";
import { FormStageProps } from "../../models/forms";
// import femaleOff from "../../styles/img/doodles/female-off.png"
// import maleOff from "../../styles/img/doodles/male-off.png"
// import notSayOff from "../../styles/img/doodles/not-say-off.png"
// import femaleOn from "../../styles/img/doodles/female-on.png"
// import maleOn from "../../styles/img/doodles/male-on.png"
// import notSayOn from "../../styles/img/doodles/not-say-on.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faMars, faVenus } from "@fortawesome/free-solid-svg-icons";

interface Props extends FormStageProps {
  ages: Array<Age>
}

export default function YourDetailsStage(props: Props) {
  const { register, formState: { errors }, handleSubmit, setValue, getValues, control } = useForm<AppUser>({ defaultValues: props.u });
  const [validated, setValidated] = useState(false);
  const [selectedAge, setSelectedAge] = useState(0);
  const [selectedGender, setSelectedGender] = useState(0);

  document.title = 'The Social Fuse: Register - Your Details';

  const onSubmit = (u: AppUser) => {
    props.nextCallback(u);
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  // set default to UK for now
  useEffect(() => {
    setValue('profile.country', 'UK');
  }, [props.u, setValue]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>

        <Container fluid className="sf-form-body">
          <Row style={{ marginBottom: 40 }}>
            <Col>
              <div className="sf-registration-stage-title"><h2>Your Details</h2></div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={props.u.accountType === AccountType.Creator ? 6 : { offset: 3, span: 6 }}>

              <Form.Group className="mb-3">
                <Form.Label>Email:</Form.Label>
                <Form.Control type="email" placeholder="Enter email" {...register("email")} readOnly required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>First Name:</Form.Label>
                <Form.Control type="text" placeholder="Jane" {...register("profile.firstName", { required: true })} required />
                {errors.profile?.firstName?.type === 'required' && <Form.Control.Feedback type="invalid">First name is required</Form.Control.Feedback>}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Last Name:</Form.Label>
                <Form.Control type="text" placeholder="Doe" {...register("profile.lastName", { required: true })} required />
                {errors.profile?.lastName?.type === 'required' && <Form.Control.Feedback type="invalid">Last name is required</Form.Control.Feedback>}
              </Form.Group>
              {
                props.u.accountType !== AccountType.Creator &&
                <Form.Group className="mb-3">
                  <Form.Label>Your LinkedIn URL:</Form.Label>
                  <Form.Control type="text" placeholder="https://www.linkedin.com/in/your-account/" {...register("profile.personalLinkedInUrl", {validate: (value) => { if (!value || (value && !value.startsWith('https://www.linkedin.com'))) return false } })} required />
                  {errors.profile?.personalLinkedInUrl && <div className="invalid-feedback" style={{ display: 'inline-block' }}>A valid LinkedIn address is required for verification (https://www.linkedin.com/in/your-account/)</div>}
                </Form.Group>
              }
              {
                props.u.accountType === AccountType.Creator &&
                <div style={{ float: 'right', marginBottom: '10px', display: 'none' }}>
                  <Form.Check
                    {...register("profile.displayLastName")}
                    type="switch"
                    label="Display last name to brands?"
                  />
                </div>
              }
              <Form.Group className="mb-3" style={{ clear: 'both' }}>
                <Form.Label>Based in:</Form.Label>

                <select disabled={true} className="form-select" {...register("profile.country", { required: true })} required>
                  <option value="UK">UK</option>
                </select>
                <div className="invalid-feedback" style={{ display: 'inline-block', fontStyle: 'italic', textAlign: 'right' }}>Please note we are currently only accepting new accounts in the UK</div>
              </Form.Group>
            </Col>
            <Col>
              {
                props.u.accountType === AccountType.Creator &&
                <Form.Group className="mb-3">
                  <Form.Label>Age Range:</Form.Label>
                  <Controller
                    name={`profile.age.label` as const}
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value) {
                          setSelectedAge(-1);
                          return "Age Range is required";
                        } else {
                          setSelectedAge(1);
                          return true;
                        }
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ToggleButtonGroup
                        name='age-toggle'
                        onChange={onChange}
                        value={value}
                        type="radio"
                        style={{ display: 'block' }}
                      >
                        {props.ages.map((a: Age, i: number) => (
                          <ToggleButton
                            key={i}
                            id={`age-${i}`}
                            variant={i % 2 === 0 ? 'outline-secondary' : 'outline-success'}
                            value={a.label}
                            onClick={(e: any) => setValue(`profile.age`, a)}
                            style={{ width: '95px', display: 'inline-block', margin: '10px 10px 0 0', borderRadius: '3px' }}
                          >
                            {a.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    )} />
                  {selectedAge === -1 && <div className="invalid-feedback" style={{ display: 'inline-block' }}>Age Range is required</div>}
                </Form.Group>
              }
              {
                props.u.accountType === AccountType.Creator &&
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>I identify as a:</Form.Label>
                    <Controller
                      name={`profile.gender` as const}
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (!value) {
                            setSelectedGender(-1);
                            return "Gender is required";
                          } else {
                            setSelectedGender(1);
                            return true;
                          }
                        },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ToggleButtonGroup
                          name='gender-toggle'
                          onChange={onChange}
                          value={value}
                          type="radio"
                          style={{ display: 'block' }}
                        >
                          <ToggleButton
                            id="genderFemale"
                            variant="outline-success"
                            value={Gender.Female}
                            onClick={(e: any) => setValue(`profile.gender`, Gender.Female)}
                            style={{ width: '30%', height: '100px', display: 'inline-block', margin: '10px 10px 0 0', padding: '0', borderRadius: '3px', position: 'relative' }}
                          >
                            {/* <img src={getValues(`profile.gender`) === Gender.Female ? femaleOn : femaleOff} style={{ maxWidth: '80%', maxHeight: '70%', marginTop: 5 }} alt="Identify as Female" /> */}
                            <FontAwesomeIcon icon={faVenus} size='4x' style={{ marginTop: 8 }} className={getValues(`profile.gender`) === Gender.Female ? "text-white" : "text-green"} />
                            <span style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', textAlign: 'center', color: getValues(`profile.gender`) === Gender.Female ? '#ffffff' : '' }}>Female</span>
                          </ToggleButton>
                          <ToggleButton
                            id="genderMale"
                            variant="outline-secondary"
                            value={Gender.Male}
                            onClick={(e: any) => setValue(`profile.gender`, Gender.Male)}
                            style={{ width: '30%', height: '100px', display: 'inline-block', margin: '10px 10px 0 0', padding: 0, borderRadius: '3px', position: 'relative' }}
                          >
                            {/* <img src={getValues(`profile.gender`) === Gender.Male ? maleOn : maleOff} style={{ maxWidth: '90%', maxHeight: '80%', marginTop: 5 }} alt="Identify as Male" /> */}
                            <FontAwesomeIcon icon={faMars} size='4x' style={{ marginTop: 8 }} className={getValues(`profile.gender`) === Gender.Male ? "text-white" : "text-purple"} />
                            <span style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', textAlign: 'center' }}>Male</span>
                          </ToggleButton>
                          <ToggleButton
                            id="genderprerNotToSay"
                            variant="outline-info"
                            value={Gender.PreferNotToSay}
                            onClick={(e: any) => setValue(`profile.gender`, Gender.PreferNotToSay)}
                            style={{ fontSize: 14, width: '30%', height: '100px', display: 'inline-block', margin: '10px 10px 0 0', padding: 0, borderRadius: '3px', position: 'relative' }}
                          >
                            {/* <img src={getValues(`profile.gender`) === Gender.PreferNotToSay ? notSayOn : notSayOff} style={{ maxWidth: '80%', maxHeight: '70%', marginTop: 5 }} alt="Prefer not to identify" /> */}
                            <FontAwesomeIcon icon={faBan} size='3x' style={{ marginTop: 8 }} className={getValues(`profile.gender`) === Gender.PreferNotToSay ? "text-white" : "text-blue"} />
                            <span className="sf-other-not-say">Other / Prefer not <br />to say</span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )} />
                    {selectedGender === -1 && <div className="invalid-feedback" style={{ display: 'inline-block' }}>A Gender Identification is required</div>}

                  </Form.Group>
                </>
              }
            </Col>
          </Row>
        </Container>
        <FormStageNav
          onNext={handleSubmit(onSubmit, onError)}
          onPrev={() => props.prevCallback(getValues())}
          localStages={props.localStages}
          currentStageName={props.currentStageName} />
      </Form>
    </>
  );
}
