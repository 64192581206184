import { Row, Form, Col, Container } from "react-bootstrap";
import { AppUser } from "../../models/appUser";
import { useForm } from "react-hook-form";
import FormStageNav from "./FormStageNav";
import { useState } from "react";
import { FormStageProps } from "../../models/forms";

interface Props extends FormStageProps {
}

export default function ConfirmStage(props: Props) {
  let u = props.u;
  const [enableSubmit, setEnableSubmit] = useState(props.u.profile && props.u.profile.consentToTC === true);

  document.title = 'The Social Fuse: Register - Confirm your details';

  const { register, handleSubmit, getValues } = useForm<AppUser>({ defaultValues: u });
  const [validated, setValidated] = useState(false);
  const onSubmit = (u: AppUser) => {
    console.log(u);
    props.nextCallback(u);
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid className="sf-form-body">
          <Row style={{marginBottom: 40}}>
            <Col>
              <div className="sf-registration-stage-title">
                <h2>Submit</h2>
              </div>
            </Col>
          </Row>
          <Row>
            {
              (!props.u.roles || props.u.roles.length === 0) &&
              <Col sm={12} md={{ offset: 3, span: 6 }} style={{ marginBottom: 20 }}>
                <Form.Group className="mb-3">
                  <Form.Label>Data Consent:</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label={<span>I confirm that I'm over 18 years of age, agree to the <a href="/terms-of-use" target="_blank">terms and conditions</a> and consent to The Social Fuse processing my personal data in accordance with the <a href="/privacy" target="_blank">privacy policy</a>.</span>}
                    id={`tsf-data-consent`}
                    {...register(`profile.consentToTC`)}
                    onClick={() => { setEnableSubmit(!getValues(`profile.consentToTC`)) }}
                  />
                </Form.Group>
              </Col>
            }
            <Col sm={12} md={{ offset: 3, span: 6 }}>
              <Form.Group className="mb-3">
                <Form.Label>Marketing Preferences:</Form.Label>
                <div style={{ marginBottom: 10 }}>
                  We'd love to keep you informed with occasional emails about new briefs, platform updates, and important information to help you make the most of The Social Fuse. Please confirm below if you'd like to receive these communications. You can unsubscribe at any time (see our <a href="/privacy" target="_blank">privacy policy</a> for more information).
                </div>
                <Form.Check
                  type="checkbox"
                  label={`Yes, I'd like to receive marketing emails from The Social Fuse`}
                  id={`tsf-marketing-pref`}
                  {...register(`profile.consentToMarketing`)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <FormStageNav
          onNext={handleSubmit(onSubmit, onError)}
          disableSubmit={enableSubmit !== true}
          onPrev={() => props.prevCallback(getValues())}
          localStages={props.localStages}
          currentStageName={props.currentStageName} />
      </Form>
    </>
  );
}
