import {  useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { UserContext } from "../contexts/userContext";

export default function AppHome() {
  const userCtx = useContext(UserContext);

  return (
    <>
      <Container style={{marginTop:'20px', textAlign:'center'}}>
        <Row>
          <Col>
            <h1>Hi {userCtx.appUser.profile?.firstName} :)</h1>
            <p>We're currently working behind the scenes to make The Social Fuse all it can be - we'll let you know when you should check back and get involved!</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
