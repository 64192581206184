import { AccountStatus, AccountType, AppUser } from "../../models/appUser";
import { useEffect, useState } from "react";
import { ResultType, useAuthApiCall } from "../../utils/useAuthApiCall";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import WhoAreYouStage from "./WhoAreYouStage";
import YourDetailsStage from "./YourDetailsStage";
import SocialsStage from "./SocialsStage";
import FormStageBreadcrumb from "./FormStageBreadcrumb";
import { FormStageProps, FormStages, Stages } from "../../models/forms";
import CreatorInterestsStage from "./CreatorInterestsStage";
import AllDoneStage from "./AllDoneStage";
import AgencyInfoStage from "./AgencyInfoStage";
import BrandsStage from "./BrandsStage";
import BrandInfoStage from "./BrandInfoStage";
import { ProgressBar, Container, Row, Col } from "react-bootstrap";
import ConfirmStage from "./ConfirmStage";
import PictureBioStage from "./PictureBioStage";
import { Route, Routes, useNavigate } from "react-router-dom";

interface Props {
  u: AppUser
  adminMode?: boolean
  submitCallback?: (u: AppUser) => void
}

export default function FormStageChooser(props: Props) {
  let [stage, setStage] = useState(FormStages.WhoAreYou);
  let [movingForward, setMovingForward] = useState(true);
  let [localStages, setLocalStages] = useState([] as Array<FormStages>);
  let [u, setU] = useState(props.u);
  let [submitting, setSubmitting] = useState(false);
  const apiCall = useAuthApiCall();
  const navigate = useNavigate();
  const [{ ages, contentAreas, sectors, followerCounts }, setLookupData] = useState({ ages: [], contentAreas: [], sectors: [], followerCounts: [] });

  let stages: Stages = props.u.accountStatus === AccountStatus.New ? {
    creator: [
      FormStages.WhoAreYou,
      FormStages.YourDetails,
      FormStages.Interests,
      FormStages.Socials,
      FormStages.PictureBio,
      FormStages.Confirm,
      FormStages.AllDone
    ],
    brand: [
      FormStages.WhoAreYou,
      FormStages.YourDetails,
      FormStages.BrandInfo,
      FormStages.PictureBio,
      FormStages.Confirm,
      FormStages.AllDone
    ],
    agency: [
      FormStages.WhoAreYou,
      FormStages.YourDetails,
      FormStages.AgencyInfo,
      FormStages.Brands,
      FormStages.PictureBio,
      FormStages.Confirm,
      FormStages.AllDone
    ]
  } :
    {
      creator: [
        FormStages.YourDetails,
        FormStages.Interests,
        FormStages.Socials,
        FormStages.PictureBio,
        FormStages.Confirm,
        FormStages.AllDone
      ],
      brand: [
        FormStages.YourDetails,
        FormStages.BrandInfo,
        FormStages.PictureBio,
        FormStages.Confirm,
        FormStages.AllDone
      ],
      agency: [
        FormStages.YourDetails,
        FormStages.AgencyInfo,
        FormStages.Brands,
        FormStages.PictureBio,
        FormStages.Confirm,
        FormStages.AllDone
      ]
    };

  useEffect(() => {
    if (props.u.accountStatus !== AccountStatus.New) {
      switch (props.u.accountType) {
        case AccountType.Creator:
          setLocalStages(stages.creator);
          setStage(FormStages.YourDetails);
          break;
        case AccountType.Brand:
          setLocalStages(stages.brand);
          setStage(FormStages.YourDetails);
          break;
        case AccountType.Agency:
          setLocalStages(stages.agency);
          setStage(FormStages.YourDetails);
          break;
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getData = async () => {
      const [ages, contentAreas, sectors, followerCounts] = await Promise.all([
        apiCall('lookup/ages', 'get'),
        apiCall('lookup/contentareas', 'get'),
        apiCall('lookup/sectors', 'get'),
        apiCall('lookup/followercounts', 'get')
      ]);

      setLookupData({ ages: ages, contentAreas: contentAreas, sectors: sectors, followerCounts: followerCounts });
    }

    getData();
  }, [apiCall]);


  const setAccountType = (u: AppUser) => {
    onNext(u);
  }

  const onNext = (u: AppUser) => {
    console.log('next');
    setU(u);
    moveFormStage(u, true);
  }

  const onPrev = (u: AppUser) => {
    console.log('prev');
    setU(u);
    moveFormStage(u, false);
  }

  const moveFormStage = async (u: AppUser, forward: boolean) => {

    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    setMovingForward(forward);
    let stg: Array<FormStages> = [];

    switch (u.accountType) {
      case AccountType.Creator:
        stg = stages.creator;
        break;
      case AccountType.Brand:
        stg = stages.brand;
        break;
      case AccountType.Agency:
        stg = stages.agency;
        break;
    }

    let currentStageIndex = stg.findIndex((f: FormStages) => f === stage);
    try {
      if (forward) {
        if (currentStageIndex < stg.length - 1) {
          // do we submit?
          if (currentStageIndex === stg.length - 2) {
            console.log("SUBMITTING", u);
            setSubmitting(true);
            u.subscription = null;

            let newU = {} as AppUser;
            if (props.adminMode){
              newU = await apiCall(`user/${u.id}/admin`, 'put', u, ResultType.JSON)
            } else {
              newU = await apiCall('user', 'put', u, ResultType.JSON);
              if (newU.accountStatus === AccountStatus.New && window.location.pathname === '/app/register') {
                // navigate to complete page for GA
                console.log("New User"); 
                navigate(`/app/register/complete`);
              }
            }

            setSubmitting(false);
            setU(newU);
            setStage(stg[currentStageIndex + 1]);

            props.submitCallback && props.submitCallback(newU);

          } else {
            setStage(stg[currentStageIndex + 1]);
          }
        }
      } else {
        if (currentStageIndex > 0) {
          setStage(stg[currentStageIndex - 1]);
        }
      }

      setLocalStages(stg);
    } catch (e) {
      setSubmitting(false);
      alert("Oops! An error occurred updating your profile. Please try again.")
    }
  }

  let p: FormStageProps = {
    u: u,
    nextCallback: setAccountType,
    prevCallback: onPrev,
    localStages: localStages,
    currentStageName: stage
  }

  let s = [
    { stage: FormStages.WhoAreYou, comp: <WhoAreYouStage {...p} /> },
    { stage: FormStages.YourDetails, comp: <YourDetailsStage {...p} ages={ages} /> },
    { stage: FormStages.Interests, comp: <CreatorInterestsStage {...p} contentAreas={contentAreas} /> },
    { stage: FormStages.Socials, comp: <SocialsStage {...p} followerCounts={followerCounts} /> },
    { stage: FormStages.AgencyInfo, comp: <AgencyInfoStage {...p} /> },
    { stage: FormStages.Brands, comp: <BrandsStage {...p} sectors={sectors} /> },
    { stage: FormStages.BrandInfo, comp: <BrandInfoStage {...p} sectors={sectors} /> },
    { stage: FormStages.PictureBio, comp: <PictureBioStage {...p} /> },
    { stage: FormStages.Confirm, comp: <ConfirmStage {...p} /> },
    { stage: FormStages.AllDone, comp: <AllDoneStage {...p} /> },
  ];

  return (
    <Routes>
      <Route path="*" element={
        <div className="form-stage-container">
          {
            stage !== FormStages.WhoAreYou && <FormStageBreadcrumb localStages={localStages} currentStageName={stage} />
          }
          {
            submitting ?
              <Container style={{ marginTop: '20px' }}>
                <Row><Col><ProgressBar variant="secondary" label="Sending over the interwebs..." animated now={75} /></Col></Row>
              </Container>
              :
              <TransitionGroup component={null} >
                {
                  s.filter((s: any) => s.stage === stage).map((s: any) => (
                    <CSSTransition key={s.stage} timeout={300} classNames={movingForward ? "form-stage" : "form-stage-back"}>
                      <div className="form-stage-wrapper">
                        {s.comp}
                      </div>
                    </CSSTransition>
                  ))
                }
              </TransitionGroup>
          }

        </div>
      } />
      <Route path="complete" element={<AllDoneStage {...p} />} />
    </Routes>


  );
}
