import { Container, Col, Row, Button } from "react-bootstrap";
import config from "../config.json";
import { useAuth0 } from "@auth0/auth0-react";
import smallArrow from "../styles/img/doodles/purple/SMALL ARROW.png";
import { Link } from "react-router-dom";

export default function Pricing() {
  const { loginWithRedirect } = useAuth0();
  const register = async () => {
    console.log("registering...", config.auth0ClientId, config.auth0Domain);
    loginWithRedirect({ screen_hint: 'signup', redirect_uri: `${window.location.origin}/app/register`, client_id: config.auth0ClientId, domain: config.auth0Domain });
  }

  return (
    <>
      <Container fluid className=''>
        <Container>
          <Row>
            <Col className="sf-banner-text" style={{ marginTop: 40, textAlign: 'center', marginBottom: 40 }}>
              <h1>
                <span className="sf-underline sf-green-underline">Pricing</span>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 4, offset: 1 }} lg={{ span: 5, offset: 1 }} md={6} style={{ marginBottom: 40 }} >
              <div style={{ padding: 30, textAlign: 'center' }} className="sf-pricing sf-pricing-blue">
                <h2 style={{ fontSize: '1.7rem' }}>Brands and Agencies</h2>
                <div className="sf-blue-highlight-left">
                  <div style={{ fontSize: '1.2rem' }}><span style={{ fontSize: '1.8rem' }}>£89</span> / brief post</div>
                  <div className="sf-blue-highlight-right">&nbsp;</div>
                </div>
                <p style={{ marginTop: 10 }}>Gain access to up to 30 interested creators!</p>
                <div style={{ padding: 10, marginBottom: 20 }}>
                  <Button variant="success" onClick={() => register()}>Register Now</Button>
                </div>
                <h5 style={{ padding: 10, textAlign: 'center' }}>W<span className="sf-underline sf-blue-underline" style={{ paddingBottom: 10 }}>hy you should sign up toda</span>y...</h5>
                <ul className="sf-purple-bullets" style={{ textAlign: 'left' }}>
                  <li>
                    Post as and when required – maximize your budget by avoiding subscriptions or excessive fees!
                  </li>
                  <li>
                    Save time and attract influencers directly. No need to sift through uninterested creators.
                  </li>
                  <li>
                    Creators are pre-vetted and applications are matched to your brief to ensure you receive high quality applications.
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={{ span: 4, offset: 2 }} lg={{ span: 5 }} md={6} style={{ marginBottom: 40 }} >
              <div style={{ padding: 30, paddingBottom: 50 }} className="sf-pricing sf-pricing-purple">
                <h2 style={{ textAlign: 'center', fontSize: '1.7rem' }}>Creators</h2>
                <div style={{ textAlign: 'center', fontSize: '1.2rem' }}><span style={{ fontSize: '1.8rem' }}>£3.<span style={{ fontSize: '1.4rem' }}>49</span></span> / month</div>
                <div style={{ textAlign: 'center', fontSize: '1.1rem', marginTop: -5 }}></div>
                <p style={{ textAlign: 'center', marginTop: 20, position: 'relative' }}>Cheaper than a coffee!<img src={smallArrow} className="d-none d-xl-block" alt="small arrow" style={{ position: 'absolute', width: 55, right: 10, top: -30, height: 25, transform: 'rotate(55deg)' }} /> </p>
                <div style={{ textAlign: 'center', padding: 10, marginBottom: 20 }}>
                  <Button variant="success" onClick={() => register()}>Start your 2 month free trial!</Button>
                </div>
                <h5 style={{ padding: 10, textAlign: 'center' }}>W<span className="sf-underline sf-blue-underline" style={{ paddingBottom: 10 }}>hy you should sign up toda</span>y...</h5>
                <ul className="sf-purple-bullets">
                  <li>
                    Direct access to top brand briefs. Apply for collaborations that excite you!
                  </li>
                  <li>
                    Receive emails straight to your inbox with available briefs.
                  </li>
                  <li>
                    Enjoy flexible membership – cancel anytime. But with such great campaigns, why would you want to?
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Have a question?</h2>
              <p className="lead">Please check out our <Link to="/faq">FAQ Page</Link>, or <a href="mailto:info@thesocialfuse.co.uk">get in touch</a> with us.</p>
              <br />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
