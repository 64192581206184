import { Container, Row, Col, Tabs, Tab, Nav } from "react-bootstrap";
import UserAdmin from "./UserAdmin";
import BriefAdmin from "./BriefAdmin";
import { Link, Route, Routes } from "react-router-dom";
import ApplicationsAdmin from "./ApplicationsAdmin";

export default function Admin() {
  return (

    <Container style={{ marginTop: '20px' }}>
      <Row style={{ marginBottom: 40 }}>
        <Col>
          <Tab.Container>
            <Nav>
              <Nav.Item>
                <Nav.Link eventKey="users" as={Link} to={`/app/admin/users`}>Users</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={`/app/admin/briefs`} eventKey="briefs">Briefs</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={`/app/admin/applications`} eventKey="applications">Applications</Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>

          <Tab.Content>
            <Routes>
              <Route path="users" element={<UserAdmin />} />
              <Route path="briefs" element={<BriefAdmin />} />
              <Route path="applications" element={<ApplicationsAdmin />} />
            </Routes>

          </Tab.Content>

        </Col>
      </Row>
    </Container>
  );
}
