import { Row, Form, Col, Container } from "react-bootstrap";
import { AppUser } from "../../models/appUser";
import { useForm } from "react-hook-form";
import FormStageNav from "./FormStageNav";
import { useState } from "react";
import { FormStageProps } from "../../models/forms";

interface Props extends FormStageProps {
}

export default function AgencyInfoStage(props: Props) {
  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<AppUser>({ defaultValues: props.u });
  const [validated, setValidated] = useState(false);

  document.title = 'The Social Fuse: Register - Your Agency Details';

  const onSubmit = (u: AppUser) => {
    props.nextCallback(u);
  }

  const onError = (errors: any) => {
    setValidated(true);
    console.error(errors);
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid className="sf-form-body">
          <Row style={{marginBottom: 40}}>
            <Col>
              <div className="sf-registration-stage-title">
                <h2>Agency Info</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={{ offset: 1, span: 10 }}>
              <Form.Group className="mb-3">
                <Form.Label>Agency Name:</Form.Label>
                <Form.Control type="text" {...register("profile.agencyName", { required: true })} required />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={{ offset: 1, span: 10 }}>
              <Form.Group className="mb-3">
                <Form.Label>Agency Website:</Form.Label>
                <Form.Control type="text" placeholder="https://my-business.com" {...register("profile.agencyWebsite", {onBlur: (e) => {if(e.target.value && !e.target.value.toLowerCase().startsWith('http')) setValue(`profile.agencyWebsite`, `http://${e.target.value}`);}, validate: (value) => { if (!value || (value && !value.startsWith('http://') && !value.startsWith('https://'))) return false } })} />
                {errors.profile?.agencyWebsite && <div className="invalid-feedback" style={{ display: 'inline-block' }}>A website must begin with http:// or https://</div>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={{ offset: 1, span: 10 }}>
              <Form.Group className="mb-3">
                <Form.Label>LinkedIn Address:</Form.Label>
                <Form.Control type="text" placeholder="https://www.linkedin.com/in/my-account" {...register("profile.linkedinUrl")} />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <FormStageNav
          onNext={handleSubmit(onSubmit, onError)}
          onPrev={() => props.prevCallback(getValues())}
          localStages={props.localStages}
          currentStageName={props.currentStageName} />

      </Form>
    </>
  );
}
