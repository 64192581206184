import { useAuth0 } from "@auth0/auth0-react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../styles/img/logos/tsf-logo-white-sm.png";
import config from "../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faRocket, faSignIn, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function PublicTopNav() {
  const { user, logout, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const isActive = (path: string) => {
    return location.pathname.indexOf(path) !== -1;
  }

  const register = async () => {
    console.log("registering...", config.auth0ClientId, config.auth0Domain);
    loginWithRedirect({ screen_hint: 'signup', redirect_uri: `${window.location.origin}/app/register`, client_id: config.auth0ClientId, domain: config.auth0Domain });
  }

  return (
    <Navbar id="sf-public-nav" sticky="top" expanded={isExpanded} expand="lg" bg="dark" variant="dark">
      <Container>
        <Link className="navbar-brand" to="/#" onClick={() => setIsExpanded(false)}>
          <img src={logo} alt="The Social Fuse" className="sf-logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIsExpanded(!isExpanded)} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Item>
              <Nav.Link as={Link} onClick={() => setIsExpanded(false)} className={isActive("/#creator") ? "nav-link active" : "nav-link"} to="/#creator">I'm a creator</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} onClick={() => setIsExpanded(false)} className={isActive("/#brand") ? "nav-link active" : "nav-link"} to="/#brand">I'm a brand/agency</Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav>
            <Nav.Item>
              <Nav.Link as={Link} onClick={() => setIsExpanded(false)} className={isActive("/about-us") ? "nav-link active" : "nav-link"} to="/about-us">About Us</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} onClick={() => setIsExpanded(false)} className={isActive("/faq") ? "nav-link active" : "nav-link"} to="/faq">FAQs</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} onClick={() => setIsExpanded(false)} className={isActive("/pricing") ? "nav-link active" : "nav-link"} to="/pricing">Pricing</Nav.Link>
            </Nav.Item>
            {
              user?.email ?
                <>
                  <Nav.Item>
                    <Button variant="primary" size="sm" onClick={(e) => { e.preventDefault(); logout({ returnTo: window.location.origin }) }}>
                      Log out &nbsp;&nbsp;<FontAwesomeIcon icon={faSignOut} /></Button>
                  </Nav.Item>
                </>
                :
                <>
                  <Nav.Item>
                    <Button variant="secondary" size="sm" onClick={() => register()}>
                      <FontAwesomeIcon icon={faCheck} /> Register For Free</Button>
                  </Nav.Item>
                </>
            }
            <Nav.Item className="sf-launch-btn">
              <Button variant="success" size="sm" onClick={() => { navigate('/app') }}>
              {
              user?.email ?
                <><FontAwesomeIcon icon={faRocket} />&nbsp; Launch</>:
                <><FontAwesomeIcon icon={faSignIn} />&nbsp; Log in</>
              }
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
