import d from "./config.json";

export interface AppConfig {
    apiBaseUrl: string
    apiAudience: string
    apiScope: string
    auth0Domain: string,
    auth0ClientId: string,
    devMode: boolean,
    paymentPortalLink: string,
    fileBase: string
}

export const appConfig = () : AppConfig => {

    var c = d as AppConfig;

    if(window.location.hostname.indexOf("localhost") !== -1){
        c.apiBaseUrl = "http://localhost:7071/api";
        c.devMode = true;
    }

    // trim trailing slash if needed
    if(c.apiBaseUrl.lastIndexOf('/') === c.apiBaseUrl.length - 1) { 
        c.apiBaseUrl = c.apiBaseUrl.substring(0, c.apiBaseUrl.length -1);
    }

    // payment portal link
    if(window.location.hostname.indexOf("localhost") !== -1){
        c.paymentPortalLink = "https://billing.stripe.com/p/login/test_fZe7td7Fqat1dO0fYY"
    } else {
        c.paymentPortalLink = "https://billing.stripe.com/p/login/test_fZe7td7Fqat1dO0fYY"
    }

    return c;
}