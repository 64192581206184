import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function TermsOfUse() {
  const navigate = useNavigate();

  return (
    <Modal show={true} size="xl" onHide={() => navigate('/')}>
      <Modal.Header closeButton>
        <Modal.Title>TERMS OF USE</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <p>
                These terms and conditions (the "Terms and Conditions") govern the use of www.thesocialfuse.co.uk (the "Site"). This Site is owned and operated by The Social Fuse Ltd. We are registered, as a limited company, in England and Wales under company number 14397644.
                We connect Social Media creators (or, influencers) to Brands to help facilitate social media influencer advertising/ collaborations. Brands post briefs to The Social Fuse with details of the type of influencers they'd like to work with and influencers in turn can apply to the briefs.
              </p>
              <p>
                By using this Site, you indicate that you have read and understood these Terms and Conditions and agree to abide by them at all times.
              </p>

              <h2>Age Restrictions</h2>
              <p>
                The minimum age to use our Site is 18 years old. By using this Site, users agree that they are over 18 years old. We do not assume any legal responsibility for false statements about age.
              </p>

              <h2>Acceptable Use</h2>
              <p>
                As a user of our Site, you agree to use our Site legally, not to use our Site for illegal purposes, and not to:
              </p>
              <ul>
                <li>Harass or mistreat other users of our Site;</li>
                <li>Violate the rights of other users of our Site;</li>
                <li>Violate the intellectual property rights of the Site owners or any third party to the Site;</li>
                <li>Hack into the account of another user of the Site;</li>
                <li>Act in any way that could be considered fraudulent; or</li>
                <li>Post any material that may be deemed inappropriate or offensive.</li>
              </ul>
              <p>If we believe you are using our Site illegally or in a manner that violates these Terms and Conditions, we reserve the right to limit, suspend or terminate your access to our Site. We also reserve the right to take any legal steps necessary to prevent you from accessing our Site.</p>

              <h2>Accounts</h2>
              <p>
                When you create an account on our Site, you agree to the following:
              </p>
              <ol>
                <li>
                  You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and
                </li>
                <li>
                  All personal information you provide to us through your account is up-to-date, accurate, and truthful and that you will update your personal information if it changes.
                </li>
              </ol>
              <p>
                Brands/Agencies and Creators must fit our requirements to have their account registrations accepted. Any accounts that no longer fit our requirements can be removed. Brands/Agencies must show that they are a legitimate business that legally operates in, or does business with, the UK. For Creators, please see our requirements <Link to="/faq">here.</Link>
                We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions. For information on how we process your information, please see our <Link to="/privacy">Privacy policy</Link>.
              </p>
              <h2>User Contributions</h2>
              <p>Users may post the following information on our Site:</p>
              <ul>
                <li>Photos;</li>
                <li>Public comments; and</li>
                <li>Brief documents.</li>
              </ul>
              <p>
                By posting publicly on our Site, you agree not to act illegally or violate these Terms and Conditions. Violations include, without limitation, any User Content that:
              </p>
              <ul>
                <li>Infringes any patent, trademark, trade secret, copyright or other rights of any other person or entity or violates any law or contractual duty;</li>
                <li>You know is false, misleading, untruthful or inaccurate;</li>
                <li>Is unlawful, threatening, abusive, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, vulgar, pornographic, offensive, profane, or is otherwise inappropriate as determined by us in our sole discretion;</li>
                <li>Contains software viruses or any other computer codes, files, or programs that are designed or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorised access to any system, data, password or other information of ours or any third party or user;</li>
                <li>Impersonates any person or entity, including any of our employees or representatives; or</li>
                <li>Includes anyone's identification documents or sensitive financial information.</li>
              </ul>
              <p>
                By using our Site, you grant each user of The Social Fuse a non-exclusive, perpetual licence to access the content you post on our Site and/or during a proposal or a project. You warrant that you have the right to grant such licences without infringement or violation of third-party rights. We have the right to disclose your identity to any third party who is claiming that content posted or uploaded by you to our site constitutes a violation of their intellectual property rights or their right to privacy.
                The grant of licences above does not confer upon us any rights of ownership to your User Content. We do not own any intellectual property rights or have any rights in any work, materials or deliverables developed and created by you under a project.
                We reserve the right but do not have any obligation, to remove, edit, modify or block any content at our sole discretion, at any time, without notice to you if the content posted violates these terms and conditions.
                We will not be responsible, or liable to any third party, for the content or accuracy of any materials posted by you or any other user of our site.
              </p>

              <h2>Sale of Services</h2>
              <p>
                These Terms and Conditions govern the sale of services available on our Site.
              </p><p>
                We are under a legal duty to supply services that match the description of the service(s) you order on our Site.
              </p><p>
                We connect Social Media creators (or, influencers) to Brands to help facilitate social media influencer advertising/ collaborations. We connect our network of registered Brands/ Agencies and Creators by allowing Brands/Agencies to post a brief on The Social Fuse. Influencers get access to the Brief Board where they can review Brand/Agency briefs and apply for those relevant. Brands in turn will be able to see The Social Fuse profiles and details of the Creators who have applied. We ensure that the Influencers applying to the brief are relevant, but we do not select the Influencers on behalf of the Brands/ Agencies.
              </p><p>
                Once the Brands/ Agencies approve whom they want to work with, they will unlock the Creators' contact information. All negotiations and final agreements between Brands and Creators happen directly, off our Site. Any communications that happen outside The Social Fuse between Creators and Brand/Agencies are not our responsibility.
              </p><p>
                We will do our utmost to make sure that the information provided by Brands and Creators is accurate, but we cannot guarantee so. Creator and Brand/Agency details are provided at the time of registration and may later change. It is the Users responsibility to keep their profile information accurate. If you believe a user is violating these Terms and Conditions by providing inaccurate information, or for any other reason, please get in contact. We reserve the right to suspend the Users account on our Site.
              </p><p>
                Creators access our Site by means of a monthly subscription. Brands/ Agencies pay per brief post. The amount paid for a brief to be posted depends on how many Creators the Brand would like to apply. This information is shown before posting the brief. Please see our <Link to="/pricing">Pricing Page</Link> for more information.
              </p><p>
                We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give you a refund equal to the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.
                These Terms and Conditions apply to all the services that are displayed on our Site at the time you access it. All information, descriptions, or images that we provide about our services are as accurate as possible. However, we are not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of all services we provide. You agree to purchase services from our Site at your own risk.
              </p>

              <h2>Creator Subscriptions</h2>
              <p>
                Your subscription automatically renews on a monthly or annual basis (depending on the option chosen) and you will be automatically billed until we receive notification that you want to cancel the subscription. You can cancel at any time. If you cancel, you will still have access until the day before your next payment date.
              </p>
              To cancel your subscription, please follow these steps:
              <ol>
                <li>Go to 'My Account' </li>
                <li>Scroll to the bottom and click 'Cancel Subscription'</li>
              </ol>
              <p>
                This will deactivate your account but will not remove it. If you want to reinstate your account at any time, you can log in and start your subscription again. To remove your account in full you must choose 'Remove Account' underneath 'Cancel Subscription'. Cancelling your subscription doesn't equate to closing your account and we therefore will keep hold of your data, in case you'd like to renew your subscription, until the account is removed. Please see our <Link to="/privacy">privacy policy</Link> for more information on how your data is processed.
              </p>


              <h2>Free Trial (Creators)</h2>
              <p>
                We offer a two-month free trial of our services to Creator accounts. This begins when the Creator registers for The Social Fuse. The free trial gives full access to The Social Fuse services.
                At the end of your free trial, your account will be deactivated unless you add payment details to your account to start your subscription. We will email you before your trial ends to remind you. Once you add payment details, you will automatically be billed our monthly subscription rate at the end of the trial. See our <Link to="/pricing">Pricing Page</Link> for more information.
                You don't need to do anything to cancel your free trial. If you don't activate your account in full by adding your payment details, then your account will be deactivated at the end of the trial.
              </p>

              <h2>Payments</h2>
              We accept the following payment methods on our Site:
              <ul><li>PayPal.</li></ul>
              <p>
                When you provide us with your payment information, you authorise our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you authorise us to charge the amount due to this payment instrument.
              </p>
              <p>
                If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.
              </p>

              <h2>Right to Cancel and Receive Reimbursement</h2>
              <p>
                If you are a customer living in the United Kingdom or the European Union you have the right to cancel your contract to purchase services from us within 14 days without giving notice. The cancellation period:
              </p>
              <ul><li>Will end 14 days from the date of purchase when you purchased a service.</li></ul>
              <p>
                To exercise your right to cancel you must inform us of your decision to cancel within the cancellation period. To cancel, contact us by email at info@thesocialfuse.co.uk or by post at Flat 76, 5-7 Hornsey Street, London, N7 8GD.
                Effects of Cancellation
                If you requested the performance of services begins during the cancellation period, you are required to pay us an amount which is in proportion to what has been performed until you have communicated to us your decision to cancel this contract. We will reimburse to you any amount you have paid above this proportionate payment.
                We will make the reimbursement using the same form of payment as you used for the initial purchase unless you have expressly agreed otherwise. You will not incur any fees because of the reimbursement.
              </p>

              <h2>Consumer Protection Law</h2>
              <p>
                Where the Sale of Goods Act 1979, the Consumer Rights Act 2015, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.
              </p>

              <h2>Our liability</h2>
              <p>
                We try to keep The Social Fuse running at all times, but we cannot guarantee to do so. We cannot guarantee continuous, uninterrupted, or secure access to the Website as it may be interfered with by factors outside of our control.
                We also try to make sure that all the information that we place on The Social Fuse is accurate, but we cannot guarantee this either.
                We do not control or edit any information provided by Brands/Agencies and Creators that is placed on the Website. We review all briefs and Creator application messages but do not edit them. If we feel they violate the Terms and Conditions in any way, we will get in touch to ask the party to edit the brief or application message before it is posted.
                We use techniques that aim to verify the accuracy of the information provided by our Users, however, user verification on the internet is difficult. The Social Fuse cannot and does not confirm, and is not responsible for ensuring, the accuracy or truthfulness of Users' purported identities or the validity of the information which they provide to us or post on The Social Fuse.
              </p>

              <h2>Links from our site</h2>
              <p>
                The Social Fuse may contain hyperlinks to websites or social accounts that are not operated by or affiliated with us. These hyperlinks are provided for your reference and convenience only and do not imply any endorsement of the activities of these third-party websites or social accounts. We are not responsible for their data or privacy practices, we, therefore, urge you to review any privacy policy posted on any site you visit.
              </p>

              <h2>Limitation of Liability</h2>
              <p>
                The Social Fuse Ltd and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site. Losses include loss of income, revenue, business, profits or contracts, data, goodwill, and office time.
              </p>

              <h2>Indemnity</h2>
              <p>
                Except where prohibited by law, by using this Site you indemnify and hold harmless The Social Fuse Ltd and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.
              </p>

              <h2>Intellectual Property</h2>
              <p>
                We are the owner or the licensee of all intellectual property rights on our site, and the material published on it. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site. These works are protected by copyright laws. No content may be used without our permission.
                If you wish to make use of any content on our site, please contact us.
              </p>

              <h2>Linking to our site</h2>
              <p>
                You may not create a link to any part of our site other than the home page. A link to our homepage is acceptable so long that it is done in a fair and legal way and does not damage our reputation or take advantage of it. This must not be done in such a way that suggests a form of association or endorsement on our part where none exists.
                We reserve the right to withdraw linking permission without notice. The website from which you are linking must comply with the standards set out in these terms.
                You must not post screenshots of any part of the website on any social media or any other website. This includes brand briefs or creator profiles.
              </p>

              <h2>Viruses, hacking and other offences</h2>
              <p>
                It is a criminal offence under the Computer Misuse Act 1990 to introduce viruses, trojans, worms, logic bombs or other malicious or technologically harmful materials to our site. Attempting to gain unauthorised access to our site, the server on which our site is stored, or any computer connected to our site, is also a breach of this act. You must not attack our site via a denial-of-service attack or a distributed denial-of-service attack.
                Any such breaches of the Computer Misuse Act 1990 will be reported to the relevant law enforcement authorities, and we will cooperate with the authorities by disclosing your identity to them. Your right to use our site will also be revoked.
                We are not liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, programs, data or other proprietary material due to your use of our site or your downloading of any material posted on it, or any website linked to it.
              </p>

              <h2>Governing Law</h2>
              <p>These Terms and Conditions and any dispute or claim arising out of or in connection with them or their subject matter or formation are governed by the laws of the Country of England.
              </p>

              <h2>Severability</h2>
              <p>
                If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.
              </p>

              <h2>Changes</h2>
              <p>
                These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.
              </p>

              <h2>Contact Details</h2>
              <p>
                Please contact us if you have any questions or concerns. Our contact details are as follows:
                <a href="mailto:info@thesocialfuse.co.uk">info@thesocialfuse.co.uk</a><br />
                You can also contact us through the feedback form available on our Site.
                <br /><br />
                Effective Date: 1st day of November, 2023
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => navigate('/')}>Close</Button>
      </Modal.Footer>
    </Modal >
  );
}
