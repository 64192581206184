import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { appConfig } from "../../appConfig";

interface ImageUploaderProps {
  selectedFileCallback?: (file: File) => void;
  fileId?: string;
  additionalStyles?: any;
  showEdit?: boolean;
  customKey?: string;
  fallbackImageUrl: string;
}

export default function ImageUploader(props: ImageUploaderProps) {
  const [imgUrl, setImgUrl] = useState(props.fileId ? `${appConfig().fileBase}/${props.fileId}` : props.fallbackImageUrl);

  useEffect(() => {
    if (props.fileId) {
      setImgUrl(`${appConfig().fileBase}/${props.fileId}`);
    }
  }, [props.fileId]);

  const handleFileChange = (event: any) => {
    if (!event.target.files[0]) return;

    if (event.target.files[0].size > 5 * 1024 * 1024) {
      alert("Oops! That file's too big - it needs to be under 5 MB");
      return;
    }

    if (event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/gif' && event.target.files[0].type !== 'image/jpeg') {
      alert("Oops! That file's not an image - it needs to be a PNG, GIF or JPEG");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = (evt: any) => {
      setImgUrl(evt.target.result);
      props.selectedFileCallback && props.selectedFileCallback(event.target.files[0]);
    }
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <input style={{ display: 'none' }} type="file" accept="image/png, image/gif, image/jpeg" id={props.customKey ? props.customKey : "filepicker"} onChange={handleFileChange} />

      <div style={{ position: 'relative', backgroundImage: `url(${imgUrl})`, backgroundSize: 'cover', backgroundPosition: 'center', ...props.additionalStyles }}>
        {props.showEdit &&
          <Button
            variant="secondary"
            size="sm"
            onClick={(evt: any) => { evt.preventDefault(); document.getElementById(props.customKey ? props.customKey : 'filepicker')?.click(); }}
            style={{ position: 'absolute', bottom: 10, right: 10 }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        }
      </div>
    </>
  )
}